/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import { initialSireSearchFields } from '../../constants/staticData';

export const name = 'sireDelivery';

function createInitialState() {
  return {
    initialSireFieldsState: { ...initialSireSearchFields },
    sireProfileSummaryData: [],
    countryList: [],
    sireProfileSearchId: '',
    noResults: false
  };
}

export const initialState = createInitialState();

const getSearchId = (ids, profielSearchId) => {
  ids.forEach((id) => profielSearchId.push({
    profileId: id
  }));
};

const mapSireDeliveryRequest = (row) => ({
  customerShipTo: row.shipToID,
  titleTransferModelCode: row.ttmi,
  divisionCode: row.division,
  buyGroupCode: row.buyGroup,
  transportationModeCode: row.mode,
  manufacturingCountryOfOrigin: row['originCountry.code'],
  destinationCountryCode: row['destCountry.code'],
  plantCode: row.plantID
});

const removeEmptyValueFromRequest = (inputField) => {
  const fieldValue = inputField;
  Object.keys(fieldValue).forEach(
    (key) => (
      fieldValue[key] === ''
      || fieldValue[key] === null
      || fieldValue[key] === undefined
      || fieldValue[key].length === 0)
      && delete fieldValue[key]
  );
  return fieldValue;
};

const mapSireDeliveryProfileRequest = (data) => data.map((item) => ({
  profileId: item.profileID,
  shipToId: item.shipToID,
  plantId: item.plantID,
  ttmi: item.ttmi,
  division: item.division,
  buyGroup: item.buyGroup,
  mode: item.mode,
  originCountry: item.originCountry,
  destCountry: item['destCountry.code']
}));

const removeBlankValues = (data) => data.map((item) => {
  const fieldValue = item;
  Object.keys(fieldValue).forEach(
    (key) => (
      fieldValue[key] === ''
      || fieldValue[key] === null
      || fieldValue[key] === undefined
      || fieldValue[key].length === 0
    )
    && delete fieldValue[key]
  );
  return fieldValue;
});

const catchError = (error, dispatch, callback) => {
  dispatch(toggleSpinner(false));
  if (callback) {
    callback(null, error);
  }
};

function createReducers() {
  function updateSireSearchInitialField(state: Object, action: Object) {
    // UPDATE_INITIAL_SIRE_FIELD

    return {
      ...state,
      initialSireFieldsState: action.payload
    };
  }

  function resetSireSearchField(state: Object) {
    // RESET_SIRE_FIELD_TO_INITIAL_STATE
    return { ...state, ...initialState };
  }

  function clearSireSearchFieldValue(state: Object, action: Object) {
    // CLEAR_SEARCH_SIRE_FIELD_VALUE
    console.log('clearSireSearchFieldValue called');
    return {
      ...state,
      initialSireFieldsState: action.payload,
      sireProfileSummaryData: [],
      noResults: false
    };
  }

  function updateSireProfileSearchData(state: Object, action: Object) {
    // UPDATE_SIRE_PROFILE_SEARCH_DATA

    return {
      ...state,
      sireProfileSummaryData: action.payload.objects,
      sireProfileSearchId: cryptoRand().toString().substr(2, 8),
      noResults: action.payload.objects.length === 0
    };
  }

  function setCountryCodeDropdown(state: Object, action: Object) {
    // SET_COUNTRY_CODE_DROPDOWN_VALUES

    return {
      ...state,
      countryList: action.payload
    };
  }

  return {
    updateSireSearchInitialField,
    resetSireSearchField,
    clearSireSearchFieldValue,
    updateSireProfileSearchData,
    setCountryCodeDropdown
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchProfileSireInstructions() {
    return createAsyncThunk(
      `${name}/fetchProfileSireInstructions`,
      async (
        {
          fetchProfileSireInstructionsCallback, ids, filetype, supressSpinner
        },
        { dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        const profielSearchId = [];
        getSearchId(ids, profielSearchId);
        const requestBody = {
          profileSearch: profielSearchId,
          fileFormat: filetype
        };
        if (!supressSpinner) {
          dispatch(toggleSpinner(true));
        }
        request(
          {
            api: 'SireDeliveryInstructions',
            method: 'post',
            data: requestBody,
            cancellable: true
          },
          dispatch
        )
          .then((response) => {
            fetchProfileSireInstructionsCallback(response.data);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            if (fetchProfileSireInstructionsCallback) {
              fetchProfileSireInstructionsCallback(null, error);
            }
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function fetchBlobRequest() {
    return createAsyncThunk(
      `${name}/fetchBlobRequest`,
      async ({ blobUrl, callback }, { getState, dispatch }) => {
        request(
          {
            url: blobUrl,
            method: 'get',
            responseType: 'blob'
          },
          dispatch,
          getState
        )
          .then((response) => {
            callback(response.data);
          })
          .catch((error) => {
            console.log('error', error);
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function fetchSireDeliveryProfile() {
    return createAsyncThunk(
      `${name}/fetchSireDeliveryProfile`,
      async ({ callback, data }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const modifiedData = JSON.parse(JSON.stringify(data));
        if (data.ttmi === '') {
          modifiedData.ttmi = [''];
        } else if (data.ttmi === 'NoTTMI') {
          modifiedData.ttmi = '';
        } else {
          modifiedData.ttmi = data?.ttmi?.split(',');
        }
        const requestBody = {
          search: removeEmptyValueFromRequest(
            mapSireDeliveryRequest(modifiedData)
          )
        };
        dispatch(actions.updateSireSearchInitialField(data));
        request(
          {
            api: 'SIREPROFILESEARCH',
            method: 'post',
            data: requestBody
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(
              actions.updateSireProfileSearchData(
                getflattenResponse(response.data)
              )
            );
            callback(response);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            catchError(error, dispatch, callback);
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function downloadSireProfile() {
    return createAsyncThunk(
      `${name}/downloadSireProfile`,
      async (
        { downloadSireCallback, dowloadData, filetype },
        { getState, dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        const requestBody = {
          eventType: 'PROFILE_ID_SEARCH',
          eventRequest: removeBlankValues(
            mapSireDeliveryProfileRequest(dowloadData)
          ),
          fileFormat: filetype
        };
        request(
          {
            api: 'DOWNLOADSIREPROFILE',
            method: 'post',
            data: requestBody
          },
          dispatch,
          getState
        )
          .then((response) => {
            downloadSireCallback(response.data);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            if (downloadSireCallback) {
              downloadSireCallback(null, error);
            }
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function getsearchPanelData() {
    return createAsyncThunk(
      `${name}/getsearchPanelData`,
      async ({ callback }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        if (state.sireDelivery && state.sireDelivery.countryList.length > 0) {
          dispatch(toggleSpinner(false));
          return false;
        }
        request(
          {
            api: 'METADATA',
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            const { countryList } = response.data;
            const countryCodeList = countryList
              && countryList.map ? countryList.map((option) => ({
                value: option.countryCode,
                text: `${option.countryCode} | ${option.countryName}`
              })) : [];
            dispatch(actions.setCountryCodeDropdown(countryCodeList));
            if (callback) {
              callback(response.data);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            catchError(error, dispatch, callback);
            dispatch(toggleSpinner(false));
          });
        return true;
      }
    );
  }

  return {
    fetchProfileSireInstructions: fetchProfileSireInstructions(),
    fetchBlobRequest: fetchBlobRequest(),
    fetchSireDeliveryProfile: fetchSireDeliveryProfile(),
    downloadSireProfile: downloadSireProfile(),
    getsearchPanelData: getsearchPanelData()
  };
}

export const extraActions = createExtraActions();

export const sireDeliveryActions = { ...actions, ...extraActions };
export const sireDeliveryReducer = slice.reducer;
