import { showForNONPROD, pricingNonProd } from '../../helpers/envServer';

/* eslint-disable */
export default {
  PPM_REPORT_PONUMBER: 'poHeader.poNumber',
  PPM_REPORT_LINE_NUMBER: 'poLine.itemNumber',
  PPM_REPORT_SCHEDULE_LINE_NUMBER: 'sizes.scheduleLineItemNumber',
  PPM_REPORT_SIZE_DESC: 'sizes.sizePo.sizeDescription',
  PPM_REPORT_TR_CO_PONUMBER: 'poHeader.trcoPoNumber',
  REPORT_PO_NUMBER: 'poData.poHeader.poNumber',
  PPM_REPORT_VENDOR: 'poHeader.vendorCode',
  PPM_REPORT_VENDOR_NAME: 'vendor.vendorName',
  PPM_REPORT_PMO_DEC_CODE: 'vendor.productionNikeLiaisonOffice',
  PPM_REPORT_PMO_DEC_CODE_DESC: 'vendor.productionNikeLiaisonOfficeName',
  PPM_REPORT_ITEM_STATUS: 'poLine.dpomItemStatus',
  PPM_REPORT_DOC_TYPE_CODE: 'poHeader.poDocTypeCode',
  PPM_REPORT_DOC_TYPE_CODE_DESC: 'poHeader.poDocTypeDescription',
  PPM_REPORT_DOC_DATE: 'poHeader.documentDate',
  PPM_REPORT_CHANGED_DATE: 'poLine.changedOnDate',
  PPM_REPORT_PLANNING_PRIORITY_NUMBER: 'planning.planningPriorityCode',
  PPM_REPORT_PLANNING_PRIORITY_NUMBER_DESC: 'planning.planningPriorityDescription',
  PPM_REPORT_FX_ADJ_REQUIRED_INDICATOR: 'poHeader.fxAdjustmentRequiredIndicator',
  PPM_REPORT_FX_ADJUSTMENT_DATE: 'poLine.fxAdjustmentDate',
  PPM_REPORT_TRCO_RELEVANT_INDICATOR: 'poHeader.istrcoRelevant',
  PPM_REPORT_MRGAC: 'planning.mrgacDate',
  PPM_REPORT_OGAC: 'poLine.originalGoodsAtConsolidatorDate',
  PPM_REPORT_GAC: 'sizes.sizePo.goodsAtConsolidatorDate',
  PPM_REPORT_GAC_REASON_CODE: 'sizes.sizePo.goodsAtConsolidatorReasonCode',
  PPM_REPORT_GAC_REASON_CODE_DESC: 'sizes.sizePo.goodsAtConsolidatorReasonDescription',
  PPM_REPORT_PREV_GAC: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorDate',
  PPM_REPORT_PREV_GAC_REASON_CODE: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorReasonCode',
  PPM_REPORT_PREV_GAC_REASON_CODE_DESC: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorReasonDescription',
  PPM_REPORT_INITIAL_GAC: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorDate',
  PPM_REPORT_INITIAL_GAC_REASON_CODE: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonCode',
  PPM_REPORT_INITIAL_GAC_REASON_CODE_DESC: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonDescription',
  PPM_REPORT_OGAC_VS_MRGAC: 'planning.ogacVsMrgac',
  PPM_REPORT_GAC_VS_MRGAC: 'planning.gacVsMrgac',
  PPM_REPORT_GAC_VS_OGAC: 'sizes.sizeManufacturing.gacVsOgac',
  PPM_REPORT_MRGAC_CAPACITY_CONSUMPTION_WEEK: 'planning.mrgacCapacityConsumptionWeek',
  PPM_REPORT_GAC_VS_PREVGAC: 'sizes.sizeManufacturing.gacVsPreviousGac',
  PPM_REPORT_GLOBAL_PRODUCT_CLASS: 'planning.globalPlanningProductClassificationCode',
  PPM_REPORT_GLOBAL_PRODUCT_CLASS_DESC: 'planning.globalPlanningProductClassificationDescription',
  PPM_REPORT_GLOBAL_PRODUCT_GROUP: 'planning.globalPlanningProductGroupCode',
  PPM_REPORT_GLOBAL_PRODUCT_GROUP_DESC: 'planning.globalPlanningProductGroupDescription',
  PPM_REPORT_ORDER_REASON_CODE: 'poLine.reasonForOrderingCode',
  PPM_REPORT_ORDER_REASON_DESCRIPTION: 'poLine.reasonforOrderingDescription',
  PPM_REPORT_SEASON_CODE: 'poLine.seasonCode',
  PPM_REPORT_PRODUCT_CODE: 'poLine.productCode',
  PPM_REPORT_SIZE_QUANTITY: 'sizes.sizePo.sizeQuantity',
  PPM_REPORT_TRANSPORT_MODE: 'sizes.sizePo.transportationModeCode',
  PPM_REPORT_TRANSPORT_MODE_DESC: 'sizes.sizePo.transportationMode',
  PPM_REPORT_PLANT_CODE: 'poLine.plantCode',
  PPM_REPORT_PLANT_NAME: 'poLine.plantName',
  PPM_REPORT_TR_CO_PLANT_CODE: 'poLine.trcoPlantCode',
  PPM_REPORT_TR_CO_PLANT_NAME: 'poLine.trcoPlantName',
  PPM_REPORT_SHIPPING_TYPE: 'poLine.shippingType',
  PPM_REPORT_ASN_QUANTITY: 'sizes.sizeLogisticsASN.asnQuantity',
  PPM_REPORT_FACTORY_PROOF_OF_DELIVERY_DATE: 'sizes.sizeLogisticsASN.factoryProofOfDeliveryDate',
  PPM_REPORT_ASN_ACTUAL_DATE: 'sizes.sizeLogisticsASN.asnActualDate',
  PPM_REPORT_ORIGIN_RECEIPT_QTY: 'sizes.sizeLogisticsOR.originReceiptQuantity',
  PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE: 'sizes.sizeLogisticsOR.originReceiptActualDate',
  PPM_REPORT_CREATED_DATE: 'poHeader.poCreatedOn',
  PPM_REPORT_ACCEPT_PO_EXPECTED_DATE: 'manufacturing.acceptPOExpectedDate',
  PPM_REPORT_ACCEPT_PO_ACTUAL_DATE: 'manufacturing.acceptPOActualDate',
  PPM_REPORT_EX_FACTORY_EXPECTED_DATE: 'manufacturing.exFactoryExpectedDate',
  PPM_REPORT_EX_FACTORY_ACTUAL_DATE: 'manufacturing.exFactoryActualDate',
  PPM_REPORT_FACTORY_DELIVERY_EXPECTED_DATE: 'manufacturing.factoryDeliveryExpectedDate',
  PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE: 'manufacturing.factoryDeliveryActualDate',
  PPM_REPORT_ISSUE_PO_EXPECTED_DATE: 'manufacturing.issuePOExpectedDate',
  PPM_REPORT_ISSUE_PO_ACTUAL_DATE: 'manufacturing.issuePOActualDate',
  PPM_REPORT_MATERIAL_ORDER_EXPECTED_DATE: 'manufacturing.materialOrderExpectedDate',
  PPM_REPORT_MATERIAL_ORDER_ACTUAL_DATE: 'manufacturing.materialOrderActualDate',
  PPM_REPORT_MATERIAL_TRIM_RECEIPT_EXPECTED_DATE: 'manufacturing.materialTrimReceiptExpectedDate',
  PPM_REPORT_MATERIAL_TRIM_RECEIPT_ACTUAL_DATE: 'manufacturing.materialTrimReceiptActualDate',
  PPM_REPORT_QRS_EXPECTED_DATE: 'manufacturing.qrsExpectedDate',
  PPM_REPORT_QRS_ACTUAL_DATE: 'manufacturing.qrsActualDate',
  PPM_REPORT_PROD_START_EXPECTED_DATE: 'manufacturing.productionStartExpectedDate',
  PPM_REPORT_PROD_START_ACTUAL_DATE: 'manufacturing.productionStartActualDate',
  PPM_REPORT_PROD_END_EXPECTED_DATE: 'manufacturing.productionEndExpectedDate',
  PPM_REPORT_PROD_END_ACTUAL_DATE: 'manufacturing.productionEndActualDate',
  PPM_REPORT_CANCEL_DATE: 'poLine.cancelDate',
  PPM_STATISTICAL_DELIVERY_DATE: 'sizes.sizePo.statisticalDeliveryDate',
  PPM_REPORT_CURRENT_EVENT: 'manufacturing.currentEvent',
  PPM_REPORT_CURRENT_EVENT_DATE: 'manufacturing.currentEventDate',
  PPM_REPORT_NEXT_EVENT: 'manufacturing.nextEvent',
  PPM_REPORT_NEXT_EVENT_DATE: 'manufacturing.nextEventDate',
  PPM_REPORT_CUSTOMER_SHIP_TO: 'poLine.customerShipToNumber',
  PPM_REPORT_CUSTOMER_SHIP_TO_NAME: 'poLine.customerShipToName',
  PPM_REPORT_CUSTOMER_PO: 'salesOrder.customerPo',
  PPM_REPORT_SALES_ORDER_ITEM_NUMBER: 'poLine.directShipSalesOrderItemNumber',
  PPM_REPORT_DIRECT_SALES_ORDER_NUMBER: 'poLine.directshipSalesOrderNumber',
  PPM_REPORT_DIST_CHANNEL_CODE: 'salesOrder.distributionChannelCode',
  PPM_REPORT_DIST_CHANNEL_DESC: 'salesOrder.distributionChannelDescription',
  PPM_REPORT_SALES_ORG_CODE: 'salesOrder.salesOrgCode',
  PPM_REPORT_BALANCE_TO_SHIP: 'sizes.sizeManufacturing.balanceToShip',
  PPM_REPORT_BALANCE_TO_SHIP_PERCENTAGE: 'sizes.sizeManufacturing.balanceToShipPercentage',
  PPM_REPORT_BALANCE_QTY: 'sizes.sizeManufacturing.balanceQuantity',
  PPM_REPORT_FACTORY_DELIVERY_QTY: 'sizes.sizeManufacturing.factoryDeliveredQuantity',
  PPM_REPORT_EX_FACTORY_QTY: 'sizes.sizeManufacturing.exFactoryQuantity',
  PPM_REPORT_ITEM_QTY: 'poLine.itemQuantity',
  PPM_REPORT_LAUNCH: 'product.launchCode',
  PPM_REPORT_CATEGORY_CODE: 'product.categoryCode',
  PPM_REPORT_CATEGORY_DESC: 'product.categoryDescription',
  PPM_REPORT_SUB_CATEGORY_CODE: 'product.subCategoryCode',
  PPM_REPORT_SUB_CATEGORY_DESC: 'product.subCategoryDescription',
  PPM_REPORT_ATHLETE_ID: 'product.athleteIdentifier',
  PPM_REPORT_ATHLETE_NAME: 'product.athleteFullName',
  PPM_REPORT_MARKETING_ID: 'product.primaryMarketingTypeIdentifier',
  PPM_REPORT_MARKETING_DESC: 'product.primaryMarketingTypeDescription',
  PPM_REPORT_BLANK_COLOR: 'product.blankColor',
  PPM_REPORT_BLANK_PRODUCT_CODE: 'product.blankProductCode',
  PPM_REPORT_BLANK_STYLE: 'product.blankStyle',
  PPM_REPORT_COLOR_DESC: 'product.colorDescription',
  PPM_REPORT_GENDER_AGE_CODE: 'product.genderAgeCode',
  PPM_REPORT_GENDER_AGE_DESC: 'product.genderAgeDescription',
  PPM_REPORT_GLOBAL_CATEGORY_FOCUS_CODE: 'product.globalCategoryCoreFocusCode',
  PPM_REPORT_GLOBAL_CATEGORY_FOCUS_DESC: 'product.globalCategoryCoreFocusDescription',
  PPM_REPORT_GLOABL_CATEGORY_SUMMARY_CODE: 'product.globalCategorySummaryCode',
  PPM_REPORT_GLOABL_CATEGORY_SUMMARY_DESC: 'product.globalCategorySummaryDescription',
  PPM_REPORT_LAUNCH_CODE: 'product.launchDate',
  PPM_REPORT_LEAGUE_ID: 'product.leagueIdCode',
  PPM_REPORT_LEAGUE_ID_DESC: 'product.leagueIdDescription',
  PPM_REPORT_MIDSOLE_CODE: 'product.midsoleCode',
  PPM_REPORT_OUTSOLE_CODE: 'product.outsoleCode',
  PPM_REPORT_PROMO_ONLY_INDICATOR: 'product.promotionalIndicator',
  PPM_REPORT_PRODUCT_REFILL_CLASS_CODE: 'product.productRefillClassCode',
  PPM_REPORT_PRODUCT_REFILL_CLASS_DESC: 'product.productRefillClassDescription',
  PPM_REPORT_SILHOUTTE_TYPE: 'product.silhouetteTypeCode',
  PPM_REPORT_SILHOUTTE_TYPE_DESC: 'product.silhouetteTypeDescription',
  PPM_REPORT_SILO_CODE: 'product.siloCode',
  PPM_REPORT_SILO_DESC: 'product.siloDescription',
  PPM_REPORT_DIMENSION_CODE: 'product.dimensionCode',
  PPM_REPORT_SIZE_SORT_SEQ_NUMBER: 'sizes.sizeProduct.sizeSortSequenceNumber',
  PPM_REPORT_SPORT_ACTIVITY_CODE: 'product.sportActivityCode',
  PPM_REPORT_SPORT_ACTIVITY_CODE_DESC: 'product.sportActivityDescription',
  PPM_REPORT_STYLE_NUMBER: 'product.styleNumber',
  PPM_REPORT_TEAM_ID: 'product.teamIdentifier',
  PPM_REPORT_TEAM_NAME: 'product.teamName',
  PPM_REPORT_TEAM_SILHOUTTE_ID: 'product.teamSilhouetteId',
  PPM_REPORT_TEAM_SILHOUTTE_ID_DESC: 'product.teamSilhouetteDescription',
  PPM_REPORT_UPC: 'sizes.sizeProduct.upc',
  PPM_REPORT_SIZE_MISMATCH_ID: 'product.sizeMismatchIndicator',
  PPM_REPORT_PURCHASE_ORG_CODE: 'poHeader.purchaseOrgCode',
  PPM_REPORT_PURCHASE_ORG_NAME: 'poHeader.purchaseOrgName',
  PPM_REPORT_PO_REJECTION_CODE: 'manufacturing.poRejectionCode',
  PPM_REPORT_PO_REJECTION_CODE_DESC: 'manufacturing.poRejectionCodeDescription',
  PPM_REPORT_INVENTORY_SEGMENT_CODE: 'sizes.sizePo.inventorySegmentCode',
  PPM_REPORT_PO_COMPANY_CODE: 'poHeader.poCompanyCode',
  PPM_REPORT_PO_COMPANY_DESC: 'poHeader.poCompanyCodeDescription',
  PPM_REPORT_CREATED_BY: 'poHeader.createdBy',
  PPM_REPORT_DESTINATION_COUNTRY_CODE: 'poLine.destinationCountryCode',
  PPM_REPORT_DESTINATION_COUNTRY_NAME: 'poLine.destinationCountryName',
  PPM_REPORT_DIVISION_CODE: 'poLine.divisionCode',
  PPM_REPORT_GEOGRAPHY_CODE: 'poLine.geographyCode',
  PPM_REPORT_INCO_TERMS: 'poHeader.incoTerms',
  PPM_REPORT_ITEM_TEXT_DETAIL: 'poLine.itemTextDetail',
  PPM_REPORT_MFG_ORIGIN_COUNTRY_CODE: 'poHeader.manufacturingCountryOfOrigin',
  PPM_REPORT_MFG_ORIGIN_COUNTRY_NAME: 'poHeader.manufacturingCountryOfOriginName',
  PPM_REPORT_OVERDUE_INDICATOR: 'manufacturing.overdueIndicator',
  PPM_REPORT_PRODUCT_NAME: 'poLine.productName',
  PPM_REPORT_PURCHASE_GROUP_CODE: 'poHeader.purchaseGroupCode',
  PPM_REPORT_PURCHASE_GROUP_NAME: 'poHeader.purchaseGroupName',
  PPM_REPORT_TR_CO_COMPANY_CODE: 'poHeader.trcoCompanyCode',
  PPM_REPORT_TR_CO_COMPANY_DESC: 'poHeader.trcoCompanyCodeDescription',
  PPM_REPORT_TITLE_TRANSFER_MODEL_CODE: 'poHeader.titleTransferModelCode',
  PPM_REPORT_TITLE_TRANSFER_MODEL_DESC: 'poHeader.titleTransferModelDescription',
  PPM_REPORT_UNIT_OF_MEASURE_CODE: 'poLine.unitOfMeasureCode',
  PPM_REPORT_SEASON_YEAR: 'poLine.seasonYear',
  PPM_REPORT_ON_TIME_QTY: 'sizes.sizeManufacturing.onTimeQuantity',
  PPM_REPORT_ON_TIME_PERCENTAGE: 'sizes.sizeManufacturing.onTimePercentage',
  PPM_REPORT_SIZE_PRODUCT_FOB: 'sizes.sizeProduct.sizeProductFob',
  PPM_REPORT_SIZE_PRODUCT_FOB_CURR_CODE: 'sizes.sizeProduct.sizeProductFobCurrencyCode',
  PPM_REPORT_FOB_MISMATCH_INDICATOR: 'product.fobMismatchIndicator',
  PPM_REPORT_FOB_CRPO_RUV: 'sizes.sizePo.sizePricing.fob.crpoRateUnitValue',
  PPM_REPORT_FOB_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.fob.crpoCurrencyCode',
  PPM_REPORT_FOB_CRPO_RUV_TYPE_NAME: '',
  PPM_REPORT_TRADING_CO_FOB_CRPO_RUV: 'sizes.sizePo.sizePricing.fob.trcoRateUnitValue',
  PPM_REPORT_TRADING_CO_FOB_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.fob.trcoCurrencyCode',
  PPM_REPORT_TRADING_CO_FOB_CRPO_RUV_TYPE_NAME: '',
  PPM_REPORT_TOOLING_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.tooling.rateUnitValue' : 'sizes.sizePo.sizePricing.tooling.crpoRateUnitValue',
  PPM_REPORT_TOOLING_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.tooling.currencyCode' : 'sizes.sizePo.sizePricing.tooling.crpoCurrencyCode',
  PPM_REPORT_TOOLING_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.tooling.crpoRateTypeName',
  PPM_REPORT_DOMESTIC_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.domesticSourcing.rateUnitValue' : 'sizes.sizePo.sizePricing.domesticSourcing.crpoRateUnitValue',
  PPM_REPORT_DOMESTIC_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.domesticSourcing.currencyCode' : 'sizes.sizePo.sizePricing.domesticSourcing.crpoCurrencyCode',
  PPM_REPORT_DOMESTIC_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.domesticSourcing.crpoRateTypeName',
  PPM_REPORT_GROSS_PRICE_CRPO_RUV: showForNONPROD ?  'sizes.sizePo.sizePricing.grossPrice.rateUnitValue' : 'sizes.sizePo.sizePricing.grossPrice.crpoRateUnitValue',
  PPM_REPORT_GROSS_PRICE_CRPO_RUV_CURR_CODE: showForNONPROD ?  'sizes.sizePo.sizePricing.grossPrice.currencyCode' : 'sizes.sizePo.sizePricing.grossPrice.crpoCurrencyCode',
  PPM_REPORT_GROSS_PRICE_CRPO_RUV_TYPE_NAME: showForNONPROD ?  'sizes.sizePo.sizePricing.grossPrice.rateTypeName' : 'sizes.sizePo.sizePricing.grossPrice.crpoRateTypeName',
  PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.bGradeDiscAuto.rateUnitValue' : 'sizes.sizePo.sizePricing.bGradeDiscAuto.crpoRateUnitValue',
  PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV_TYPE: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.bGradeDiscAuto.crpoRateTypeName',
  PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.bGradeDiscAuto.currencyCode' : 'sizes.sizePo.sizePricing.bGradeDiscAuto.crpoCurrencyCode',
  PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.bGradeDiscManual.rateUnitValue' : 'sizes.sizePo.sizePricing.bGradeDiscManual.crpoRateUnitValue',
  PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.bGradeDiscManual.currencyCode' : 'sizes.sizePo.sizePricing.bGradeDiscManual.crpoCurrencyCode',
  PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.bGradeDiscManual.crpoRateTypeName',
  PPM_REPORT_SURCH_SLT_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeSlt.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeSlt.crpoRateUnitValue' ,
  PPM_REPORT_SURCH_SLT_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeSlt.currencyCode' : 'sizes.sizePo.sizePricing.surchargeSlt.crpoCurrencyCode' ,
  PPM_REPORT_SURCH_SLT_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeSlt.crpoRateTypeName' ,
  PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeSltpercent.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeSltpercent.crpoRateUnitValue',
  PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeSltpercent.currencyCode' : 'sizes.sizePo.sizePricing.surchargeSltpercent.crpoCurrencyCode',
  PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeSltpercent.crpoRateTypeName',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinMatMainBody.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.crpoRateUnitValue',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinMatMainBody.currencyCode' : 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.crpoCurrencyCode',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.crpoRateTypeName',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.crpoRateUnitValue',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.currencyCode' : 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.crpoCurrencyCode',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.crpoRateTypeName',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinProductivity.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeMinProductivity.crpoRateUnitValue',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMinProductivity.currencyCode' : 'sizes.sizePo.sizePricing.surchargeMinProductivity.crpoCurrencyCode',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeMinProductivity.crpoRateTypeName',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscProdDelay.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.crpoRateUnitValue',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscProdDelay.currencyCode' : 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.crpoCurrencyCode',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.crpoRateTypeName',
  PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscVolume.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeDiscVolume.crpoRateUnitValue',
  PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscVolume.currencyCode' : 'sizes.sizePo.sizePricing.surchargeDiscVolume.crpoCurrencyCode',
  PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeDiscVolume.crpoRateTypeName',
  PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscQuality.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeDiscQuality.crpoRateUnitValue',
  PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscQuality.currencyCode' : 'sizes.sizePo.sizePricing.surchargeDiscQuality.crpoCurrencyCode',
  PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeDiscQuality.crpoRateTypeName',
  PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscOther.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeDiscOther.crpoRateUnitValue',
  PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeDiscOther.currencyCode' : 'sizes.sizePo.sizePricing.surchargeDiscOther.crpoCurrencyCode',
  PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeDiscOther.crpoRateTypeName',
  PPM_REPORT_SURCH_MISC_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMiscellaneous.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeMiscellaneous.crpoRateUnitValue',
  PPM_REPORT_SURCH_MISC_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeMiscellaneous.currencyCode' : 'sizes.sizePo.sizePricing.surchargeMiscellaneous.crpoCurrencyCode',
  PPM_REPORT_SURCH_MISC_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeMiscellaneous.crpoRateTypeName',
  PPM_REPORT_SURCH_VAS_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeVas.rateUnitValue' : 'sizes.sizePo.sizePricing.surchargeVas.crpoRateUnitValue',
  PPM_REPORT_SURCH_VAS_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.surchargeVas.currencyCode' : 'sizes.sizePo.sizePricing.surchargeVas.crpoCurrencyCode',
  PPM_REPORT_SURCH_VAS_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.surchargeVas.crpoRateTypeName',
  PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV: 'sizes.sizePo.sizePricing.surchargeVasPercent.rateUnitValue',
  PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeVasPercent.currencyCode',
  PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV_TYPE_NAME: '',
  PPM_REPORT_FASHION_VAS_CRPO_RUV: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVas.rateUnitValue' : 'sizes.sizePo.sizePricing.fashionVas.crpoRateUnitValue',
  PPM_REPORT_FASHION_VAS_CRPO_RUV_CURR_CODE: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVas.currencyCode' : 'sizes.sizePo.sizePricing.fashionVas.crpoCurrencyCode',
  PPM_REPORT_FASHION_VAS_CRPO_RUV_TYPE_NAME: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVas.rateTypeName' : 'sizes.sizePo.sizePricing.fashionVas.crpoRateTypeName',
  PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVasAbs.rateUnitValue' : 'sizes.sizePo.sizePricing.fashionVasAbs.crpoRateUnitValue',
  PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV_CURR_CODE: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVasAbs.currencyCode' : 'sizes.sizePo.sizePricing.fashionVasAbs.crpoCurrencyCode',
  PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV_TYPE_NAME: showForNONPROD ?  'sizes.sizePo.sizePricing.fashionVasAbs.rateTypeName' : 'sizes.sizePo.sizePricing.fashionVasAbs.crpoRateTypeName',
  PPM_REPORT_NET_INCL_SURCH_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.netIncludingSurcharges.rateUnitValue' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoRateUnitValue',
  PPM_REPORT_NET_INCL_SURCH_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.netIncludingSurcharges.currencyCode' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoCurrencyCode',
  PPM_REPORT_NET_INCL_SURCH_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoRateTypeName',
  PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV: 'sizes.sizePo.sizePricing.commissionValue.rateUnitValue',
  PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.commissionValue.currencyCode',
  PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV_TYPE_NAME: '',
  PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV: 'sizes.sizePo.sizePricing.commissionValuePercent.rateUnitValue',
  PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.commissionValuePercent.currencyCode',
  PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV_TYPE_NAME: '',
  PPM_REPORT_FOREIGN_EX_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.foreignExchange.rateUnitValue' : 'sizes.sizePo.sizePricing.foreignExchange.crpoRateUnitValue',
  PPM_REPORT_FOREIGN_EX_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.foreignExchange.currencyCode' : 'sizes.sizePo.sizePricing.foreignExchange.crpoCurrencyCode',
  PPM_REPORT_FOREIGN_EX_CRPO_RUV_TYPE_NAME: pricingNonProd ?  '' : 'sizes.sizePo.sizePricing.foreignExchange.crpoRateTypeName',
  PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV: 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoRateUnitValue',
  PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoCurrencyCode',
  PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV_TYPE_NAME: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoRateTypeName',
  PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV: 'sizes.sizePo.sizePricing.trMkupAccrual.crpoRateUnitValue',
  PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.trMkupAccrual.crpoCurrencyCode',
  PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.trMkupAccrual.crpoRateTypeName',
  PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV: 'sizes.sizePo.sizePricing.netIncludingTrMkup.crpoRateUnitValue',
  PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.netIncludingTrMkup.crpoCurrencyCode',
  PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.netIncludingTrMkup.crpoRateTypeName',
  PPM_REPORT_DUTY_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.dutyNike.rateUnitValue' : 'sizes.sizePo.sizePricing.dutyNike.crpoRateUnitValue',
  PPM_REPORT_DUTY_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.dutyNike.currencyCode' : 'sizes.sizePo.sizePricing.dutyNike.crpoCurrencyCode',
  PPM_REPORT_DUTY_CRPO_RUV_TYPE_NAME: pricingNonProd ?  'sizes.sizePo.sizePricing.dutyNike.rateTypeName' : 'sizes.sizePo.sizePricing.dutyNike.crpoRateTypeName',
  PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.factoryFreight.rateUnitValue' : 'sizes.sizePo.sizePricing.factoryFreight.crpoRateUnitValue',
  PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.factoryFreight.currencyCode' : 'sizes.sizePo.sizePricing.factoryFreight.crpoCurrencyCode',
  PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV_TYPE_NAME: pricingNonProd ?  'sizes.sizePo.sizePricing.factoryFreight.rateTypeName' : 'sizes.sizePo.sizePricing.factoryFreight.crpoRateTypeName',
  PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV: pricingNonProd ?  'sizes.sizePo.sizePricing.nikeFreight.rateUnitValue' : 'sizes.sizePo.sizePricing.nikeFreight.crpoRateUnitValue',
  PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV_CURR_CODE: pricingNonProd ?  'sizes.sizePo.sizePricing.nikeFreight.currencyCode' : 'sizes.sizePo.sizePricing.nikeFreight.crpoCurrencyCode',
  PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV_TYPE_NAME: pricingNonProd ?  'sizes.sizePo.sizePricing.nikeFreight.rateTypeName' : 'sizes.sizePo.sizePricing.nikeFreight.crpoRateTypeName',
  PPM_REPORT_FOB_TR_CO_CRPO_RUV: 'sizes.sizePo.sizePricing.fob.trcoRateUnitValue',
  PPM_REPORT_FOB_TR_CO_CRPO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.fob.trcoCurrencyCode',
  PPM_REPORT_FOB_TR_CO_CRPO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.fob.trcoRateTypeName',
  PPM_REPORT_TOOLING_TRCO_RUV: 'sizes.sizePo.sizePricing.tooling.trcoRateUnitValue',
  PPM_REPORT_TOOLING_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.tooling.trcoCurrencyCode',
  PPM_REPORT_TOOLING_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.tooling.trcoRateTypeName',
  PPM_REPORT_GROSS_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.grossPrice.trcoCurrencyCode',
  PPM_REPORT_GROSS_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.grossPrice.trcoRateTypeName',
  PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV: 'sizes.sizePo.sizePricing.bGradeDiscAuto.trcoRateUnitValue',
  PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.bGradeDiscAuto.trcoCurrencyCode',
  PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.bGradeDiscAuto.trcoRateTypeName',
  PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV: 'sizes.sizePo.sizePricing.bGradeDiscManual.trcoRateUnitValue',
  PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.bGradeDiscManual.trcoCurrencyCode',
  PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.bGradeDiscManual.trcoRateTypeName',
  PPM_REPORT_SURCH_SLT_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeSlt.trcoRateUnitValue',
  PPM_REPORT_SURCH_SLT_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeSlt.trcoCurrencyCode',
  PPM_REPORT_SURCH_SLT_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeSlt.trcoRateTypeName',
  PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeSltpercent.trcoRateUnitValue',
  PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeSltpercent.trcoCurrencyCode',
  PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeSltpercent.trcoRateTypeName',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.trcoRateUnitValue',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.trcoCurrencyCode',
  PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeMinMatMainBody.trcoRateTypeName',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.trcoRateUnitValue',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.trcoCurrencyCode',
  PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeMinMaterialTrim.trcoRateTypeName',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeMinProductivity.trcoRateUnitValue',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeMinProductivity.trcoCurrencyCode',
  PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeMinProductivity.trcoRateTypeName',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.trcoRateUnitValue',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.trcoCurrencyCode',
  PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeDiscProdDelay.trcoRateTypeName',
  PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeDiscVolume.trcoRateUnitValue',
  PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeDiscVolume.trcoCurrencyCode',
  PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeDiscVolume.trcoRateTypeName',
  PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeDiscQuality.trcoRateUnitValue',
  PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeDiscQuality.trcoCurrencyCode',
  PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeDiscQuality.trcoRateTypeName',
  PPM_REPORT_SURCH_DISC_OTHER: 'sizes.sizePo.sizePricing.surchargeDiscOther.trcoRateUnitValue',
  PPM_REPORT_SURCH_DISC_OTHER_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeDiscOther.trcoCurrencyCode',
  PPM_REPORT_SURCH_DISC_OTHER_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeDiscOther.trcoRateTypeName',
  PPM_REPORT_SURCH_MISC_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeMiscellaneous.trcoRateUnitValue',
  PPM_REPORT_SURCH_MISC_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeMiscellaneous.trcoCurrencyCode',
  PPM_REPORT_SURCH_MISC_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeMiscellaneous.trcoRateTypeName',
  PPM_REPORT_SURCH_VAS_TRCO_RUV: 'sizes.sizePo.sizePricing.surchargeVas.trcoRateUnitValue',
  PPM_REPORT_SURCH_VAS_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.surchargeVas.trcoCurrencyCode',
  PPM_REPORT_SURCH_VAS_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.surchargeVas.trcoRateTypeName',
  PPM_REPORT_FASHION_VAS_TRCO_RUV: 'sizes.sizePo.sizePricing.fashionVas.trcoRateUnitValue',
  PPM_REPORT_FASHION_VAS_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.fashionVas.trcoCurrencyCode',
  PPM_REPORT_FASHION_VAS_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.fashionVas.trcoRateTypeName',
  PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV: 'sizes.sizePo.sizePricing.fashionVasAbs.trcoRateUnitValue',
  PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.fashionVasAbs.trcoCurrencyCode',
  PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.fashionVasAbs.trcoRateTypeName',
  PPM_REPORT_NET_INCL_SURCH_TRCO_RUV: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoRateUnitValue',
  PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoCurrencyCode',
  PPM_REPORT_NET_INCL_SURCH_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoRateTypeName',
  PPM_REPORT_FOREIGN_EX_TRCO_RUV: 'sizes.sizePo.sizePricing.foreignExchange.trcoRateUnitValue',
  PPM_REPORT_FOREIGN_EX_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.foreignExchange.trcoCurrencyCode',
  PPM_REPORT_FOREIGN_EX_TRCO_RUV_TYPE_NAME: 'sizes.sizePo.sizePricing.foreignExchange.trcoRateTypeName',
  PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV: 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoRateUnitValue',
  PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV_CURR_CODE: 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoCurrencyCode',
  PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV_TYPE_NAME: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoRateTypeName',
  REPORT_ID: 'sizes.sizeId',
  PPM_REPORT_VAS_ITEM: 'poLine.itemVasDetail',
  PPM_REPORT_VAS_SIZE: 'sizes.sizeVas',
  PPM_REPORT_DELETION_INDICATOR: 'sizes.sizePo.filterInDPOM',
  PPM_REPORT_DIVERT_FLAG: 'poLine.conversionDivertedIndicator',
  PPM_REPORT_DIVERTED_FROM: 'poLine.createdFrom',
  PPM_REPORT_DIVERTED_TO: 'poLine.movedTo',
  PPM_REPORT_USECASE_CODE: 'sizes.sizePo.grcUseCaseCode',
  PPM_REPORT_USECASE_CODE_DESC: 'sizes.sizePo.grcUseCaseDescription',
  PPM_REPORT_ADDRESS_OVERRIDE_INDICATOR: 'poLine.shipping.address.addressOverrideIndicator',
  PPM_REPORT_ASIAN_CC: 'poLine.asianSpecificSizeIsRelevant',
  // PPM_REPORT_KOREA_SIZE: 'poLine.korea',
  // PPM_REPORT_CHINA_SIZE: 'poLine.china',
  PPM_REPORT_KOREA_WAIST_SIZE: 'sizes.sizeProduct.kr.waistSizeCode',
  PPM_REPORT_KOREA_CHEST_SIZE: 'sizes.sizeProduct.kr.chestSizeCode',
  PPM_REPORT_KOREA_AGE_BY_SIZE: 'sizes.sizeProduct.kr.ageBySizeCode',
  PPM_REPORT_CHINA_TOP_SIZE: 'sizes.sizeProduct.cn.topSizeCode',
  PPM_REPORT_CHINA_BOTTOM_SIZE: 'sizes.sizeProduct.cn.bottomSizeCode'
};
