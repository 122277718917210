import { ACTIVE_FEATURES } from '../featureFlag';
import airFreightReportFieldMapping from '../fieldMappings/airFreightReportFieldMapping';

export default {
  [airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER, secondary: '', type: 'string', link: false, label: 'Purchase Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true, isCollapsible: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER, secondary: '', type: 'string', link: false, label: 'PO Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OGAC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OGAC, secondary: '', type: 'date', link: false, label: 'OGAC', requestDataType: 'dynamicDate', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GAC_REASON_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GAC_REASON_CODE, secondary: '', type: 'string', link: false, label: 'GAC Reason Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GAC_USECASE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GAC_USECASE_CODE, secondary: airFreightReportFieldMapping.AIR_FREIGHT_GAC_USECASE_CODE_DESC, type: 'string', link: false, label: 'GRC Usecase Code', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_QUANTITY]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_QUANTITY, secondary: '', type: 'string', link: false, label: 'Quantity', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_NAF]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_NAF, secondary: '', type: 'string', link: false, label: 'NAF%', numeric: false, disablePadding: false, group: true, wrap: true, default: true, width: '6%', includeInRequest: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_FAF]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_FAF, secondary: '', type: 'string', link: false, label: 'FAF%', numeric: false, disablePadding: false, group: true, wrap: true, default: true, width: '7%', includeInRequest: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER,
    // secondary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER,
    label: 'Trading Co Purchase Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_VENDOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_VENDOR,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_VENDOR_NAME,
    label: 'Vendor',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_VENDOR_NAME]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_VENDOR_NAME,
    secondary: '',
    label: 'Vendor Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string'
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DIVISION_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DIVISION_CODE,
    // secondary: airFreightReportFieldMapping.AIR_FREIGHT_VENDOR_NAME,
    label: 'Division',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_NAME,
    label: 'Product',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_NAME]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_NAME,
    secondary: '',
    label: 'Product Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string'
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ITEM_QTY]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ITEM_QTY,
    label: 'Total Item Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true,
    width: '10%'
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SEASON_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SEASON_CODE,
    label: 'Season',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SEASON_YEAR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SEASON_YEAR,
    label: 'Season Year',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH,
    label: 'Launch Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_MIDSOLE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_MIDSOLE_CODE,
    label: 'Midsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OUTSOLE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OUTSOLE_CODE,
    label: 'Outsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PROMO_ONLY_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PROMO_ONLY_INDICATOR,
    label: 'Promo Only Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_ORDER_REASON_DESCRIPTION,
    label: 'Reason For Ordering',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_STYLE_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_STYLE_NUMBER,
    label: 'Style Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_EVENT]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_EVENT,
    label: 'Current Event',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_NEXT_EVENT]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_NEXT_EVENT,
    label: 'Next Event',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_PO]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_PO,
    label: 'Customer PO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE_DESC,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_ORG_NAME,
    label: 'Purchase Org',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_NAME,
    label: 'Purchase Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_COLOR_DESC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_COLOR_DESC,
    label: 'Color Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CREATED_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CREATED_DATE,
    label: 'Create Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_SUBMISSION_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_SUBMISSION_DATE,
    label: 'Request Submission Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DOC_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DOC_DATE,
    label: 'Document Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CHANGED_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CHANGED_DATE,
    label: 'Change Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GAC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GAC,
    label: 'Current GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH_DATE,
    label: 'Launch Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_EVENT_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_EVENT_DATE,
    label: 'Current Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_NEXT_EVENT_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_NEXT_EVENT_DATE,
    label: 'Next Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ACCEPT_PO_ACTUAL_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ACCEPT_PO_ACTUAL_DATE,
    label: 'Acceptance Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_FACTORY_DELIVERY_ACTUAL_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_FACTORY_DELIVERY_ACTUAL_DATE,
    label: 'Factory Delivery Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_EX_FACTORY_ACTUAL_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_EX_FACTORY_ACTUAL_DATE,
    label: 'Ex Factory Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_MRGAC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_MRGAC,
    label: 'MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_FX_ADJ_REQUIRED_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_FX_ADJ_REQUIRED_INDICATOR,
    label: 'FX Adjustment Required Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PLANT_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PLANT_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PLANT_NAME,
    label: 'Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_FOB_MISMATCH_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_FOB_MISMATCH_INDICATOR,
    label: 'Fob Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SIZE_MISMATCH_ID]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SIZE_MISMATCH_ID,
    label: 'Size Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_REQ_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_REQ_DATE,
    label: 'Customer Requested Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    // requestDataType: 'dynamicDate'
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_VAS_ITEM]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_VAS_ITEM,
    label: 'Item Vas',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_R3_DOC_TYPE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_R3_DOC_TYPE,
    label: 'Historical Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CREATED_BY]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CREATED_BY,
    label: 'Created By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TITLE_TRANSFER_MODEL_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TITLE_TRANSFER_MODEL_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_TITLE_TRANSFER_MODEL_DESC,
    label: 'TTMI',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_MFG_ORIGIN_COUNTRY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_MFG_ORIGIN_COUNTRY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_MFG_ORIGIN_COUNTRY_NAME,
    label: 'MCO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PO_COMPANY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PO_COMPANY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PO_COMPANY_DESC,
    label: 'Company',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_COMPANY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_COMPANY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_COMPANY_DESC,
    label: 'Company Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ITEM_STATUS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ITEM_STATUS,
    label: 'DPOM Line Item Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_UNIT_OF_MEASURE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_UNIT_OF_MEASURE_CODE,
    label: 'UoM',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_NAME,
    label: 'Destination Country',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PLANT_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PLANT_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PLANT_NAME,
    label: 'Trading Company Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TRANSPORT_MODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TRANSPORT_MODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_TRANSPORT_MODE_DESC,
    label: 'Mode of Transportation',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SHIPPING_TYPE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SHIPPING_TYPE,
    label: 'Shipping Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DIRECT_SALES_ORDER_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DIRECT_SALES_ORDER_NUMBER,
    label: 'Direct Ship Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DIRECT_SALES_ORDER_ITEM_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DIRECT_SALES_ORDER_ITEM_NUMBER,
    label: 'Direct Ship Sales Order Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DELIVERY_COMPLETE_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DELIVERY_COMPLETE_INDICATOR,
    label: 'Delivery Complete Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CANCEL_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CANCEL_DATE,
    label: 'Cancel Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CUST_ISO_COUNTRY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CUST_ISO_COUNTRY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_COUNTRY_NAME,
    label: 'Customer Country',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SALES_ORDER_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SALES_ORDER_NUMBER,
    label: 'Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SALES_ORDER_ITEM_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SALES_ORDER_ITEM_NUMBER,
    label: 'Sales Order Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ITEM_TEXT_DETAIL]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ITEM_TEXT_DETAIL,
    label: 'Item Text',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CATEGORY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CATEGORY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_CATEGORY_DESC,
    label: 'Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_SUB_CATEGORY_DESC,
    label: 'Sub Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_CATEGORY_FOCUS_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_CATEGORY_FOCUS_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_CATEGORY_FOCUS_DESC,
    label: 'Global Category Core Focus',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GLOABL_CATEGORY_SUMMARY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GLOABL_CATEGORY_SUMMARY_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_GLOABL_CATEGORY_SUMMARY_DESC,
    label: 'Global Category Summary',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GENDER_AGE_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GENDER_AGE_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_GENDER_AGE_DESC,
    label: 'Gender Age',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_MARKETING_ID]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_MARKETING_ID,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_MARKETING_DESC,
    label: 'Marketing Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_SILHOUTTE_TYPE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_SILHOUTTE_TYPE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_SILHOUTTE_TYPE_DESC,
    label: 'Silhouette',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_DIMENSION_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_DIMENSION_CODE,
    label: 'Dimension Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_REFILL_CLASS_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_REFILL_CLASS_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_REFILL_CLASS_DESC,
    label: 'Product Refill Class',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_LEAGUE_ID_DESC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_LEAGUE_ID_DESC,
    label: 'League ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PO_REJECTION_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PO_REJECTION_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PO_REJECTION_CODE_DESC,
    label: 'DPOM Rejection Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERDUE_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERDUE_INDICATOR,
    label: 'Overdue Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO_NAME,
    label: 'Ship To Customer',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_GROUP]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_GROUP,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_GROUP_DESC,
    label: 'Global Planning Product Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_CLASS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_CLASS,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_GLOBAL_PRODUCT_CLASS_DESC,
    label: 'Global Planning Product Classification',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PLANNING_PRIORITY_NUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PLANNING_PRIORITY_NUMBER,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PLANNING_PRIORITY_NUMBER_DESC,
    label: 'Planning Priority',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GAC_VS_MRGAC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GAC_VS_MRGAC,
    label: 'Gac Vs Mrgac',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OGAC_VS_MRGAC]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OGAC_VS_MRGAC,
    label: 'Ogac Vs Mrgac',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_MRGAC_CAPACITY_CONSUMPTION_WEEK]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_MRGAC_CAPACITY_CONSUMPTION_WEEK,
    label: 'MRGAC Capacity Consumption Week',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_PMO_DEC_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_PMO_DEC_CODE,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_PMO_DEC_CODE_DESC,
    label: 'PMO/DEC code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Geo Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_TRCO_RELEVANT_INDICATOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_TRCO_RELEVANT_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Trd Co rel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_INITIAL_GAC_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_INITIAL_GAC_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Initial GAC Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    width: '10%'
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_PONUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_PONUMBER, secondary: '', type: 'string', link: false, label: 'Purchase Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true, isCollapsible: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_POITEMNUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_POITEMNUMBER, secondary: '', type: 'string', link: false, label: 'PO Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_NAF]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_NAF, secondary: '', type: 'string', link: false, label: 'NAF', numeric: false, disablePadding: false, group: true, wrap: true, default: true, width: '6%', includeInRequest: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_FAF]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_FAF, secondary: '', type: 'string', link: false, label: 'FAF', numeric: false, disablePadding: false, group: true, wrap: true, default: true, width: '7%', includeInRequest: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_COMMENTS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_COMMENTS,
    secondary: '',
    label: 'Comments',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_REQUESTOR]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_REQUESTOR,
    secondary: '',
    label: 'Requestor Email',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_STATUS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_STATUS,
    secondary: '',
    label: 'Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS_UPLOAD]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS_UPLOAD,
    secondary: '',
    label: 'Attachment',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS,
    secondary: '',
    label: 'View Attachment',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ELIGIBLE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ELIGIBLE,
    secondary: '',
    type: 'boolean',
    label: 'Eligible For Override',
    includeInRequest: false,
    group: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_PONUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_PONUMBER, secondary: '', type: 'string', link: false, label: 'Purchase Order Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true, isCollapsible: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_POITEMNUMBER]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_POITEMNUMBER, secondary: '', type: 'string', link: false, label: 'PO Line Item Number', numeric: false, disablePadding: false, group: true, wrap: true, default: true
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUESTOR_EMAIL]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUESTOR_EMAIL,
    secondary: '',
    label: 'Requestor Email',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_PMO]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_PMO,
    secondary: '',
    label: 'PMO/DEC code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_GEO_CODE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_GEO_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Geo Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUEST_DATE]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUEST_DATE,
    label: 'Request Submission Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_STATUS]: {
    primary: airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_STATUS,
    secondary: airFreightReportFieldMapping.AIR_FREIGHT_LAST_UPDATED_TS,
    label: 'AF Request Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true,
    featureFlag: ACTIVE_FEATURES.AF_ADDITIONAL_FIELD
  }
};
