/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  updateDialogStates,
  toggleSpinner, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import conversationThreadReportHeader from '../../constants/conversationThreadReportHeader';
import { mapInputFieldsToRequest, mapRequestToInputFields, setValidFields } from '../../helpers/dataHelpers';
import { conversationThreadReportFieldList, initialConversationThreadReportFieldState } from '../../constants/staticData';
import appConfig from '../../appConfig';
import conversationThreadReportFieldProperties from '../../constants/fieldProperties/conversationThreadReportFieldProperties';

export const name = 'conversationThreadReport';

function createInitialState() {
  return {
    conversationThreadReportResultData: {},
    conversationThreadReportSearchId: '',
    conversationThreadReportTotalCount: 0,
    conversationThreadReportNextResults: {},
    conversationThreadReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,

    isSavedSearchModified: false,
    conversationThreadReportRequestBody: null,
    conversationThreadReportInputFieldData: { ...initialConversationThreadReportFieldState },
    conversationThreadReportSelectedFields: [],
    conversationThreadReportBookmarkName: '',
    conversationThreadReportBookmarkId: '',
    requestError: false
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updateConversationThreadReportPage(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_PAGE

    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }

  function updateConversationThreadReportRowsPerPage(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_ROWS_PER_PAGE

    return {
      ...state,
      rowsPerPage: action.payload?.rowsPerPage ? action.payload?.rowsPerPage : action.payload
    };
  }

  function updateConversationThreadReportRequestBody(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_REQUEST_BODY

    return {
      ...state,
      conversationThreadReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(state.conversationThreadReportBookmarkId)
    };
  }

  function updateConversationThreadReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_SEARCH_REQUEST_ON_SAME_BOOKMARK
    return {
      ...state,
      conversationThreadReportRequestBody: action.payload
    };
  }

  function resetConversationThreadReportResults(state: Object) {
    // RESET_CONVERSATION_THREAD_REPORT_RESULTS
    return {
      ...state,
      conversationThreadReportResultData: {},
      conversationThreadReportSearchId: '',
      conversationThreadReportTotalCount: 0,
      conversationThreadReportNextResults: {},
      conversationThreadReportPrevResults: {},
      page: 0,
      rowsPerPage: 100
    };
  }

  function resetConversationThreadReportRequest(state: Object) {
    // RESET_CONVERSATION_THREAD_REPORT_REQUEST
    return {
      ...state,
      isSavedSearchModified: false,
      conversationThreadReportRequestBody: null,
      conversationThreadReportInputFieldData: { ...initialConversationThreadReportFieldState },
      conversationThreadReportSelectedFields: [],
      conversationThreadReportBookmarkName: '',
      conversationThreadReportBookmarkId: '',
      requestError: false
    };
  }

  function updateConversationThreadReportFields(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_SELECTED_FIELDS
    return {
      ...state, conversationThreadReportSelectedFields: action.payload
    };
  }

  function updateConversationThreadReportInputValues(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_INPUT_VALUES
    return { ...state, conversationThreadReportInputFieldData: action.payload };
  }

  function updateConversationThreadReportBookmarkId(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_BOOKMARK_ID
    return {
      ...state,
      isSavedSearchModified: false,
      conversationThreadReportBookmarkId: action.payload
    };
  }

  function clearSavedConversationThreadReport(state: Object) {
    // CLEAR_CONVERSATION_THREAD_REPORT_BOOKMARK
    return {
      ...state,
      isSavedSearchModified: false,
      conversationThreadReportBookmarkId: '',
      conversationThreadReportBookmarkName: ''
    };
  }

  function updateConversationThreadReportBookmarkName(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_BOOKMARK_NAME
    return {
      ...state,
      isSavedSearchModified: false,
      conversationThreadReportBookmarkName: action.payload
    };
  }

  function updateConversationThreadReportResults(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportTotalCount: payload.data.pages.totalResources,
      conversationThreadReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      conversationThreadReportNextResults: null,
      conversationThreadReportPrevResults: null
    };
  }

  function updateConversationThreadReportSearchId(state: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_SEARCH_ID
    return { ...state, conversationThreadReportSearchId: cryptoRand().toString().substr(2, 8) };
  }

  function resetConversationThreadReportInputData(state: Object) {
    // RESET_CONVERSATION_THREAD_REPORT_INPUT_VALUES
    return {
      ...state,
      conversationThreadReportInputFieldData: initialState.conversationThreadReportInputFieldData
    };
  }

  function updateConversationThreadReportNextResult(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_NEXT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportNextResults: payload.dataNext,
      conversationThreadReportTotalPages: payload.data.pages.totalPages,
      conversationThreadReportTotalCount: payload.data.pages.totalResources
    };
  }

  function updateConversationThreadReportPrevResult(state: Object, action: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_PREV_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportPrevResults: payload.dataPrev,
      conversationThreadReportTotalPages: payload.data.pages.totalPages,
      conversationThreadReportTotalCount: payload.data.pages.totalResources
    };
  }

  function conversationThreadReportOffset(state: Object, action: Object) {
    // CONVERSATION_THREAD_REPORT_RESULT_OFFSET
    const payload = {
      data: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportTotalCount: payload.data.pages.totalResources,
      conversationThreadReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      conversationThreadReportNextResults: null,
      conversationThreadReportPrevResults: null
    };
  }

  function ChangeReportAppendNextResultSet(state: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_NEXT_APPEND
    const updatedCurrentResultSet = state.conversationThreadReportResultData;
    const updatedNextResultSet = state.conversationThreadReportNextResults;

    const payload = {
      dataPrev: updatedCurrentResultSet,
      data: updatedNextResultSet
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportPrevResults: payload.dataPrev,
      conversationThreadReportNextResults: null
    };
  }
  // poSearchResultReducer
  // function PoSearchResultsPrev(state: Object, action: Object) {
  //   // PO_SEARCH_RESULT_PREV_SUCCESS
  //   const payload = {
  //     data: action.payload.currentResultSet,
  //     dataPrev: getflattenResponse(action.payload.response.data)
  //   };
  // }

  function ChangeReportAppendPrevResultSet(state: Object) {
    // UPDATE_CONVERSATION_THREAD_REPORT_PREV_APPEND
    const updatedPrevResultSet = state.conversationThreadReportPrevResults;
    const updatedCurrentResultSet = state.conversationThreadReportResultData;

    const payload = {
      data: updatedPrevResultSet,
      dataNext: updatedCurrentResultSet
    };

    return {
      ...state,
      conversationThreadReportResultData: payload.data,
      conversationThreadReportNextResults: payload.dataNext,
      conversationThreadReportPrevResults: null
    };
  }

  function getMetaData(state: Object, action: Object) {
    // GET_CONVERSATION_META_DATA
    return {
      ...state,
      conversationMetaData: action.payload.conversationCodes.objects
    };
  }

  return {
    updateConversationThreadReportPage,
    updateConversationThreadReportRowsPerPage,
    updateConversationThreadReportRequestBody,
    updateConversationThreadReportRequestBodyOnSameBookmark,
    resetConversationThreadReportResults,
    resetConversationThreadReportRequest,
    updateConversationThreadReportFields,
    updateConversationThreadReportInputValues,
    updateConversationThreadReportBookmarkId,
    clearSavedConversationThreadReport,
    updateConversationThreadReportBookmarkName,
    updateConversationThreadReportResults,
    updateConversationThreadReportSearchId,
    resetConversationThreadReportInputData,
    updateConversationThreadReportNextResult,
    updateConversationThreadReportPrevResult,
    conversationThreadReportOffset,
    ChangeReportAppendNextResultSet,
    // PoSearchResultsPrev,
    ChangeReportAppendPrevResultSet,
    getMetaData
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchConversationThreadReports() {
    return createAsyncThunk(
      `${name}/fetchConversationThreadReports`,
      async ({
        data,
        callback,
        modifiedrequestBody = {}
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const secondaryFields = [];
        const fields = conversationThreadReportHeader
          .filter((header) => header.default)
          .map((header) => {
            if (header.secondary) {
              secondaryFields.push(header.secondary);
            }
            return header.primary;
          });
        const state = getState();
        const rowsPerPageOption = state.searchpaneldata
          && state.searchpaneldata?.rowPerPageOption;
        let requestData = {};
        if (data) {
          requestData = mapInputFieldsToRequest(data, 'conversationThreadReport');
          const appliedConversationThreadSearchField = [
            ...new Set(requestData.map((field) => field.fieldName))
          ];
          dispatch(actions.updateConversationThreadReportInputValues(data));
          dispatch(actions.updateConversationThreadReportFields(conversationThreadReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedConversationThreadSearchField.includes(field))));
        } else {
          requestData = state.conversationThreadReport.conversationThreadReportRequestBody.search;
        }

        const requestBody = (state.conversationThreadReport.conversationThreadReportRequestBody)
          ? {
            ...state.conversationThreadReport.conversationThreadReportRequestBody,
            search: requestData,
            offset: '0',
            ...modifiedrequestBody
          } : {
            fields: [...fields, ...secondaryFields],
            search: requestData,
            // filter: [],
            count: appConfig.SearchThresholdLimit,
            offset: '0',
            ...modifiedrequestBody
          };
        request({
          api: 'conversationThreadReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            dispatch(actions.updateConversationThreadReportRequestBody(requestBody));
            dispatch(toggleSpinner(false));
            callback(response.data);
            dispatch(actions.updateConversationThreadReportRowsPerPage(rowsPerPageOption));
            dispatch(actions.updateConversationThreadReportResults(response));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function exportConversationThreadReport() {
    return createAsyncThunk(
      `${name}/exportConversationThreadReport`,
      async ({
        callback, filetype
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        const searchRequestBody = {
          ...state.conversationThreadReport.conversationThreadReportRequestBody
        };
        const conversationThreadReportRequestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedPOReportFieldsInExportedFile.includes(field)
          )),
          // filter: [...(searchRequestBody.filter) || []],
          searchType: searchRequestBody.searchType,
          fileFormat: filetype
        };
        request({
          api: 'conversationThreadReportExportLargeFile',
          method: 'post',
          data: conversationThreadReportRequestBody
        }, dispatch, getState)
          .then((response) => {
            callback(response);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            callback(null, error);
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function fetchSavedConversationThreadReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedConversationThreadReportResults`,
      async ({
        conversationThreadReportRequestData,
        callback
      }, { getState, dispatch }) => {
        const state = getState();
        const rowsPerPageOption = state.searchpaneldata
          && state.searchpaneldata?.rowPerPageOption;
        let requestBody = { ...conversationThreadReportRequestData.searchCriteria, offset: '0' };
        requestBody = setValidFields(conversationThreadReportFieldProperties, requestBody);
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        request({
          api: 'conversationThreadReportSearch',
          method: 'post',
          data: {
            savedSearchID: conversationThreadReportRequestData.id,
            ...requestBody
          },
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            const fields = mapRequestToInputFields([
              ...conversationThreadReportRequestData.searchCriteria.search
            ]);
            const conversationThreadSearchFields = [...new Set(
              conversationThreadReportRequestData.searchCriteria.search.map(
                (field) => field.fieldName
              )
            )];
            dispatch(actions.updateConversationThreadReportInputValues(
              fields
            ));
            dispatch(actions.updateConversationThreadReportRequestBody(requestBody));
            dispatch(actions.updateConversationThreadReportFields(conversationThreadReportFieldList
              .map((field) => field.key)
              .filter((field) => conversationThreadSearchFields.includes(field))));
            dispatch(actions.updateConversationThreadReportResults(response));
            dispatch(actions.updateConversationThreadReportSearchId());
            dispatch(actions.updateConversationThreadReportPage(0));
            dispatch(actions.updateConversationThreadReportRowsPerPage(rowsPerPageOption));
            dispatch(
              actions.updateConversationThreadReportBookmarkId(
                conversationThreadReportRequestData.id
              )
            );
            dispatch(
              actions.updateConversationThreadReportBookmarkName(
                conversationThreadReportRequestData.name
              )
            );
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            console.log(error);
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function fetchConversationThreadreportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchConversationThreadreportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        // TODO
        if (state.conversationThreadReport.conversationThreadReportRequestBody
          && state.conversationThreadReport.conversationThreadReportResultData) {
          const conversationThreadReportUpdatedRequest = state.conversationThreadReport
            .conversationThreadReportRequestBody;
          delete conversationThreadReportUpdatedRequest.offset;
          conversationThreadReportUpdatedRequest.offset = '0';
          dispatch(toggleSpinner(true));
          request({
            api: 'conversationThreadReportSearch',
            method: 'post',
            data: conversationThreadReportUpdatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.conversationThreadReportOffset(response));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateConversationThreadReportSearchId());
            })
            .catch((error) => {
              console.log('NEXT Error ', error);
              dispatch(toggleSpinner(false));
            });
        }
      }
    );
  }

  function conversationThreadReportDataOffset() {
    return createAsyncThunk(
      `${name}/conversationThreadReportDataOffset`,
      async ({
        pofetchConversationThreadreportsResultsOffset
      }, { dispatch }) => {
        dispatch(pofetchConversationThreadreportsResultsOffset());
      }
    );
  }

  function fetchConversationThreadReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchConversationThreadReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        // TODO
        if (state.conversationThreadReport.conversationThreadReportRequestBody
          && state.conversationThreadReport.conversationThreadReportResultData
          && state.conversationThreadReport.conversationThreadReportResultData.pages.next) {
          const currentResultSet = state.conversationThreadReport
            .conversationThreadReportResultData;
          const updatedRequest = state.conversationThreadReport
            .conversationThreadReportRequestBody;
          const searchResultNextOffset = state.conversationThreadReport
            .conversationThreadReportResultData.pages.next;
          delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest.offset = searchResultNextOffset.slice(7, resNext);
          dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
          request({
            api: 'conversationThreadReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(
                actions.updateConversationThreadReportRequestBodyOnSameBookmark(updatedRequest)
              );
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              dispatch(toggleSpinner(false));
              dispatch(
                actions.updateConversationThreadReportNextResult(response, currentResultSet)
              );
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              console.log('NEXT Error ', error);
            });
        }
      }
    );
  }

  function ChangeReportsDataNextSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataNextSet`,
      async ({
        pofetchConversationThreadReportNextResultSet
      }, { dispatch }) => {
        dispatch(pofetchConversationThreadReportNextResultSet());
      }
    );
  }

  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.conversationThreadReport
          && state.conversationThreadReport.conversationThreadReportResultData
          && state.conversationThreadReport.conversationThreadReportResultData.pages.prev) {
          const currentResultSet = state.conversationThreadReport
            .conversationThreadReportResultData;
          const updatedRequest = state.conversationThreadReportRequest
            .conversationThreadReportRequestBody;
          const searchResultPrevOffset = state.conversationThreadReport
            .conversationThreadReportResultData.pages.prev;
          delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest.offset = searchResultPrevOffset.slice(7, res);
          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'conversationThreadReportSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(
                actions.updateConversationThreadReportRequestBodyOnSameBookmark(updatedRequest)
              );
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              dispatch(toggleSpinner(false));
              dispatch(
                actions.updateConversationThreadReportPrevResult({ response, currentResultSet })
              );
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              console.log('PREV Error ', error);
            });
        }
      }
    );
  }

  function ChangeReportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataPrevSet`,
      async ({
        pofetchReportPrevResultSet
      }, { dispatch }) => {
        dispatch(pofetchReportPrevResultSet());
      }
    );
  }

  function getConversationCodes() {
    return createAsyncThunk(
      `${name}/getConversationCodes`,
      async ({
        callback
      }, { getState, dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ getConversationCodesSpinner: true }));
        const fields = 'ConversationCode,ConversationDescription,modifiedBy,modifiedDate,isActive,id,conversationCategory';
        const filters = 'isActive(false),isActive(true)';
        const query = `?fields=${fields}&filter=${filters}`;
        request({
          api: 'getConversationCodes',
          routeParams: { query },
          method: 'get'
        }, dispatch, getState).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ getConversationCodesSpinner: false }));
          payload.conversationCodes = response.data;
          dispatch(actions.getMetaData(payload));
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ getConversationCodesSpinner: false }));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  return {
    fetchConversationThreadReports: fetchConversationThreadReports(),
    exportConversationThreadReport: exportConversationThreadReport(),
    fetchSavedConversationThreadReportResults: fetchSavedConversationThreadReportResults(),
    fetchConversationThreadreportsResultsOffset: fetchConversationThreadreportsResultsOffset(),
    conversationThreadReportDataOffset: conversationThreadReportDataOffset(),
    fetchConversationThreadReportNextResultSet: fetchConversationThreadReportNextResultSet(),
    ChangeReportsDataNextSet: ChangeReportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    ChangeReportsDataPrevSet: ChangeReportsDataPrevSet(),
    getConversationCodes: getConversationCodes()
  };
}

export const extraActions = createExtraActions();

export const conversationThreadReportActions = { ...actions, ...extraActions };
export const conversationThreadReportReducer = slice.reducer;
