/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request, { cancelRequest } from '../../services/request';
import {
  updateDialogStates,
  toggleSpinner, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import poChangeReportsHeader from '../../constants/poChangeReportHeader';
import { initialPOChangeReportFieldState, poChangeReportFieldList } from '../../constants/staticData';
import {
  mapInputFieldsToRequest, mapRequestToInputFields, checkForColumnData, setValidFields
} from '../../helpers/dataHelpers';
// import { poReportFieldList, initialReportFieldsState } from '../../constants/staticData';
import appConfig from '../../appConfig';
import poChangeReportFieldProperties from '../../constants/fieldProperties/poChangeReportFieldProperties';

export const name = 'poChangeReport';

function createInitialState() {
  return {
    isSavedSearchModified: false,
    poChangeReportRequestBody: null,
    poChangeReportInputFieldData: { ...initialPOChangeReportFieldState },
    poChangeReportSelectedFields: [],
    poChangeReportBookmarkName: '',
    poChangeReportBookmarkId: '',
    requestError: false,

    poChangeReportResultData: {},
    poChangeReportSearchId: '',
    poChangeReportTotalCount: 0,
    poChangeReportNextResults: {},
    poChangeReportPrevResults: {},
    page: 0,
    rowsPerPage: 100
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updatePOChangeReportPage(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_PAGE
    return { ...state, page: action.payload };
  }

  function updatePOChangeReportRowsPerPage(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_ROWS_PER_PAGE
    return { ...state, rowsPerPage: action.payload };
  }

  function updatePOChangeReportRequestBody(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_REQUEST_BODY
    return {
      ...state,
      poChangeReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(state.poChangeReportBookmarkId)
    };
  }

  function updatePOChangeReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    // UPDATE_POCHANGE_SEARCH_REQUEST_ON_SAME_BOOKMARK
    return {
      ...state,
      poChangeReportRequestBody: action.payload
    };
  }

  function resetPoChangeReportResults(state: Object) {
    // RESET_PO_CHANGE_REPORT_RESULTS
    return {
      ...state,
      poChangeReportResultData: {},
      poChangeReportSearchId: '',
      poChangeReportTotalCount: 0,
      poChangeReportNextResults: {},
      poChangeReportPrevResults: {},
      page: 0,
      rowsPerPage: 100
    };
  }

  function resetPoChangeReportRequest(state: Object) {
    // RESET_PO_CHANGE_REPORT_REQUEST
    return {
      ...state,
      isSavedSearchModified: false,
      poChangeReportRequestBody: null,
      poChangeReportInputFieldData: { ...initialPOChangeReportFieldState },
      poChangeReportSelectedFields: [],
      poChangeReportBookmarkName: '',
      poChangeReportBookmarkId: '',
      requestError: false
    };
  }

  function resetPOChangeReportRequestForClearSearch(state: Object) {
    // RESET_PO_CHANGE_REPORT_REQUEST_DATA_CLEAR_SEARCH
    return {
      ...state,
      isSavedSearchModified: false,
      poChangeReportRequestBody: null,
      poChangeReportInputFieldData: { ...initialPOChangeReportFieldState },
      poChangeReportSelectedFields: [...new Set(state.poChangeReportSelectedFields)],
      poChangeReportBookmarkName: '',
      poChangeReportBookmarkId: '',
      requestError: false
    };
  }

  function updatePOChangeReportFields(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_SELETED_FIELDS
    return {
      ...state, poChangeReportSelectedFields: action.payload
    };
  }

  function updatePOChangeReportInputValues(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_INPUT_VALUES
    return { ...state, poChangeReportInputFieldData: action.payload };
  }

  function updatePOChangeReportBookmarkId(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_BOOKMARK_ID
    return { ...state, isSavedSearchModified: false, poChangeReportBookmarkId: action.payload };
  }

  function clearSavedPOChangeReport(state: Object) {
    // CLEAR_PO_CHANGE_REPORT_BOOKMARK
    return {
      ...state,
      isSavedSearchModified: false,
      poChangeReportBookmarkId: '',
      poChangeReportBookmarkName: ''
    };
  }

  function updatePOChangeReportBookmarkName(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_BOOKMARK_NAME
    return { ...state, isSavedSearchModified: false, poChangeReportBookmarkName: action.payload };
  }
  // TODO: fn not used
  // function updateReportFilterDropDownValues(state: Object) {
  //   // UPDATE_REPORT_FILTER_DROPDOWN_VALUES
  //   return {
  //     ...state
  //   };
  // }

  function updatePOChangeReportResults(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };

    return {
      ...state,
      poChangeReportResultData: payload.data,
      poChangeReportTotalCount: payload.data.pages.totalResources,
      poChangeReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      poChangeReportNextResults: null,
      poChangeReportPrevResults: null
    };
  }

  function updatePOChangeReportSearchId(state: Object) {
    // UPDATE_PO_CHANGE_REPORT_SEARCH_ID
    return { ...state, poChangeReportSearchId: cryptoRand().toString().substr(2, 8) };
  }

  function resetPOChangeReportInputData(state: Object) {
    // RESET_PO_CHANGE_REPORT_INPUT_VALUES
    return { ...state, poChangeReportInputFieldData: initialState.poChangeReportInputFieldData };
  }

  function updatePOChangeReportNextResult(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_NEXT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      poChangeReportResultData: payload.data,
      poChangeReportNextResults: payload.dataNext,
      poChangeReportTotalPages: payload.data.pages.totalPages,
      poChangeReportTotalCount: payload.data.pages.totalResources
    };
  }

  function updatePOChangeReportPrevResult(state: Object, action: Object) {
    // UPDATE_PO_CHANGE_REPORT_PREV_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      poChangeReportResultData: payload.data,
      poChangeReportPrevResults: payload.dataPrev,
      poChangeReportTotalPages: payload.data.pages.totalPages,
      poChangeReportTotalCount: payload.data.pages.totalResources
    };
  }

  function PoChangeReportOffset(state: Object, action: Object) {
    // PO_CHANGE_REPORT_RESULT_OFFSET
    const payload = {
      data: getflattenResponse(action.payload.data)
    };

    return {
      ...state,
      poChangeReportResultData: payload?.data,
      poChangeReportTotalCount: payload?.data?.pages?.totalResources,
      poChangeReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      poChangeReportNextResults: null,
      poChangeReportPrevResults: null
    };
  }

  // function fetchPoChangeReportNextResultSet(state: Object, action: Object) {
  //   // UPDATE_PO_CHANGE_REPORT_NEXT_RESULTS
  //   const payload = {
  //     data: getflattenResponse(action.payload.response.data)
  //   };

  //   return {
  //     ...state,
  //     poChangeReportResultData: payload.data,
  //     poChangeReportNextResults: payload.dataNext,
  //     poChangeReportTotalPages: payload.data.pages.totalPages,
  //     poChangeReportTotalCount: payload.data.pages.totalResources
  //   };
  // }

  function ChangeReportAppendNextResultSet(state: Object) {
    // UPDATE_PO_CHANGE_REPORT_NEXT_APPEND
    const payload = {
      dataPrev: state.poChangeReportResultData,
      data: state.poChangeReportNextResults
    };

    return {
      ...state,
      poChangeReportResultData: payload.data,
      poChangeReportPrevResults: payload.dataPrev,
      poChangeReportNextResults: null
    };
  }

  function ChangeReportAppendPrevResultSet(state: Object) {
    // UPDATE_PO_CHANGE_REPORT_PREV_APPEND
    const payload = {
      data: state.poChangeReportPrevResults,
      dataNext: state.poChangeReportResultData
    };

    return {
      ...state,
      poChangeReportResultData: payload.data,
      poChangeReportNextResults: payload.dataNext,
      poChangeReportPrevResults: null
    };
  }

  return {
    updatePOChangeReportPage,
    updatePOChangeReportRowsPerPage,
    updatePOChangeReportRequestBody,
    updatePOChangeReportRequestBodyOnSameBookmark,
    resetPoChangeReportResults,
    resetPoChangeReportRequest,
    resetPOChangeReportRequestForClearSearch,
    updatePOChangeReportFields,
    updatePOChangeReportInputValues,
    updatePOChangeReportBookmarkId,
    clearSavedPOChangeReport,
    updatePOChangeReportBookmarkName,
    // updateReportFilterDropDownValues,
    updatePOChangeReportResults,
    updatePOChangeReportSearchId,
    resetPOChangeReportInputData,
    updatePOChangeReportNextResult,
    updatePOChangeReportPrevResult,
    PoChangeReportOffset,
    // fetchPoChangeReportNextResultSet,
    ChangeReportAppendNextResultSet,
    ChangeReportAppendPrevResultSet
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function fetchPoChangeReports() {
    return createAsyncThunk(
      `${name}/fetchPoChangeReports`,
      async ({
        data,
        callback,
        modifiedrequestBody = {}
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const secondaryFields = [];
        const fields = poChangeReportsHeader
          .filter((header) => header.default)
          .map((header) => {
            if (header.secondary) {
              secondaryFields.push(header.secondary);
            }
            return header.primary;
          });
        const state = getState();
        let requestData = {};
        const {
          selectedColumnOrderOptions: columnOrderOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};

        const fieldsForRequest = checkForColumnData(state, true)
          ? columnOrderOptions
          : [...fields, ...secondaryFields];
        if (data) {
          requestData = mapInputFieldsToRequest(data, 'poChangeReport');
          const appliedPoChangeSearchField = [...new Set(
            requestData.map((field) => field.fieldName)
          )];
          dispatch(actions.updatePOChangeReportInputValues(data));
          dispatch(actions.updatePOChangeReportFields(poChangeReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedPoChangeSearchField.includes(field))));
        } else {
          requestData = state.poChangeReport.poChangeReportRequestBody.search;
        }

        let requestBody = (state.poChangeReport.poChangeReportRequestBody) ? {
          ...state.poChangeReport.poChangeReportRequestBody,
          search: requestData,
          offset: '0',
          ...modifiedrequestBody
        } : {
          fields: fieldsForRequest,
          search: requestData,
          filter: [],
          count: appConfig.SearchThresholdLimit,
          offset: '0',
          ...modifiedrequestBody
        };
        requestBody = setValidFields(poChangeReportFieldProperties, requestBody);

        request({
          api: 'poChangeReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            dispatch(cancelRequest());
            dispatch(actions.updatePOChangeReportRequestBody(requestBody));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response.data);
            }
            dispatch(actions.updatePOChangeReportRowsPerPage(rowsPerPageOption));
            dispatch(actions.updatePOChangeReportResults(response));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function exportPoChangeReport() {
    return createAsyncThunk(
      `${name}/exportPoChangeReport`,
      async ({
        callback,
        filetype
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        const searchRequestBody = {
          ...state.poChangeReport.poChangeReportRequestBody
        };
        const poChangeReportRequestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedPOReportFieldsInExportedFile.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          searchType: searchRequestBody.searchType,
          fileFormat: filetype
        };

        request({
          api: 'poChangeReportExportLargeFile',
          method: 'post',
          data: poChangeReportRequestBody
        }, dispatch, getState)
          .then((response) => {
            callback(response);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            callback(null, error);
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function fetchSavedPOChangeReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedPOChangeReportResults`,
      async ({
        poChangeReportRequestData,
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        const {
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        let requestBody = { ...poChangeReportRequestData.searchCriteria, offset: '0' };
        requestBody = setValidFields(poChangeReportFieldProperties, requestBody);

        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        request({
          api: 'poChangeReportSearch',
          method: 'post',
          data: {
            savedSearchID: poChangeReportRequestData.id,
            ...requestBody
          },
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            const fields = mapRequestToInputFields(
              [...poChangeReportRequestData.searchCriteria.search]
            );
            const poChangeSearchFields = [...new Set(
              poChangeReportRequestData.searchCriteria.search.map((field) => field.fieldName)
            )];
            dispatch(actions.updatePOChangeReportInputValues(
              fields
            ));
            dispatch(actions.updatePOChangeReportRequestBody(requestBody));
            dispatch(actions.updatePOChangeReportFields(poChangeReportFieldList
              .map((field) => field.key)
              .filter((field) => poChangeSearchFields.includes(field))));
            dispatch(actions.updatePOChangeReportResults(response));
            dispatch(actions.updatePOChangeReportSearchId());
            dispatch(actions.updatePOChangeReportPage(0));
            dispatch(actions.updatePOChangeReportRowsPerPage(rowsPerPageOption));
            dispatch(actions.updatePOChangeReportBookmarkId(poChangeReportRequestData.id));
            dispatch(actions.updatePOChangeReportBookmarkName(poChangeReportRequestData.name));
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            console.log(error);
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function fetchPoChangereportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchPoChangereportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        const state = getState();
        if (state.poChangeReport.poChangeReportRequestBody
          && state.poChangeReport.poChangeReportResultData) {
          let poChangeReportUpdatedRequest = state.poChangeReport.poChangeReportRequestBody;
          // delete poChangeReportUpdatedRequest.offset;
          poChangeReportUpdatedRequest = {
            ...poChangeReportUpdatedRequest,
            offset: '0'
          };

          request({
            api: 'poChangeReportSearch',
            method: 'post',
            data: poChangeReportUpdatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.PoChangeReportOffset(response));
              dispatch(toggleSpinner(false));
              dispatch(actions.updatePOChangeReportSearchId());
            })
            .catch((error) => {
              console.log('NEXT Error ', error);
              dispatch(toggleSpinner(false));
            });
        }
      }
    );
  }

  function PoChangeReportDataOffset() {
    return createAsyncThunk(
      `${name}/PoChangeReportDataOffset`,
      async ({
        pofetchPoChangereportsResultsOffset
      }, { dispatch }) => {
        dispatch(pofetchPoChangereportsResultsOffset());
      }
    );
  }

  function fetchPoChangeReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchPoChangeReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.poChangeReport.poChangeReportRequestBody
          && state.poChangeReport.poChangeReportResultData
          && state.poChangeReport.poChangeReportResultData.pages.next) {
          const currentResultSet = state.poChangeReport.poChangeReportResultData;
          let updatedRequest = state.poChangeReport.poChangeReportRequestBody;
          const searchResultNextOffset = state.poChangeReport.poChangeReportResultData.pages.next;
          // delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };

          dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
          request({
            api: 'poChangeReportSearch',
            method: 'post',
            data: updatedRequest,
            cancellable: true
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updatePOChangeReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updatePOChangeReportNextResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              console.log('NEXT Error ', error);
            });
        }
      }
    );
  }

  function ChangeReportsDataNextSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataNextSet`,
      async ({
        pofetchPoChangeReportNextResultSet
      }, { dispatch }) => {
        dispatch(pofetchPoChangeReportNextResultSet());
      }
    );
  }

  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.poChangeReport && state.poChangeReport.poChangeReportResultData
          && state.poChangeReport.poChangeReportResultData.pages.prev) {
          const currentResultSet = state.poChangeReport.poChangeReportResultData;
          let updatedRequest = state.poChangeReport.poChangeReportRequestBody;
          const searchResultPrevOffset = state.poChangeReport.poChangeReportResultData.pages.prev;
          // delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };

          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'poChangeReportSearch',
            method: 'post',
            data: updatedRequest,
            cancellable: true
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updatePOChangeReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updatePOChangeReportPrevResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              console.log('PREV Error ', error);
            });
        }
      }
    );
  }

  function ChangeReportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataPrevSet`,
      async ({
        pofetchReportPrevResultSet
      }, { dispatch }) => {
        dispatch(pofetchReportPrevResultSet());
      }
    );
  }

  return {
    fetchPoChangeReports: fetchPoChangeReports(),
    exportPoChangeReport: exportPoChangeReport(),
    fetchSavedPOChangeReportResults: fetchSavedPOChangeReportResults(),
    fetchPoChangereportsResultsOffset: fetchPoChangereportsResultsOffset(),
    PoChangeReportDataOffset: PoChangeReportDataOffset(),
    fetchPoChangeReportNextResultSet: fetchPoChangeReportNextResultSet(),
    ChangeReportsDataNextSet: ChangeReportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    ChangeReportsDataPrevSet: ChangeReportsDataPrevSet()
  };
}

export const extraActions = createExtraActions();

export const poChangeReportActions = { ...actions, ...extraActions };
export const poChangeReportReducer = slice.reducer;
