/**
 * This is the constants file containing the field names.
 * Thereby no fields should be elsewhere aprart from this file.
 * @flow
 */
import { pricingNonProd } from '../../helpers/envServer';

const lblPOLineItemNumber = 'poData.poLine.itemNumber';
export default {
  ID: 'id',
  SIZE_ID: 'sizes.sizeId',
  DELETION_INDICATOR: 'sizes.sizePo.filterInDPOM',
  PO_SIZE_ID: 'poData.sizes.sizeId',
  IS_LINE: 'isLine',
  PURCHASE_ORDER_NUMBER: 'poHeader.poNumber',
  PO_LINE_ITEM_NUMBER: 'poLine.itemNumber',
  SCHEDULE_LINE_ITEM_NUMBER: 'sizes.scheduleLineItemNumber',
  SRC_SYS_NM: 'srcSysNm',
  SIZE_DESC: 'sizes.sizePo.sizeDescription',
  TR_CO_PURCHASE_ORDER_NUMBER: 'poHeader.trcoPoNumber',
  VENDOR_CODE: 'poHeader.vendorCode',
  VENDOR_NAME: 'vendor.vendorName',
  DOCUMENT_DATE: 'poHeader.documentDate',
  FX_ADJ_REQUIRED_INDICATOR: 'poHeader.fxAdjustmentRequiredIndicator',
  // IS_TRCO_RELEVANT: 'poHeader.istrcoRelevant',
  TRCO_RELEVANT_INDICATOR: 'poHeader.istrcoRelevant',
  CREATE_DATE: 'poHeader.poCreatedOn',
  MRGAC: 'planning.mrgacDate',
  GAC: 'sizes.sizePo.goodsAtConsolidatorDate',
  OGAC: 'poLine.originalGoodsAtConsolidatorDate',
  PO_LINE_ITEM_STATUS: 'poLine.dpomItemStatus',
  PRODUCT_CODE: 'poLine.productCode',
  MODE_OF_TRANSPORTATION: 'sizes.sizePo.transportationModeCode',
  MODE_OF_TRANSPORTATION_MODE: 'sizes.sizePo.transportationMode',
  FSP: 'vendor.fsp',
  PURCHASING_DOCUMENT_TYPE_CODE: 'poHeader.poDocTypeCode',
  PURCHASING_DOCUMENT_TYPE_DESC: 'poHeader.poDocTypeDescription',
  ORDER_REASON_CODE: 'poLine.reasonForOrderingCode',
  ORDER_REASON_DESCRIPTION: 'poLine.reasonforOrderingDescription',
  SEASON: 'poLine.seasonCode',
  YEAR: 'poLine.seasonYear',
  CURRENT_EVENT: 'manufacturing.currentEvent',
  PLANT_CODE: 'poLine.plantCode',
  PLANT_NAME: 'poLine.plantName',
  GAC_LOCK: 'poReport.gacLock',
  CHANGE_DATE: 'poLine.changedOnDate',
  INTERNATIONAL_COMMERCIAL_TERMS1: 'poHeader.incoTerms',
  INTERNATIONAL_COMMERCIAL_TERMS1_DESC: 'poHeader.internationalCommercialTerms2Code',
  CREATED_BY: 'poHeader.createdBy',
  PURCHASE_ORG_CODE: 'poHeader.purchaseOrgCode',
  PURCHASE_ORG_NAME: 'poHeader.purchaseOrgName',
  PURCHASE_GROUP_CODE: 'poHeader.purchaseGroupCode',
  PURCHASE_GROUP_NAME: 'poHeader.purchaseGroupName',
  TTMI_CODE: 'poHeader.titleTransferModelCode',
  TTMI_CODE_DESC: 'poHeader.titleTransferModelDescription',
  MCO: 'poHeader.manufacturingCountryOfOrigin',
  MCO_DESC: 'poHeader.manufacturingCountryOfOriginName',
  COMPANY_CODE: 'poHeader.poCompanyCode',
  COMPANY_DESC: 'poHeader.poCompanyCodeDescription',
  TR_CO_CODE: 'poHeader.trcoCompanyCode',
  TR_CO_DESC: 'poHeader.trcoCompanyCodeDescription',
  REJECTION_CODE: 'manufacturing.poRejectionCode',
  REJECTION_CODE_DESC: 'manufacturing.poRejectionCodeDescription',
  PRODUCT_NAME: 'poLine.productName',
  TOTAL_ITEM_QUANTITY: 'poLine.itemQuantity',
  UNIT_OF_MEASURE: 'poLine.unitOfMeasureCode',
  DESTINATION_COUNTRY_CODE: 'poLine.destinationCountryCode',
  DESTINATION_COUNTRY_NAME: 'poLine.destinationCountryName',
  GAC_REASON_CODE: 'sizes.sizePo.goodsAtConsolidatorReasonCode',
  GAC_REASON_DESC: 'sizes.sizePo.goodsAtConsolidatorReasonDescription',
  INVENTORY_SEGMENT_CODE: 'sizes.sizePo.inventorySegmentCode',
  TR_CO_PLANT_CODE: 'poLine.trcoPlantCode',
  TR_CO_PLANT_NAME: 'poLine.trcoPlantName',
  SHIPPING_TYPE: 'poLine.shippingType',
  DIRECT_SHIP_SALES_ORDER_NUMBER: 'poLine.directshipSalesOrderNumber',
  DIRECT_SHIP_SALES_ORDER_ITEM: 'poLine.directShipSalesOrderItemNumber',
  SIZE_QUANTITY: 'sizes.sizePo.sizeQuantity',
  PURCHASE_ORDER_FOB_ITEM_AMOUNT: 'poLine.itemPoFob.crpoTotalValueAmount',
  PURCHASE_ORDER_FOB_ITEM_CURR_CODE: 'poLine.itemPoFob.crpoTotalValueAmountCurrencyCode',
  TR_CO_FOB_ITEM_AMOUNT: 'poLine.itemPoFob.trcoTotalValueAmount',
  TR_CO_FOB_ITEM_CURRENTCY_CODE: 'poLine.itemPoFob.trpoTotalValueAmountCurrencyCode',
  PURCHASE_ORDER_FOB_SIZE_AMOUNT: 'sizes.sizePo.sizePricing.fob.crpoRateUnitValue',
  PURCHASE_ORDER_FOB_SIZE_CURR_CODE: '',
  PURCHASE_ORDER_FOB_SIZE_CURR_CURR: 'sizes.sizePo.sizePricing.fob.crpoCurrencyCode',
  TR_CO_FOB_SIZE_AMOUNT: 'sizes.sizePo.sizePoFob.trcototalValueAmount',
  TR_CO_FOB_SIZE_CURR_CODE: 'sizes.sizePo.sizePoFob.trcototalValueAmountCurrencyCode',
  PO_FX_ADJ_TO_TOTAL_VALUE: 'poLine.itemForeignExchange.crpoRateUnitValue',
  PO_FX_ADJ_TO_TOTAL_RATE_TYPE_NAME: 'poLine.itemForeignExchange.crpoRateTypeName',
  TR_CO_PO_FX_ADJ_TO_TOTAL_UNIT_VALUE: 'poLine.itemForeignExchange.trcoRateUnitValue',
  TR_CO_PO_FX_ADJ_TO_TOTAL_RATE_TYPE_NAME: 'poLine.itemForeignExchange.trcoRateTypeName',
  PO_FX_ADJ_TO_SIZE_RATE_TYPE_NAME: 'sizes.sizePo.sizeForeignExchange.crpoRateTypeName',
  TR_CO_PO_FX_ADJ_TO_SIZE_UNIT_VALUE: 'sizes.sizePo.sizeForeignExchange.trcoRateUnitValue',
  TR_CO_PO_FX_ADJ_TO_SIZE_RATE_TYPE_NAME: 'sizes.sizePo.sizeForeignExchange.trcoRateTypeName',
  PO_FX_ADJ_TO_TOTAL_AMOUNT: 'poLine.itemForeignExchange.crpoTotalValueAmount',
  PO_FX_ADJ_TO_TOTAL_CURRENTY_CODE: 'poLine.itemForeignExchange.crpoTotalValueAmountCurrencyCode',
  TR_CO_PO_FX_ADJ_TO_TOTAL_AMOUNT: 'poLine.itemForeignExchange.trcoTotalValueAmount',
  TR_CO_PO_FX_ADJ_TO_TOTAL_CURR_CODE: 'poLine.itemForeignExchange.trcoTotalValueAmountCurrencyCode',
  PO_FX_ADJ_TO_SIZE_AMOUNT: 'sizes.sizePo.sizeForeignExchange.crpototalValueAmount',
  PO_FX_ADJ_TO_SIZE_CURR_CODE: 'sizes.sizePo.sizeForeignExchange.crpototalValueAmountCurrencyCode',
  TR_CO_PO_FX_ADJ_TO_SIZE_AMOUNT: 'sizes.sizePo.sizeForeignExchange.trcototalValueAmount',
  TR_CO_PO_FX_ADJ_TO_SIZE_CURR_CODE: 'sizes.sizePo.sizeForeignExchange.trcototalValueAmountCurrencyCode',
  NET_PRICE_INCL_SURCH_AMOUNT: 'poLine.itemNetIncludingSurcharges.crpoTotalValueAmount',
  NET_PRICE_INCL_SURCH_CURR_CODE: 'poLine.itemNetIncludingSurcharges.crpoTotalValueAmountCurrencyCode',
  TR_CO_NET_PRICE_INCL_SURCH_AMOUNT: 'poLine.itemNetIncludingSurcharges.trcoTotalValueAmount',
  TR_CO_NET_PRICE_INCL_SURCH_CURR_CODE: 'poLine.itemNetIncludingSurcharges.trcoTotalValueAmountCurrencyCode',
  NET_PRICE_INCL_SURCH_SIZE_AMOUNT: 'sizes.sizePo.sizeNetIncludingSurcharges.crpototalValueAmount',
  NET_PRICE_INCL_SURCH_SIZE_CURR_CODE: 'sizes.sizePo.sizeNetIncludingSurcharges.crpototalValueAmountCurrencyCode',
  TR_CO_NET_PRICE_INCL_SURCH_SIZE_AMOUNT: 'sizes.sizePo.sizeNetIncludingSurcharges.trcototalValueAmount',
  TRCO_PRICE_SURCH_SIZE_CURR_CODE: 'sizes.sizePo.sizeNetIncludingSurcharges.trcototalValueAmountCurrencyCode',
  GROSS_PRICE_ITEM_AMOUNT: 'poLine.itemGrossPrice.crpoTotalValueAmount',
  GROSS_PRICE_ITEM_CURR_CODE: 'poLine.itemGrossPrice.crpoTotalValueAmountCurrencyCode',
  TR_CO_GROSS_PRICE_ITEM_AMOUNT: 'poLine.itemGrossPrice.trcoTotalValueAmount',
  TR_CO_GROSS_PRICE_ITEM_CURR_CODE: 'poLine.itemGrossPrice.trcoTotalValueAmountCurrencyCode',
  GROSS_PRICE_SIZE_AMOUNT: 'sizes.sizePo.sizeGrossPrice.crpototalValueAmount',
  GROSS_PRICE_SIZE_CURR_CODE: 'sizes.sizePo.sizeGrossPrice.crpototalValueAmountCurrencyCode',
  TR_CO_GROSS_PRICE_SIZE: 'sizes.sizePo.sizeGrossPrice.trcototalValueAmount',
  TR_CO_GROSS_PRICE_CURR_CODE: 'sizes.sizePo.sizeGrossPrice.trcototalValueAmountCurrencyCode',
  SUBTOTAL_ITEM_AMOUNT: 'poLine.itemSubTotal.crpoTotalValueAmount',
  SUBTOTAL_ITEM_CURR_CODE: 'poLine.itemSubTotal.crpoTotalValueAmountCurrencyCode',
  TR_CO_SUBTOTAL_ITEM_AMOUNT: 'poLine.itemSubTotal.trcoTotalValueAmount',
  TR_CO_SUBTOTAL_ITEM_CURR_CODE: 'poLine.itemSubTotal.trcoTotalValueAmountCurrencyCode',
  SUBTOTAL_SIZE_AMOUNT: 'sizes.sizePo.sizeSubTotal.crpototalValueAmount',
  SUBTOTAL_SIZE_CURR_CODE: 'sizes.sizePo.sizeSubTotal.crpototalValueAmountCurrencyCode',
  TR_CO_SUBTOTAL_SIZE_AMOUNT: 'sizes.sizePo.sizeSubTotal.trcototalValueAmount',
  TR_CO_SUBTOTAL_SIZE_CURR_CODE: 'sizes.sizePo.sizeSubTotal.trcototalValueAmountCurrencyCode',
  NET_INCL_DISCOUNTS_ITEM_AMOUNT: 'poLine.itemNetIncludingDiscounts.crpoTotalValueAmount',
  NET_INCL_DISCOUNTS_ITEM_CURR_CODE: 'poLine.itemNetIncludingDiscounts.crpoTotalValueAmountCurrencyCode',
  TR_CO_NET_INCL_DISCOUNTS_ITEM_AMOUNT: 'poLine.itemNetIncludingDiscounts.trcoTotalValueAmount',
  TR_CO_NET_INCL_DISCOUNTS_ITEM_CURR_CODE: 'poLine.itemNetIncludingDiscounts.trcoTotalValueAmountCurrencyCode',
  NET_INCL_DISCOUNTS_SIZE_AMOUNT: 'sizes.sizePo.sizeNetIncludingDiscounts.crpototalValueAmount',
  NET_INCL_DISCOUNTS_SIZE_CURR_CODE: 'sizes.sizePo.sizeNetIncludingDiscounts.crpototalValueAmountCurrencyCode',
  TR_CO_NET_INCL_DISCOUNTS_SIZE_AMOUNT: 'sizes.sizePo.sizeNetIncludingDiscounts.trcototalValueAmount',
  TR_CO_NET_INCL_DISCOUNTS_SIZE_CURR_CODE: 'sizes.sizePo.sizeNetIncludingDiscounts.trcototalValueAmountCurrencyCode',
  FACTORY_AIR_FREIGHT: 'poLine.factoryAirFreight',
  PO_LINE_ITEM_CANCEL_DATE: 'poLine.cancelDate',
  STATISTICAL_DELIVERY_DATE: 'sizes.sizePo.statisticalDeliveryDate',
  CUSTOMER_COUNTRY_CODE: 'poLine.customerIsoCountryCode',
  CUSTOMER_COUNTRY: 'poLine.customerCountryName',
  ITEM_TEXT: 'poLine.itemTextDetail',
  PRODUCT_FOB_SIZE: 'sizes.sizePo.product.sizeProductFob',
  PRODUCT_FOB_SIZE_CODE: 'sizes.sizePo.product.sizeProductFobCurrencyCode',
  DIVISION: 'poLine.divisionCode',
  GEOGRAPHY_CODE: 'poLine.geographyCode',
  LAUNCH_INDICATOR: 'product.launchCode',
  LAUNCH_DATE: 'product.launchDate',
  CATEGORY_CODE: 'product.categoryCode',
  CATEGORY_CODE_DESC: 'product.categoryDescription',
  SUB_CATEGORY_CODE: 'product.subCategoryCode',
  SUB_CATEGORY_DESC: 'product.subCategoryDescription',
  GLOBAL_CATEGORY_CORE_FOCUS_CODE: 'product.globalCategoryCoreFocusCode',
  GLOBAL_CATEGORY_CORE_FOCUS_DESC: 'product.globalCategoryCoreFocusDescription',
  GLOBAL_CATEGORY_SUMMARY_CODE: 'product.globalCategorySummaryCode',
  GLOBAL_CATEGORY_SUMMARY_DESC: 'product.globalCategorySummaryDescription',
  GENDER_AGE_CODE: 'product.genderAgeCode',
  GENDER_AGE__DESC: 'product.genderAgeDescription',
  SPORT_ACTIVITY_CODE: 'product.sportActivityCode',
  SPORT_ACTIVITY_CODE_DESC: 'product.sportActivityDescription',
  COLOR_DESC: 'product.colorDescription',
  MIDSOLE_CODE: 'product.midsoleCode',
  OUTSOLE_CODE: 'product.outsoleCode',
  PROMO_ONLY_INDICATOR: 'product.promotionalIndicator',
  SILHOUETTE_CODE: 'product.silhouetteTypeCode',
  SILHOUETTE_DESC: 'product.silhouetteTypeDescription',
  SIZE_DIMENSION: 'product.dimensionCode',
  SIZE_SORT_SEQUENCE_NUMBER: 'sizes.sizeProduct.sizeSortSequenceNumber',
  ATHLETE_ID: 'product.athleteIdentifier',
  ATHLETE_NAME: 'product.athleteFullName',
  TEAM_ID: 'product.teamIdentifier',
  TEAM_NAME: 'product.teamName',
  MARKETING_ID: 'product.primaryMarketingTypeIdentifier',
  MARKETING_DESC: 'product.primaryMarketingTypeDescription',
  TEAM_SILHOUETTE_ID: 'product.teamSilhouetteId',
  TEAM_SILHOUETTE_DESC: 'product.teamSilhouetteDescription',
  BLANK_COLOR: 'product.blankColor',
  BLANK_PRODUCT_CODE: 'product.blankProductCode',
  BLANK_ID: 'product.blankUsageIndicator',
  BLANK_STYLE_DISP_NUMBER: 'product.blankStyle',
  STYLE_NUMBER: 'product.styleNumber',
  SILO_CODE: 'product.siloCode',
  SILO_DESC: 'product.siloDescription',
  PRODUCT_REFILL_CLASS_CODE: 'product.productRefillClassCode',
  PRODUCT_REFILL_CLASS_DESC: 'product.productRefillClassDescription',
  CURRENT_EVENT_DATE: 'manufacturing.currentEventDate',
  NEXT_EVENT: 'manufacturing.nextEvent',
  NEXT_EVENT_DATE: 'manufacturing.nextEventDate',
  PENDING_CHANGES: 'manufacturing.pendingChanges',
  INITIAL_GAC: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorDate',
  INITIAL_GAC_REASON_CODE: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonCode',
  INITIAL_GAC_REASON_DESC: 'sizes.sizeManufacturing.initialGoodsAtConsolidatorReasonDescription',
  PREVIOUS_GAC: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorDate',
  PREVIOUS_GAC_REASON_CODE: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorReasonCode',
  PREVIOUS_GAC_REASON_DESC: 'sizes.sizeManufacturing.previousGoodsAtConsolidatorReasonDescription',
  INITIAL_CAPACITY_CONSUMPTION_WEEK: 'planning.initialCapacityConsumptionWeek',
  INITIAL_PRODUCTION_WEEK: 'planning.initialProductionWeek',
  GLOBAL_PLANNING_PRODUCT_GROUP: 'planning.globalPlanningProductGroupCode',
  GLOBAL_PLANNING_PRODUCT_DESC: 'planning.globalPlanningProductGroupDescription',
  GLOBAL_PLANNING_PRODUCT_CLASS_CODE: 'planning.globalPlanningProductClassificationCode',
  GLOBAL_PLANNING_PRODUCT_CLASS_DESC: 'planning.globalPlanningProductClassificationDescription',
  PLANNING_PRIORITY_NUMBER_EDP_CODE: 'planning.planningPriorityCode',
  PLANNING_PRIORITY_NUMBER_EDP_DESC: 'planning.planningPriorityDescription',
  GAC_VS_MRGAC: 'planning.gacVsMrgac',
  OGAC_VS_MRGAC: 'planning.ogacVsMrgac',
  MRGAC_CAPACITY_CONSUMPTION_WEEK_MRGAC_GI: 'planning.mrgacCapacityConsumptionWeek',
  SHIP_TO_CUSTOMER_NAME: 'poLine.customerShipToName',
  SHIP_TO_CUSTOMER_NUMBER: 'poLine.customerShipToNumber',
  CUSTOMER_STORE: 'salesOrder.customerStore',
  CUSTOMER_DEPARTMENT: 'salesOrder.customerDepartment',
  CUSTOMER_CANCELLATION_DATE: 'salesOrder.customerCancellationDate',
  SALES_ORDER_NUMBER: 'poLine.salesOrderNumber',
  SALES_ORDER_ITEM_NUMBER: 'poLine.salesOrderItemNumber',
  CUSTOMER_REQUESTED_DATE: 'salesOrder.customerRequestedDate',
  BILLING_DATE_FROM_SALES_ORDER: 'salesOrder.billingDate',
  CUSTOMER_PO: 'salesOrder.customerPo',
  FACTORY_PROOF_OF_DELIVERY_DATE: 'sizes.sizeLogisticsASN.factoryProofOfDeliveryDate',
  ORIGIN_RECEIPT_DATE: 'sizes.sizeLogisticsOR.originReceiptActualDate',
  ORIGIN_RECEIPT_SIZE: 'sizes.sizeLogisticsOR.originReceiptSize',
  ORIGIN_RECEIPT_QUANTITY: 'sizes.sizeLogisticsOR.originReceiptQuantity',
  ADVANCED_SHIPMENT_NOTICE_ASN_DATE: 'sizes.sizeLogisticsASN.asnActualDate',
  ASN_SIZE: 'logistics.asnSize',
  ASN_QUANTITY: 'sizes.sizeLogisticsASN.asnQuantity',
  FOB_MISMATCH_INDICATOR: 'product.fobMismatchIndicator',
  SIZE_MISMATCH_INDICATOR: 'product.sizeMismatchIndicator',
  OVER_DUE_INDICATOR: 'manufacturing.overdueIndicator',
  DISTRIBUTION_CHANNEL_CODE: 'salesOrder.distributionChannelCode',
  DISTRIBUTION_CHANNEL_DESC: 'salesOrder.distributionChannelDescription',
  LEAGUE_ID_CODE: 'product.leagueIdCode',
  LEAGUE_ID_DESC: 'product.leagueIdDescription',
  FX_ADJ_DATE: 'poLine.fxAdjustmentDate',
  SALES_ORGANIZATION_CODE: 'salesOrder.salesOrgCode',
  UPC: 'sizes.sizeProduct.upc',
  ISSUE_PO_EXPECTED_DATE: 'manufacturing.issuePOExpectedDate',
  ISSUE_PO_ACTUAL_DATE: 'manufacturing.issuePOActualDate',
  ACCEPT_PO_EXPECTED_DATE: 'manufacturing.acceptPOExpectedDate',
  PO_ACCEPTANCE_DATE: 'manufacturing.acceptPOActualDate',
  MATERIAL_ORDER_EXPECTED_DATE: 'manufacturing.materialOrderExpectedDate',
  MATERIAL_ORDER_ACTUAL_DATE: 'manufacturing.materialOrderActualDate',
  MATERIAL_TRIM_RECEIPT_EXPECTED_DATE: 'manufacturing.materialTrimReceiptExpectedDate',
  MATERIAL_TRIM_RECEIPT_ACTUAL_DATE: 'manufacturing.materialTrimReceiptActualDate',
  QRS_EXPECTED_DATE: 'manufacturing.qrsExpectedDate',
  QRS_ACTUAL_DATE: 'manufacturing.qrsActualDate',
  PRODUCTION_START_EXPECTED_DATE: 'manufacturing.productionStartExpectedDate',
  PRODUCTION_START_ACTUAL_DATE: 'manufacturing.productionStartActualDate',
  PRODUCTION_END_EXPECTED_DATE: 'manufacturing.productionEndExpectedDate',
  PRODUCTION_END_ACTUAL_DATE: 'manufacturing.productionEndActualDate',
  EX_FACTORY_EXPECTED_DATE: 'manufacturing.exFactoryExpectedDate',
  EX_FACTORY_ACTUAL_DATE: 'manufacturing.exFactoryActualDate',
  FACTORY_DELIVERY_EXPECTED_DATE: 'manufacturing.factoryDeliveryExpectedDate',
  FACTORY_DELIVERY_ACTUAL_DATE: 'manufacturing.factoryDeliveryActualDate',
  EX_FACTORY_QUANTITY: 'sizes.sizeManufacturing.exFactoryQuantity',
  PROPOSED_GAC_DATE: 'poReport.proposedGacDate',
  PROPOSED_GAC_REASON_CODE: 'poReport.proposedGacReasonCode',
  // PENDING_GAC_LOCK: 'PO Report only??',
  PMO_DEC_CODE: 'vendor.productionNikeLiaisonOffice',
  PMO_DEC_CODE_DESC: 'vendor.productionNikeLiaisonOfficeName',
  PAYMENT_TERM: 'vendor.paymentTerm',
  PAYMENT_TERM_DESC: 'vendor.paymentTermDescription',
  VAS_ITEM: 'poLine.itemVasDetail',
  VAS_SIZE: 'sizes.sizeVas',
  PO_GAC: 'poLine.goodsAtConsolidatorDate',
  // new pricing json path
  GROSS_PRICE: 'sizes.sizePo.sizePricing.grossPrice.crpoRateUnitValue',
  GROSS_PRICE_RATE: 'sizes.sizePo.sizePricing.grossPrice.crpoRateTypeName',
  GROSS_PRICE_CURRENCY: 'sizes.sizePo.sizePricing.grossPrice.crpoCurrencyCode',
  NET_INCL_DISCOUNTS_AMOUNT: 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoRateUnitValue',
  NET_INCL_DISCOUNTS_AMOUNT_RATE: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoRateTypeName',
  NET_INCL_DISCOUNTS_AMOUNT_CURRENCY: 'sizes.sizePo.sizePricing.netIncludingDiscounts.crpoCurrencyCode',
  NET_INCL_SURCH_AMOUNT: pricingNonProd ? 'sizes.sizePo.sizePricing.netIncludingSurcharges.rateUnitValue' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoRateUnitValue',
  NET_INCL_SURCH_AMOUNT_RATE: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoRateTypeName',
  NET_INCL_SURCH_AMOUNT_CURRENCY: pricingNonProd ? 'sizes.sizePo.sizePricing.netIncludingSurcharges.currencyCode' : 'sizes.sizePo.sizePricing.netIncludingSurcharges.crpoCurrencyCode',
  FOREIGN_EXCHANGE: pricingNonProd ? 'sizes.sizePo.sizePricing.foreignExchange.rateUnitValue' : 'sizes.sizePo.sizePricing.foreignExchange.crpoRateUnitValue',
  FOREIGN_EXCHANGE_RATE: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.foreignExchange.crpoRateTypeName',
  FOREIGN_EXCHANGE_CURRENCY: pricingNonProd ? 'sizes.sizePo.sizePricing.foreignExchange.currencyCode' : 'sizes.sizePo.sizePricing.foreignExchange.crpoCurrencyCode',
  PRODUCT_FOB_AMOUNT: 'sizes.sizeProduct.sizeProductFob',
  PRODUCT_FOB_AMOUNT_CURRENCY: 'sizes.sizeProduct.sizeProductFobCurrencyCode',
  TR_CO_GROSS_PRICE_RATE: 'sizes.sizePo.sizePricing.grossPrice.trcoRateTypeName',
  TR_CO_GROSS_PRICE_CURRENCY: 'sizes.sizePo.sizePricing.grossPrice.trcoCurrencyCode',
  TR_CO_INCL_DISCOUNTS: 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoRateUnitValue',
  TR_CO_INCL_DISCOUNTS_RATE: pricingNonProd ? '' : 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoRateTypeName',
  TR_CO_INCL_DISCOUNTS_CURRENCY: 'sizes.sizePo.sizePricing.netIncludingDiscounts.trcoCurrencyCode',
  TR_CO_INCL_SURC: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoRateUnitValue',
  TR_CO_INCL_SURC_RATE: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoRateTypeName',
  TR_CO_INCL_SURC_CURRENCY: 'sizes.sizePo.sizePricing.netIncludingSurcharges.trcoCurrencyCode',
  TR_CO_FOREIGN_EXCHANGE: 'sizes.sizePo.sizePricing.foreignExchange.trcoRateUnitValue',
  TR_CO_FOREIGN_EXCHANGE_RATE: 'sizes.sizePo.sizePricing.foreignExchange.trcoRateTypeName',
  TR_CO_FOREIGN_EXCHANGE_CURRENCY: 'sizes.sizePo.sizePricing.foreignExchange.trcoCurrencyCode',
  TR_CO_FOB: 'sizes.sizePo.sizePricing.fob.trcoRateUnitValue',
  TR_CO_FOB_RATE: '',
  TR_CO_FOB_CURRENCY: 'sizes.sizePo.sizePricing.fob.trcoCurrencyCode',
  DELIVERY_COMPLETE_INDICATOR: 'sizes.sizePo.deliveryCompleteIndicator',

  PO_CHANGE_STATUS: 'poChangeStatus',
  CHANGE_ID: 'changeRequestNumber',
  PO_NUMBER: 'poData.poHeader.poNumber',
  PO_LINE_ITEM_NUMBER_REQUEST: lblPOLineItemNumber,
  SCHEDULE_LINE_ITEM_NUMBER_REQUEST: 'poData.sizes.scheduleLineItemNumber',
  SIZE_DESC_REQUEST: 'poData.sizes.sizePo.sizeDescription',
  TRADING_CO_PO_NUMBER: 'poData.poHeader.trcoPoNumber',
  PRODUCT_CODE_REQUEST: 'poData.poLine.productCode',
  DOC_TYPE_CODE: 'poData.poHeader.poDocTypeCode',
  DOC_TYPE_DESC: 'poData.poHeader.poDocTypeDescription',
  CHANGE_TYPE: 'collaborationType',
  CURRENT_GAC_DATE: 'poData.sizes.sizePo.goodsAtConsolidatorDate',
  CURRENT_GAC_REASON_CODE: 'poData.sizes.sizePo.goodsAtConsolidatorReasonCode',
  CURRENT_GAC_REASON_DESC: 'poData.sizes.sizePo.goodsAtConsolidatorReasonDescription',
  PROPOSED_GAC_DATE_REQUEST: 'poData.sizes.proposedGacDate',
  PROPOSED_GAC_REASON_CODE_REQUEST: 'poData.sizes.proposedGacReasonCode',
  PROPOSED_GAC_REASON_DESC_REQUEST: 'poData.sizes.proposedGacReasonDescription',
  VENDOR_CODE_REQUEST: 'poData.poHeader.vendorCode',
  VENDOR_NAME_REQUEST: 'poData.vendor.vendorName',
  DESTINATION_COUNTRY_CODE_REQUEST: 'poData.poLine.destinationCountryCode',
  DESTINATION_COUNTRY_NAME_REQUEST: 'poData.poLine.destinationCountryName',
  PO_DATA_ITEM_NUMBER: lblPOLineItemNumber,
  INITIATOR_ROLE: 'initiatorRole',
  INITIATOR_ID: 'initiatorID',
  DIVERT_FLAG: 'poLine.conversionDivertedIndicator',
  DIVERTED_FROM: 'poLine.createdFrom',
  DIVERTED_TO: 'poLine.movedTo',
  GAC_USECASE_CODE: 'sizes.sizePo.grcUseCaseCode',
  GAC_USECASE_CODE_DESC: 'sizes.sizePo.grcUseCaseDescription',
  ADDRESS_OVERRIDE_INDICATOR: 'poLine.shipping.address.addressOverrideIndicator',
  POSEARCH_ASIAN_CC: 'poLine.asianSpecificSizeIsRelevant',
  // POSEARCH_KOREA_SIZE: 'poLine.korea',
  // POSEARCH_CHINA_SIZE: 'poLine.china',
  POSEARCH_KOREA_WAIST_SIZE: 'sizes.sizeProduct.kr.waistSizeCode',
  POSEARCH_KOREA_CHEST_SIZE: 'sizes.sizeProduct.kr.chestSizeCode',
  POSEARCH_KOREA_AGE_BY_SIZE: 'sizes.sizeProduct.kr.ageBySizeCode',
  POSEARCH_CHINA_TOP_SIZE: 'sizes.sizeProduct.cn.topSizeCode',
  POSEARCH_CHINA_BOTTOM_SIZE: 'sizes.sizeProduct.cn.bottomSizeCode'
};
