export default {
  LINE_SUMMARY_PONUMBER: 'poHeader.poNumber',
  LINE_SUMMARY_TR_CO_PONUMBER: 'poHeader.trcoPoNumber',
  LINE_SUMMARY_LINE_NUMBER: 'poLine.itemNumber',
  LINE_SUMMARY_VENDOR: 'poHeader.vendorCode',
  LINE_SUMMARY_VENDOR_NAME: 'vendor.vendorName',
  LINE_SUMMARY_DIVISION_CODE: 'poLine.divisionCode',
  LINE_SUMMARY_PRODUCT_CODE: 'poLine.productCode',
  LINE_SUMMARY_PRODUCT_NAME: 'poLine.productName',
  LINE_SUMMARY_ITEM_QTY: 'poLine.itemQuantity',
  LINE_SUMMARY_GAC_REASON_CODE: 'poLine.goodsAtConsolidatorReasonCode',
  LINE_SUMMARY_GAC_REASON_CODE_DESC: 'poLine.goodsAtConsolidatorReasonDescription',
  LINE_SUMMARY_GRC_USECASE_CODE: 'poLine.grcUseCaseCode',
  LINE_SUMMARY_GRC_USECASE_CODE_DESC: 'poLine.grcUseCaseDescription',
  LINE_SUMMARY_SEASON_CODE: 'poLine.seasonCode',
  LINE_SUMMARY_SEASON_YEAR: 'poLine.seasonYear',
  LINE_SUMMARY_LAUNCH: 'product.launchCode',
  LINE_SUMMARY_MIDSOLE_CODE: 'product.midsoleCode',
  LINE_SUMMARY_OUTSOLE_CODE: 'product.outsoleCode',
  LINE_SUMMARY_PROMO_ONLY_INDICATOR: 'product.promotionalIndicator',
  LINE_SUMMARY_ORDER_REASON_CODE: 'poLine.reasonForOrderingCode',
  LINE_SUMMARY_ORDER_REASON_DESCRIPTION: 'poLine.reasonforOrderingDescription',
  LINE_SUMMARY_STYLE_NUMBER: 'product.styleNumber',
  LINE_SUMMARY_CURRENT_EVENT: 'manufacturing.currentEvent',
  LINE_SUMMARY_NEXT_EVENT: 'manufacturing.nextEvent',
  LINE_SUMMARY_CUSTOMER_PO: 'salesOrder.customerPo',
  LINE_SUMMARY_DOC_TYPE_CODE: 'poHeader.poDocTypeCode',
  LINE_SUMMARY_DOC_TYPE_CODE_DESC: 'poHeader.poDocTypeDescription',
  LINE_SUMMARY_PURCHASE_ORG_CODE: 'poHeader.purchaseOrgCode',
  LINE_SUMMARY_PURCHASE_ORG_NAME: 'poHeader.purchaseOrgName',
  LINE_SUMMARY_PURCHASE_GROUP_CODE: 'poHeader.purchaseGroupCode',
  LINE_SUMMARY_PURCHASE_GROUP_NAME: 'poHeader.purchaseGroupName',
  LINE_SUMMARY_COLOR_DESC: 'product.colorDescription',
  LINE_SUMMARY_CREATED_DATE: 'poHeader.poCreatedOn',
  LINE_SUMMARY_DOC_DATE: 'poHeader.documentDate',
  LINE_SUMMARY_CHANGED_DATE: 'poLine.changedOnDate',
  LINE_SUMMARY_OGAC: 'poLine.originalGoodsAtConsolidatorDate',
  LINE_SUMMARY_GAC: 'poLine.goodsAtConsolidatorDate',
  LINE_SUMMARY_GEOGRAPHY_CODE: 'poLine.geographyCode',
  LINE_SUMMARY_LAUNCH_DATE: 'product.launchDate',
  LINE_SUMMARY_CURRENT_EVENT_DATE: 'manufacturing.currentEventDate',
  LINE_SUMMARY_NEXT_EVENT_DATE: 'manufacturing.nextEventDate',
  LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE: 'manufacturing.acceptPOActualDate',
  LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE: 'manufacturing.factoryDeliveryActualDate',
  LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE: 'manufacturing.exFactoryActualDate',
  LINE_SUMMARY_MRGAC: 'planning.mrgacDate',
  LINE_SUMMARY_PLANT_CODE: 'poLine.plantCode',
  LINE_SUMMARY_PLANT_NAME: 'poLine.plantName',
  LINE_SUMMARY_FOB_MISMATCH_INDICATOR: 'product.fobMismatchIndicator',
  LINE_SUMMARY_SIZE_MISMATCH_ID: 'product.sizeMismatchIndicator',
  LINE_SUMMARY_CREATED_BY: 'poHeader.createdBy',
  LINE_SUMMARY_TITLE_TRANSFER_MODEL_CODE: 'poHeader.titleTransferModelCode',
  LINE_SUMMARY_TITLE_TRANSFER_MODEL_DESC: 'poHeader.titleTransferModelDescription',
  LINE_SUMMARY_PO_COMPANY_CODE: 'poHeader.poCompanyCode',
  LINE_SUMMARY_PO_COMPANY_DESC: 'poHeader.poCompanyCodeDescription',
  LINE_SUMMARY_TR_CO_COMPANY_CODE: 'poHeader.trcoCompanyCode',
  LINE_SUMMARY_TR_CO_COMPANY_DESC: 'poHeader.trcoCompanyCodeDescription',
  LINE_SUMMARY_ITEM_STATUS: 'poLine.dpomItemStatus',
  LINE_SUMMARY_UNIT_OF_MEASURE_CODE: 'poLine.unitOfMeasureCode',
  LINE_SUMMARY_DESTINATION_COUNTRY_CODE: 'poLine.destinationCountryCode',
  LINE_SUMMARY_DESTINATION_COUNTRY_NAME: 'poLine.destinationCountryName',
  LINE_SUMMARY_TR_CO_PLANT_CODE: 'poLine.trcoPlantCode',
  LINE_SUMMARY_TR_CO_PLANT_NAME: 'poLine.trcoPlantName',
  LINE_SUMMARY_SHIPPING_TYPE: 'poLine.shippingType',
  LINE_SUMMARY_DIRECT_SALES_ORDER_NUMBER: 'poLine.directshipSalesOrderNumber',
  LINE_SUMMARY_DIRECT_SALES_ORDER_ITEM_NUMBER: 'poLine.directShipSalesOrderItemNumber',
  LINE_SUMMARY_CANCEL_DATE: 'poLine.cancelDate',
  LINE_SUMMARY_ITEM_TEXT_DETAIL: 'poLine.itemTextDetail',
  LINE_SUMMARY_CATEGORY_CODE: 'product.categoryCode',
  LINE_SUMMARY_CATEGORY_DESC: 'product.categoryDescription',
  LINE_SUMMARY_SUB_CATEGORY_CODE: 'product.subCategoryCode',
  LINE_SUMMARY_SUB_CATEGORY_DESC: 'product.subCategoryDescription',
  LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_CODE: 'product.globalCategoryCoreFocusCode',
  LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_DESC: 'product.globalCategoryCoreFocusDescription',
  LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_CODE: 'product.globalCategorySummaryCode',
  LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_DESC: 'product.globalCategorySummaryDescription',
  LINE_SUMMARY_GENDER_AGE_CODE: 'product.genderAgeCode',
  LINE_SUMMARY_GENDER_AGE_DESC: 'product.genderAgeDescription',
  LINE_SUMMARY_MARKETING_ID: 'product.primaryMarketingTypeIdentifier',
  LINE_SUMMARY_MARKETING_DESC: 'product.primaryMarketingTypeDescription',
  LINE_SUMMARY_SILHOUTTE_TYPE: 'product.silhouetteTypeCode',
  LINE_SUMMARY_SILHOUTTE_TYPE_DESC: 'product.silhouetteTypeDescription',
  LINE_SUMMARY_DIMENSION_CODE: 'product.dimensionCode',
  LINE_SUMMARY_PRODUCT_REFILL_CLASS_CODE: 'product.productRefillClassCode',
  LINE_SUMMARY_PRODUCT_REFILL_CLASS_DESC: 'product.productRefillClassDescription',
  LINE_SUMMARY_LEAGUE_ID_DESC: 'product.leagueIdDescription',
  LINE_SUMMARY_PO_REJECTION_CODE: 'manufacturing.poRejectionCode',
  LINE_SUMMARY_PO_REJECTION_CODE_DESC: 'manufacturing.poRejectionCodeDescription',
  LINE_SUMMARY_OVERDUE_INDICATOR: 'manufacturing.overdueIndicator',
  LINE_SUMMARY_CUSTOMER_SHIP_TO: 'poLine.customerShipToNumber',
  LINE_SUMMARY_CUSTOMER_SHIP_TO_NAME: 'poLine.customerShipToName',
  LINE_SUMMARY_GLOBAL_PRODUCT_GROUP: 'planning.globalPlanningProductGroupCode',
  LINE_SUMMARY_GLOBAL_PRODUCT_GROUP_DESC: 'planning.globalPlanningProductGroupDescription',
  LINE_SUMMARY_GLOBAL_PRODUCT_CLASS: 'planning.globalPlanningProductClassificationCode',
  LINE_SUMMARY_GLOBAL_PRODUCT_CLASS_DESC: 'planning.globalPlanningProductClassificationDescription',
  LINE_SUMMARY_PLANNING_PRIORITY_NUMBER: 'planning.planningPriorityCode',
  LINE_SUMMARY_PLANNING_PRIORITY_NUMBER_DESC: 'planning.planningPriorityDescription',
  LINE_SUMMARY_GAC_VS_MRGAC: 'planning.gacVsMrgac',
  LINE_SUMMARY_OGAC_VS_MRGAC: 'planning.ogacVsMrgac',
  LINE_SUMMARY_MRGAC_CAPACITY_CONSUMPTION_WEEK: 'planning.mrgacCapacityConsumptionWeek',
  LINE_SUMMARY_PMO_DEC_CODE: 'vendor.productionNikeLiaisonOffice',
  LINE_SUMMARY_PMO_DEC_CODE_DESC: 'vendor.productionNikeLiaisonOfficeName',
  REPORT_ID: 'id',
  LINE_SUMMARY_FX_ADJ_REQUIRED_INDICATOR: 'poHeader.fxAdjustmentRequiredIndicator',
  LINE_SUMMARY_CUSTOMER_REQ_DATE: 'salesOrder.customerRequestedDate',
  LINE_SUMMARY_VAS_ITEM: 'poLine.itemVasDetail',
  LINE_SUMMARY_R3_DOC_TYPE: 'poHeader.r3DocType',
  LINE_SUMMARY_MFG_ORIGIN_COUNTRY_CODE: 'poHeader.manufacturingCountryOfOrigin',
  LINE_SUMMARY_MFG_ORIGIN_COUNTRY_NAME: 'poHeader.manufacturingCountryOfOriginName',
  LINE_SUMMARY_TRANSPORT_MODE: 'poLine.transportationModeCode',
  LINE_SUMMARY_TRANSPORT_MODE_DESC: 'poLine.transportationMode',
  LINE_SUMMARY_DELIVERY_COMPLETE_INDICATOR: 'poLine.deliveryCompleteIndicator',
  LINE_SUMMARY_CUST_ISO_COUNTRY_CODE: 'poLine.customerIsoCountryCode',
  LINE_SUMMARY_SALES_ORDER_NUMBER: 'poLine.salesOrderNumber',
  LINE_SUMMARY_SALES_ORDER_ITEM_NUMBER: 'poLine.salesOrderItemNumber',
  LINE_SUMMARY_CUSTOMER_COUNTRY_NAME: 'poLine.customerCountryName',
  LINE_SUMMARY_TRCO_RELEVANT_INDICATOR: 'poHeader.istrcoRelevant',
  LINE_SUMMARY_DIVERT_FLAG: 'poLine.conversionDivertedIndicator',
  LINE_SUMMARY_DIVERTED_FROM: 'poLine.createdFrom',
  LINE_SUMMARY_DIVERTED_TO: 'poLine.movedTo',
  LINE_SUMMARY_ADDRESS_OVERRIDE_INDICATOR: 'poLine.shipping.address.addressOverrideIndicator'
};
