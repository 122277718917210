import ppmReportFieldMapping from './fieldMappings/ppmReportFieldMapping';
import collabReportFieldMapping from './fieldMappings/collabReportFieldMapping';
import ppmReportFieldProperties from './fieldProperties/ppmReportFieldProperties';
import collabReportFieldProperties from './fieldProperties/collabReportFieldProperties';
import {
  pricingNonProd, showForPROD
} from '../helpers/envServer';

const ppmReportsHeader = pricingNonProd ? [
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PONUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SCHEDULE_LINE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_DESC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VENDOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOC_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE],
  ppmReportFieldProperties[
    ppmReportFieldMapping.PPM_REPORT_USECASE_CODE
  ],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PREV_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PREV_GAC_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OGAC_VS_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_OGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MRGAC_CAPACITY_CONSUMPTION_WEEK],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_PREVGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_CLASS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_GROUP],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SEASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_QUANTITY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PLANT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_PLANT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SHIPPING_TYPE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERTED_FROM],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERTED_TO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FX_ADJ_REQUIRED_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FX_ADJUSTMENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ASN_QUANTITY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ASN_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_PROOF_OF_DELIVERY_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CREATED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_QRS_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_QRS_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_START_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_START_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_END_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_END_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CANCEL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_STATISTICAL_DELIVERY_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ADDRESS_OVERRIDE_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CUSTOMER_PO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SALES_ORDER_ITEM_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIST_CHANNEL_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SALES_ORG_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP_PERCENTAGE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LAUNCH],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ATHLETE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ATHLETE_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_COLOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_PRODUCT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_STYLE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COLOR_DESC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GENDER_AGE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_CATEGORY_FOCUS_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOABL_CATEGORY_SUMMARY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LAUNCH_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LEAGUE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MARKETING_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROMO_ONLY_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_REFILL_CLASS_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SILHOUTTE_TYPE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SILO_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIMENSION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_SORT_SEQ_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SPORT_ACTIVITY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_STYLE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_SILHOUTTE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_UPC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INVENTORY_SEGMENT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PO_COMPANY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CREATED_BY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INCO_TERMS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_TEXT_DETAIL],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MFG_ORIGIN_COUNTRY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_COMPANY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_UNIT_OF_MEASURE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_PRODUCT_FOB],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRCO_RELEVANT_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VAS_SIZE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VAS_ITEM],
  !showForPROD && ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ASIAN_CC]
].filter((el) => el !== false) : [
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PONUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SCHEDULE_LINE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_DESC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VENDOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOC_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE],
  ppmReportFieldProperties[
    ppmReportFieldMapping.PPM_REPORT_USECASE_CODE
  ],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PREV_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PREV_GAC_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INITIAL_GAC_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OGAC_VS_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_MRGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_OGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MRGAC_CAPACITY_CONSUMPTION_WEEK],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GAC_VS_PREVGAC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_CLASS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_PRODUCT_GROUP],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SEASON_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_QUANTITY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PLANT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_PLANT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SHIPPING_TYPE],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERTED_FROM],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVERTED_TO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FX_ADJ_REQUIRED_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FX_ADJUSTMENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ASN_QUANTITY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ASN_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_PROOF_OF_DELIVERY_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CREATED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ISSUE_PO_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_ORDER_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MATERIAL_TRIM_RECEIPT_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_QRS_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_QRS_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_START_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_START_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_END_EXPECTED_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROD_END_ACTUAL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CANCEL_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_STATISTICAL_DELIVERY_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ADDRESS_OVERRIDE_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CUSTOMER_PO],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SALES_ORDER_ITEM_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIST_CHANNEL_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SALES_ORG_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_TO_SHIP_PERCENTAGE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BALANCE_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_EX_FACTORY_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_QTY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LAUNCH],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ATHLETE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ATHLETE_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_COLOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_PRODUCT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BLANK_STYLE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COLOR_DESC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GENDER_AGE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOBAL_CATEGORY_FOCUS_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_GLOABL_CATEGORY_SUMMARY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LAUNCH_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_LEAGUE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MARKETING_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PROMO_ONLY_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_REFILL_CLASS_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SILHOUTTE_TYPE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SILO_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIMENSION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_SORT_SEQ_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SPORT_ACTIVITY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_STYLE_NUMBER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TEAM_SILHOUTTE_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_UPC],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INVENTORY_SEGMENT_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PO_COMPANY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CREATED_BY],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_INCO_TERMS],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_ITEM_TEXT_DETAIL],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_MFG_ORIGIN_COUNTRY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PRODUCT_NAME],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_CO_COMPANY_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_UNIT_OF_MEASURE_CODE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SIZE_PRODUCT_FOB],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TOOLING_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DOMESTIC_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOLUME_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRADING_CO_FOB_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_PERCENT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_HASH_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_COMMISSION_VALUE_PERCENT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TR_MKUP_ACCURAL_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_TR_MKUP_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_DUTY_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FACTORY_FREIGHT_CRPO_RUV],
  // ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NIKE_FRIEGHT_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOB_TR_CO_CRPO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TOOLING_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_AUTO_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_BGRADE_DISC_MAN_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_SLT_PERCENT_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_MAIN_BODY_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_MAT_TRIM_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MIN_PRODUCTIVITY_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_PROD_DELAY_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_VOL_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_QLTY_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_DISC_OTHER],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_MISC_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_SURCH_VAS_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FASHION_VAS_ABS_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_SURCH_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_FOREIGN_EX_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_NET_INCL_DISCOUNT_TRCO_RUV],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_TRCO_RELEVANT_INDICATOR],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VAS_SIZE],
  ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_VAS_ITEM]
];

export const poReportsHeader = [
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PO_NUMBER],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PO_ITEM_NUMBER],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SCD_LINE_ITEM_NUMBER],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SIZE_DESCRIPTION],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PENDING_GAC_LOCK],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_GAC_OVERDUE_INDICATOR],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SAP_RESPONSE_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SAP_RESPOSNE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_COLLABORATION_TYPE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_APPROVER_ID],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_INITIATOR_ID],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_INITIATOR_ROLE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CHANGE_REQUEST_NO],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CHANGE_STATUS_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_GAC_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_GAC_REASON_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_GAC_USECASE_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PROPOSED_GAC_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PROPOSED_GAC_REASON_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_INITIAL_GAC_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_INITIAL_GAC_REASON_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_ORIGINAL_GAC_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CREATED_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_DOCUMENT_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CHANGED_ON_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PO_COMPANY_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PO_DOC_TYPE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PURCHASE_ORG_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PURCHASE_GROUP_NUMBER],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PLANT_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_MFG_COUNTRY_ORIGIN],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PRODUCT_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_DESTINATION_COUNTRY_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_DPOM_ITEM_STATUS],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_TR_CO_PO_NUMBER],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_MRGAC_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CURRENT_EVENT],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_CURRENT_EVENT_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_NEXT_EVENT],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_NEXT_EVENT_DATE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_VENDOR_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_PROD_NIKE_LIAISON_OFFICE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SIZE_PO_QUANTITY],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_UNIT_OF_MEASURE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_TR_CO_PLANT_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SIZE_TRANSPORTATION_CODE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SHIPPING_PARTNER_ID],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SHIPPING_TYPE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_ID],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_ISLINE],
  collabReportFieldProperties[collabReportFieldMapping.REPORT_SIZE_ID]
].filter((el) => el !== false);

export default ppmReportsHeader;
