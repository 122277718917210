import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import appString from '../env.json';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: appString.APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: false,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: false,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: false,
    enableResponseHeaderTracking: false
  }
});
appInsights.loadAppInsights();

const getCurrentEnv = () => {
  let currentEnv = 'DEV';
  if (['NONPROD1', 'NONPROD5'].includes(appString.APISource)) {
    currentEnv = 'SECQA3';
  } else if (['NONPROD3', 'NONPROD7'].includes(appString.APISource)) {
    currentEnv = 'RELSECQA';
  } else if (['NONPROD4'].includes(appString.APISource)) {
    currentEnv = 'SECQA2';
  } else if (['NONPROD8'].includes(appString.APISource)) {
    currentEnv = 'PSQASEA';
  } else if (['NONPROD2', 'NONPROD6'].includes(appString.APISource)) {
    currentEnv = 'PSQA';
  } else if (['PROD'].includes(appString.APISource)) {
    currentEnv = 'PROD';
  }

  return currentEnv;
};

const trackEvent = (pageId, userEmail) => {
  const userId = userEmail;
  const timeStampId = new Date(Date.now()).toISOString();
  const env = getCurrentEnv();
  appInsights.trackEvent({
    name: 'clickDPOMLink',
    properties: {
      pageId, userId, env, timeStampId
    }
  });
};

export { reactPlugin, appInsights, trackEvent };
