/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  mapInputFieldsToRequest,
  getFilterValuesFromResponse,
  getFiltersInResponseRequestBody,
  mapFiltersToRequest,
  mapRequestToInputFields,
  setFieldItemVas,
  setFilterAsiaSize,
  setValidFields
} from '../../helpers/dataHelpers';
import serviceAPI from '../../helpers/serviceAPI';
import { newContractPOSearchDataMock } from '../mock/POSearchDataMock';
import {
  searchRequestTemplate,
  poSearchCriteriaFields,
  initialSearchFieldsState,
  initialPOSearchFiltersState
} from '../../constants/staticData';
import { toggleSpinner, togglePaginationSpinner, updateDialogStates } from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import
dataTableHeader,
{ dataTableHeaderInactv, dataTableCustomView } from '../../constants/dataTableHeader';
import poSearchFieldMapping from '../../constants/fieldMappings/poSearchFieldMapping';
import { settingsActions } from '../profileSettings/settingSlice';
import {
  setFieldSrcSysNm,
  getFieldsForRequest,
  getSelectedFilterValues,
  getModifiedSelectedFilters,
  getSelectedFilterKeys,
  getFilterInResponseValueInPOSearch,
  // setSchedulerData,
  getFiltersInResponse,
  getFilterData,
  sortDataAscending,
  setFieldAsiaSize,
  removeFieldAsiaSize
} from './poSearchHelper';
import appConfig from '../../appConfig';
import poSearchFieldProperties from '../../constants/fieldProperties/poSearchFieldProperties';

const name = 'PoSearchResult';

function createInitialState() {
  return {
    // state for posearchResult
    // poSearchResult: [],
    PoSearchResult: {},
    PoSearchResultNext: {},
    PoSearchResultPrev: {},
    poSearchFilters: [],
    anchor: 0,
    nextAnchor: 0,
    prevAnchor: 0,
    poSearchResultTotalCount: 0,
    searchedValue: '',
    error: '',
    spinner: false,
    showSearch: false,
    searchedPoCategory: '',
    selectedRecordsCount: 0,
    searchId: '',
    searchPanelId: '',
    searchRequestData: {
      ...initialSearchFieldsState
    },
    page: 0,
    rowsPerPage: 100,
    key: '',
    // state for posearchRequst
    savedSearchName: '',
    savedSearchId: '',
    isSavedSearchModified: false,
    body: null,
    selectedFields: [],
    schedulerData: {},
    //  state for searchFilterReducer
    filterId: 'none',
    filterDropdownValues: {},
    selectedFilters: {
      ...initialPOSearchFiltersState
    }
  };
}
export const initialState = createInitialState();

function createReducers() {
//  posearchResultReducer functions
  function updatePOSearchPage(state: Object, action: Object) {
    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }
  function updatePOSearchRowsPerPage(state: Object, action: Object) {
    return {
      ...state,
      rowsPerPage: action.payload?.page ? action.payload?.page : action.payload
    };
  }
  function PoSearchResultsNext(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      PoSearchResult: payload.data,
      PoSearchResultNext: payload.dataNext,
      PoSearchResultTotalPages: payload.data?.pages?.totalPages,
      PoSearchResultTotalCount: payload.data?.pages?.totalResources
    };
  }
  function appendNextResultSet(state: Object) {
    const payload = {
      dataPrev: state.PoSearchResult,
      data: state.PoSearchResultNext
    };

    return {
      ...state,
      PoSearchResult: payload.data,
      PoSearchResultPrev: payload.dataPrev,
      PoSearchResultNext: null
    };
  }
  function PoSearchResultsPrev(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      PoSearchResult: payload.data,
      PoSearchResultPrev: payload.dataPrev,
      PoSearchResultTotalPages: payload.data?.pages?.totalPages,
      PoSearchResultTotalCount: payload.data?.pages?.totalResources,
      spinner: false
    };
  }
  function appendPrevResultSet(state: Object) {
    const payload = {
      data: state.PoSearchResultPrev,
      dataNext: state.PoSearch
    };

    return {
      ...state,
      PoSearchResultPrev: null,
      PoSearchResult: payload.data,
      PoSearchResultNext: payload.dataNext
    };
  }

  function PoSearchResults(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.data)
      // dataNext: null
    };
    return {
      ...state,
      PoSearchResult: payload.data,
      PoSearchResultTotalPages: payload.data?.pages?.totalPages,
      PoSearchResultTotalCount: payload.data?.pages?.totalResources,
      poSearchFilters: payload.data.filters,
      selectedRecordsCount: 0,
      PoSearchResultPrev: null,
      PoSearchResultNext: null,
      spinner: false
    };
  }
  function PoSearchOffset(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.data)
    };
    return {
      ...state,
      PoSearchResult: payload.data,
      PoSearchResultTotalPages: payload?.data?.pages?.totalPages,
      PoSearchResultTotalCount: payload?.data?.pages?.totalResources,
      poSearchFilters: payload?.data?.filters,
      selectedRecordsCount: 0,
      PoSearchResultPrev: null,
      PoSearchResultNext: null,
      spinner: false
    };
  }
  function PoSearchFailure(state: Object, action: Object) {
    return {
      ...state,
      error: action.payload,
      spinner: false
    };
  }
  function toggleSearch(state: Object, action: Object) {
    return {
      ...state,
      showSearch: action.payload
    };
  }
  function resetSearchId(state: Object) {
    return {
      ...state,
      searchId: cryptoRand().toString().substr(2, 8)
    };
  }
  function resetSearchPanelId(state: Object) {
    return {
      ...state,
      searchPanelId: cryptoRand().toString().substr(2, 8)
    };
  }
  function resetPoSearchResult(state: Object) {
    return {
      // ...initialState// correct
      PoSearchResult: {},
      PoSearchResultTotalPages: 0,
      PoSearchResultTotalCount: 0,
      poSearchFilters: {},
      selectedRecordsCount: 0,
      PoSearchResultPrev: {},
      PoSearchResultNext: {},
      page: 0,
      rowsPerPage: 100,
      searchId: '',
      searchRequestData: {
        ...initialSearchFieldsState
      },
      selectedFilters: {
        ...initialPOSearchFiltersState
      },
      selectedFields: [...new Set(state.selectedFields)]
    };
  }
  function persistSearchPanelData(state: Object, action: Object) {
    return {
      ...state,
      searchRequestData: action.payload
    };
  }
  function updatePoSearchId(state: Object) {
    return {
      ...state,
      key: cryptoRand()
    };
  }
  //  posearchRequestReducer functions
  function updateSearchRequestBody(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(state.savedSearchName),
      body: action.payload
    };
  }
  function updateSearchRequestBodyOnSameBookmark(state: Object, action: Object) {
    return {
      ...state,
      body: action.payload
    };
  }
  function updatePoSearchFields(state: Object, action: Object) {
    return {
      ...state, selectedFields: [...new Set(action.payload)]
    };
  }
  function updateFiltersInRequest(state: Object, action: Object) {
    const updatedBody = { ...state.body, filter: action.payload };
    return { ...state, body: updatedBody, isSavedSearchModified: Boolean(state.savedSearchName) };
  }
  function updateFiltersInResponse(state: Object, action: Object) {
    const updatedBody = { ...state.body, filtersInResponse: action.payload };
    return { ...state, body: updatedBody };
  }
  function updateOffsetInRequest(state: Object, action: Object) {
    const updatedBody = { ...state.body, offset: action.payload };
    return { ...state, body: updatedBody };
  }
  function updateSavedSearchNameAndId(state: Object, action: Object) {
    return {
      ...state,
      savedSearchName: action.payload.savedSearchName,
      savedSearchId: action.payload.savedSearchId,
      isSavedSearchModified: false
    };
  }
  function updateSavedSearchName(state: Object, action: Object) {
    return {
      ...state, savedSearchName: action.payload, isSavedSearchModified: false
    };
  }
  function resetPOSearchRequest() {
    return {
      // ...initialState// correct value
      savedSearchName: '',
      savedSearchId: '',
      isSavedSearchModified: false,
      body: null,
      selectedFields: [],
      schedulerData: {}
    };
  }
  function resetPOSearchRequestDataOnClearSearch(state: Object, action: Object) {
    const clearSearchSelectedField = action.payload.payload;
    return {
      savedSearchName: '',
      savedSearchId: '',
      isSavedSearchModified: false,
      body: null,
      selectedFields: [...clearSearchSelectedField],
      selectedFilters: {
        ...initialPOSearchFiltersState
      }
    };
  }
  function setSchedularData(state: Object, action: Object) {
    return {
      ...state,
      schedulerData: action.payload
    };
  }
  //  searchFilterReducer
  function setFilterId(state: Object) {
    return { ...state, filterId: cryptoRand().toString().substr(2, 8) };
  }
  function addNewFilter(state: Object, action: Object) {
    if (!state.selectedFilters[action.payload] && action.payload) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload]: []
        }
      };
    }
    return { ...state };
  }
  function removeFilter(state: Object, action: Object) {
    if (state.selectedFilters[action.payload] && action.payload) {
      const selectedFilters = { ...state.selectedFilters };
      selectedFilters[action.payload] = null;
      return { ...state, selectedFilters };
    }
    return { ...state };
  }
  function addFilterValue(state: Object, action: Object) {
    if (
      action.payload.filter
      && action.payload.value
      && state.selectedFilters[action.payload.filter]
    ) {
      const values = [...state.selectedFilters[action.payload.filter]];
      if (!values.includes(action.payload.value)) {
        values.push(action.payload.value);
      }
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: values
        }
      };
    }
    return { ...state };
  }
  function removeFilterValue(state: Object, action: Object) {
    if (
      action.payload.filter
      && action.payload.value
      && state.selectedFilters[action.payload.filter]
    ) {
      const values = [...state.selectedFilters[action.payload.filter] || []];
      const indexOfValue = values.indexOf(action.payload.value);
      if (indexOfValue > -1) {
        values.splice(indexOfValue, 1);
      }
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: values
        }
      };
    }
    return { ...state };
  }
  function addAllFilterValues(state: Object, action: Object) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }
  function updateFilterDropdownValues(state: Object, action: Object) {
    if (
      action.payload
      && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortDataAscending(action.payload)
      };
    }
    return { ...state };
  }
  function resetFiltersToInitialState(state: Object) {
    return {
      // ...initialState // original value
      ...state,
      filterId: 'none',
      filterDropdownValues: {},
      selectedFilters: {
        ...initialPOSearchFiltersState
      },
      selectedFields: [...new Set(state.selectedFields)]
    };
  }
  function resetSelectedFiltersToInitialState(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      if (state.selectedFilters[filter]) {
        selectedFilters[filter] = [];
      } else {
        selectedFilters[filter] = null;
      }
    });
    return {
      ...state,
      selectedFilters
    };
  }
  function clearFilters(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      selectedFilters[filter] = null;
    });
    return {
      ...state,
      filterDropdownValues: {},
      selectedFilters
    };
  }
  function addAllFilters(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      if (!selectedFilters[filter]) {
        selectedFilters[filter] = [];
      }
    });
    return {
      filterDropdownValues: {},
      selectedFilters
    };
  }
  function bulkUpdateSelectedFilters(state: Object, action: Object) {
    if (action.payload instanceof Array) {
      const modifiedSelectedFilters = { ...state.selectedFilters };
      Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
        if (action.payload.includes(filter)) {
          if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
        } else {
          modifiedSelectedFilters[filter] = null;
        }
      });
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    if ((typeof action.payload === 'object') && action.payload.selectedFilters) {
      const modifiedSelectedFilters = {
        ...state.selectedFilters,
        ...action.payload.selectedFilters
      };
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    return { ...state };
  }

  return {
    updatePOSearchPage,
    updatePOSearchRowsPerPage,
    PoSearchResultsNext,
    appendNextResultSet,
    PoSearchResultsPrev,
    appendPrevResultSet,
    PoSearchResults,
    PoSearchOffset,
    PoSearchFailure,
    toggleSearch,
    resetSearchId,
    resetSearchPanelId,
    resetPoSearchResult,
    persistSearchPanelData,
    updatePoSearchId,
    // posearchRequest functions
    updateSearchRequestBody,
    updateSearchRequestBodyOnSameBookmark,
    updatePoSearchFields,
    updateFiltersInRequest,
    updateFiltersInResponse,
    updateOffsetInRequest,
    updateSavedSearchNameAndId,
    updateSavedSearchName,
    resetPOSearchRequest,
    resetPOSearchRequestDataOnClearSearch,
    setSchedularData,
    // searchFilter Functions
    setFilterId,
    addNewFilter,
    removeFilter,
    addFilterValue,
    removeFilterValue,
    addAllFilterValues,
    updateFilterDropdownValues,
    resetFiltersToInitialState,
    resetSelectedFiltersToInitialState,
    clearFilters,
    addAllFilters,
    bulkUpdateSelectedFilters

  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

export const actions = { ...slice.actions };

function createExtraActions() {
  // searchFilter actions api calls
  function fetchUnfilteredPOResults() {
    return createAsyncThunk(
      `${name}/fetchUnfilteredPOResults`,
      async (callback, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        let requestBody = {
          ...state.PoSearch.body,
          filter: [],
          offset: '0'
        };
        requestBody = setFieldSrcSysNm(requestBody);
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        request({
          api: 'POSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.PoSearchResults(response));
          dispatch(actions.clearFilters());
          dispatch(actions.resetSearchPanelId());
          dispatch(actions.updateFiltersInRequest([]));
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          if (callback) {
            callback(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async ({
        payload,
        applyFiltersCallback,
        shouldRequestFilterValues = false
      }, { getState, dispatch }) => {
        const state = getState();
        const selectedFilters = {
          ...state.PoSearch.selectedFilters,
          [payload.filter]: payload.values
        };
        const filtersRequest = mapFiltersToRequest(selectedFilters);
        dispatch(toggleSpinner(true));
        let requestBody = {
          ...state.PoSearch.body,
          filter: filtersRequest,
          offset: '0'
        };
        const selectedFilterKeys = Object.keys(selectedFilters)
          .filter((filter) => selectedFilters[filter]);
        if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
          requestBody.filtersInResponse = getFilterInResponseValueInPOSearch(selectedFilterKeys);
        } else {
          delete requestBody.filtersInResponse;
        }
        requestBody = setFieldSrcSysNm(requestBody);
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        request({
          api: 'POSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.PoSearchResults(response));
          dispatch(actions.addAllFilterValues(payload));
          dispatch(actions.updateOffsetInRequest('0'));
          dispatch(actions.updateFiltersInRequest(filtersRequest));
          dispatch(actions.setFilterId());
          if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
            dispatch(actions.updateFiltersInResponse(requestBody.filtersInResponse));
          }
          if (response.data.filters) {
            dispatch(actions.updateFilterDropdownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
          }
          if (applyFiltersCallback) {
            applyFiltersCallback(response);
          }
        }).catch((error: Object) => {
          if (applyFiltersCallback) {
            applyFiltersCallback(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function searchNewFilterRequestCallback(requestNewFiltersCallback, error) {
    if (requestNewFiltersCallback) {
      requestNewFiltersCallback(null, error);
    }
  }
  function requestForNewFilters() {
    return createAsyncThunk(
      `${name}/requestForNewFilters`,
      async ({
        filtersSelected,
        enableCheckboxForDefault,
        requestNewFiltersCallback
      }, { getState, dispatch }) => {
        const state = getState();
        const {
          selectedFilters,
          filterDropdownValues
        } = { ...state.PoSearch };
        const modifiedSelectedFilters = { ...selectedFilters };
        Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
          getModifiedSelectedFilters(filtersSelected, filter, modifiedSelectedFilters);
        });

        const selectedFilterData = {
          selectedFilterListForPOSearch: filtersSelected
        };
        let requestBody = { ...state.PoSearch.body };
        const searchFiltersToBeRequested = [];
        const filtersInResponse = [];
        const selectedFilterKeys = Object.keys(modifiedSelectedFilters)
          .filter((filter) => modifiedSelectedFilters[filter]);
        const appliedFiltersKeys = selectedFilterKeys.filter((filter) => (
          modifiedSelectedFilters[filter] && modifiedSelectedFilters[filter].length > 0
        ));
        let searchFiltersToBeApplied = null;
        const hasAppliedSearchFiltersChanged = (!(requestBody.filter || []).every((filter) => (
          appliedFiltersKeys.includes(filter.fieldName)
        )));

        if (hasAppliedSearchFiltersChanged) {
          searchFiltersToBeApplied = mapFiltersToRequest(modifiedSelectedFilters);
          requestBody.filter = [...searchFiltersToBeApplied];
        }

        selectedFilterKeys.forEach((filter) => {
          getSelectedFilterKeys(
            filter, modifiedSelectedFilters, filterDropdownValues, searchFiltersToBeRequested,
            filtersInResponse
          );
        });

        if (searchFiltersToBeRequested.length === 0) {
          delete requestBody.filtersInResponse;
        } else {
          requestBody.filtersInResponse = searchFiltersToBeRequested;
        }
        requestBody.offset = '0';
        if (enableCheckboxForDefault) {
          dispatch(settingsActions.userProfileSettings({
            requestBody: selectedFilterData
          }));
        }
        requestBody = setFieldSrcSysNm(requestBody);
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        dispatch(toggleSpinner(true));
        request({
          api: 'POSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.updatePOSearchPage(0));
          dispatch(actions.setFilterId());
          dispatch(actions.resetSearchPanelId());
          dispatch(actions.updateOffsetInRequest('0'));
          dispatch(actions.PoSearchResults(response));
          dispatch(actions.bulkUpdateSelectedFilters(filtersSelected));
          if (response.data.filters) {
            dispatch(actions.updateFilterDropdownValues({
              ...filterDropdownValues,
              ...getFilterValuesFromResponse(response.data.filters)
            }));
            dispatch(actions.updateFiltersInResponse(filtersInResponse));
          }
          if (hasAppliedSearchFiltersChanged) {
            dispatch(actions.updateFiltersInRequest(searchFiltersToBeApplied));
            dispatch(actions.PoSearchResults(response));
            dispatch(actions.updatePOSearchPage(0));
          }
          if (requestNewFiltersCallback) {
            requestNewFiltersCallback(response);
          }
        }).catch((error: Object) => {
          console.log('error');
          dispatch(toggleSpinner(false));
          searchNewFilterRequestCallback(requestNewFiltersCallback, error);
        });
      }
    );
  }

  // po search result api calls
  // function poSearchResultNextAppend() {
  //   return createAsyncThunk(
  //     `${name}/poSearchResultNextAppend`,
  //     async (_, { getState, dispatch }) => {
  //       const state = getState();
  //       if (state.PoSearch) {
  //         const updatedCurrentResultSet = state.PoSearch.PoSearchResult;
  //         const updatedNextResultSet = state.PoSearch.PoSearchResultNext;
  //         dispatch(actions.appendNextResultSet({updatedCurrentResultSet, updatedNextResultSet}));
  //       }
  //     }
  //   );
  // }
  function fetchPoSearchResultsNextSet() {
    return createAsyncThunk(
      `${name}/fetchPoSearchResultsNextSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
        if (state.PoSearch && state.PoSearch.PoSearchResult
      && state.PoSearch.PoSearchResult.pages.next) {
          const currentResultSet = state.PoSearch.PoSearchResult;
          let updatedRequest = state.PoSearch.body;
          updatedRequest = setFieldSrcSysNm(updatedRequest);
          updatedRequest = setFieldItemVas(updatedRequest);
          updatedRequest = setFieldAsiaSize(updatedRequest);
          updatedRequest = setFilterAsiaSize(updatedRequest);
          const searchResultNextOffset = state.PoSearch.PoSearchResult.pages.next;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };
          // updatedRequest.offset = searchResultNextOffset.slice(7, resNext);
          const requestBody = { ...updatedRequest };
          delete requestBody.filtersInResponse;
          request({
            api: 'POSearch',
            method: 'post',
            data: requestBody
          }, dispatch, getState).then((response: Object) => {
            updatedRequest = removeFieldAsiaSize(updatedRequest);
            dispatch(actions.updateSearchRequestBodyOnSameBookmark(updatedRequest));
            dispatch(actions.PoSearchResultsNext({ response, currentResultSet }));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
          }).catch((error: Object) => {
            console.log('Error: ', error);
            // dispatch(actions.PoSearchFailure(error));
            dispatch(toggleSpinner(false));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
          });
        }
      }
    );
  }
  function PoSearchDataNextSet() {
    return createAsyncThunk(
      `${name}/PoSearchDataNextSet`,
      async (
        { poSearchfetchPoSearchResultsNextSet },
        { dispatch }
      ) => {
        dispatch(poSearchfetchPoSearchResultsNextSet());
      }
    );
  }
  function fetchPoSearchResultsPrevSet() {
    return createAsyncThunk(
      `${name}/fetchPoSearchResultsPrevSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
        if (state.PoSearch && state.PoSearch.PoSearchResult
          && state.PoSearch.PoSearchResult.pages.prev) {
          const currentResultSet = state.PoSearch.PoSearchResult;
          let updatedRequest = state.PoSearch.body;
          updatedRequest = setFieldSrcSysNm(updatedRequest);
          updatedRequest = setFieldItemVas(updatedRequest);
          updatedRequest = setFieldAsiaSize(updatedRequest);
          updatedRequest = setFilterAsiaSize(updatedRequest);
          const searchResultPrevOffset = state.PoSearch.PoSearchResult.pages.prev;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };
          const requestbody = { ...updatedRequest };
          delete requestbody.filtersInResponse;
          request({
            api: 'POSearch',
            method: 'post',
            data: requestbody
          }, dispatch, getState).then((response: Object) => {
            updatedRequest = removeFieldAsiaSize(updatedRequest);
            dispatch(actions.updateSearchRequestBodyOnSameBookmark(updatedRequest));
            dispatch(actions.PoSearchResultsPrev({ response, currentResultSet }));
            dispatch(toggleSpinner(false));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
          }).catch((error: Object) => {
            console.log('Error: ', error);
            // dispatch(actions.PoSearchFailure(error));
            dispatch(toggleSpinner(false));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
          });
        }
      }
    );
  }
  function PoSearchDataPrevSet() {
    return createAsyncThunk(
      `${name}/PoSearchDataPrevSet`,
      async (
        {
          poSearchfetchPoSearchResultsPrevSet
        }, { dispatch }
      ) => {
        dispatch(poSearchfetchPoSearchResultsPrevSet());
      }
    );
  }
  function fetchPOSearchResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchPOSearchResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.PoSearch && state.PoSearch.PoSearchResult) {
          let updatedRequest = JSON.parse(JSON.stringify(state.PoSearch.body));
          updatedRequest = setFieldSrcSysNm(updatedRequest);
          updatedRequest = setFieldItemVas(updatedRequest);
          updatedRequest = setFieldAsiaSize(updatedRequest);
          updatedRequest = setFilterAsiaSize(updatedRequest);
          delete updatedRequest.offset;
          updatedRequest.offset = '0';
          delete updatedRequest.filtersInResponse;
          dispatch(toggleSpinner(true));
          request({
            api: 'POSearch',
            method: 'post',
            data: updatedRequest
          }, dispatch, getState).then((response: Object) => {
            dispatch(actions.PoSearchOffset(response));
            dispatch(actions.resetSearchPanelId());
            dispatch(toggleSpinner(false));
          }).catch((error: Object) => {
            console.log('Error: ', error);
            // dispatch(actions.PoSearchFailure(error));
            dispatch(toggleSpinner(false));
          });
        }
      }
    );
  }
  function PoSearchDataOffset() {
    return createAsyncThunk(
      `${name}/PoSearchDataOffset`,
      async (
        {
          poSearchfetchPOSearchResultsOffset
        }, { dispatch }
      ) => {
        dispatch(poSearchfetchPOSearchResultsOffset());
      }
    );
  }
  function callPOSearchAPI() {
    return createAsyncThunk(
      `${name}/callPOSearchAPI`,
      async ({
        requestBody, selectedFilterOptions, rowPerPageOption, callback
      }, { getState, dispatch }) => {
        let payload = { ...requestBody };
        payload = setValidFields(poSearchFieldProperties, payload, dataTableHeaderInactv);
        payload = setFieldSrcSysNm(payload);
        payload = setFieldItemVas(payload);
        payload = setFieldAsiaSize(payload);
        payload = setFilterAsiaSize(payload);
        if (serviceAPI('POSearch').mock) {
          dispatch(toggleSpinner(false));
          dispatch(actions.updateSearchRequestBody(payload));
          dispatch(actions.updatePOSearchRowsPerPage(rowPerPageOption));
          dispatch(actions.PoSearchResults(newContractPOSearchDataMock));
        } else {
          request({
            api: 'POSearch',
            method: 'post',
            data: payload,
            cancellable: true
          }, dispatch, getState).then((response: Object) => {
            dispatch(toggleSpinner(false));
            payload = removeFieldAsiaSize(payload);
            const appliedPoSearchField = payload.search
              .map((item) => item.fieldName)
              .filter((field) => field !== poSearchFieldMapping.PO_LINE_ITEM_STATUS);
            dispatch(actions.updatePoSearchFields(poSearchCriteriaFields
              .map((field) => field.key)
              .filter((field) => appliedPoSearchField.includes(field))));
            dispatch(actions.resetSelectedFiltersToInitialState());
            dispatch(actions.updateSearchRequestBody(payload));
            dispatch(actions.PoSearchResults(response));
            dispatch(actions.resetSearchId());
            dispatch(actions.updatePOSearchRowsPerPage(rowPerPageOption));
            dispatch(actions.updatePOSearchPage(0));
            dispatch(actions.updateFilterDropdownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
            if (selectedFilterOptions?.length) {
              const updatedFilterValues = response?.data?.filters.map((key) => key.filterName);
              dispatch(actions.bulkUpdateSelectedFilters(updatedFilterValues));
            }
            if (callback) {
              callback(response);
            }
          }).catch((error: Object) => {
            // dispatch(actions.PoSearchFailure(error));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
        }
      }
    );
  }
  function fetchPOSearchResults() {
    return createAsyncThunk(
      `${name}/fetchPOSearchResults`,
      async ({
        fieldData, callback, poSearchCallSearchApi
      }, { getState, dispatch }) => {
        const searchPayload = mapInputFieldsToRequest(fieldData, 'poSearch');
        const fields = [];
        if (fieldData['poLine.dpomItemStatus'] !== 'Cancelled') {
          searchPayload.push({
            fieldName: poSearchFieldMapping.DELETION_INDICATOR,
            function: 'NOT IS_DEFINED'
          });
        }
        dataTableHeader.forEach((headRow: Object) => {
          fields.push(headRow.primary);
          const secondaryFields = {};
          if (headRow.secondary && !secondaryFields[headRow.secondary]) {
            secondaryFields[headRow.secondary] = true;
            fields.push(headRow.secondary);
          }
          if (headRow.additionalFields && headRow.additionalFields.length > 0) {
            fields.push(...headRow.additionalFields);
          }
        });
        dataTableHeader.forEach((headRow: Object) => {
          if (headRow.includeInRequest) {
            fields.push(headRow.primary);
          }
        });
        dataTableCustomView.forEach((headerRow: Object) => {
          if (headerRow.includeInRequest) {
            fields.push(headerRow.primary);
          }
        });
        dataTableHeaderInactv.forEach((headRow: Object) => {
          fields.push(headRow.primary);
        });

        const state = getState();
        const { searchpaneldata } = state;
        const {
          selectedColumnOrderOptions,
          selectedFilterOptions,
          rowPerPageOption
        } = searchpaneldata || {};
        const fieldsForRequest = getFieldsForRequest(
          state, selectedColumnOrderOptions, fields
        );
        const filterValuesOptByUser = getSelectedFilterValues(selectedFilterOptions);
        const filtersInResponse = getFiltersInResponse(
          selectedFilterOptions, filterValuesOptByUser, state
        );
        const filteredData = getFilterData(filtersInResponse);

        const requestBody = state.PoSearch.body
          ? {
            ...state.PoSearch.body,
            search: searchPayload,
            filter: [],
            filtersInResponse: filteredData,
            offset: 0
          } : {
            ...searchRequestTemplate,
            fields: fieldsForRequest,
            search: searchPayload,
            filtersInResponse: filteredData
          };
        if (serviceAPI('POSearch').mock) {
          dispatch(toggleSpinner(true));
          dispatch(poSearchCallSearchApi({
            requestBody,
            selectedFilterOptions,
            rowPerPageOption,
            // Number(rowPerPageOption),
            callback
          }));
        } else {
          dispatch(toggleSpinner(true));
          dispatch(poSearchCallSearchApi({
            requestBody,
            selectedFilterOptions,
            rowPerPageOption,
            // Number(rowPerPageOption),
            callback
          }));
        }
      }
    );
  }
  function fetchPOCustomizedSearchResults() {
    return createAsyncThunk(
      `${name}/fetchPOCustomizedSearchResults`,
      async ({
        updatedField,
        preserveSearch = false,
        callback
      }, { getState, dispatch }) => {
        if (typeof updatedField === 'object') {
          let updatedRequestBody = updatedField;
          updatedRequestBody = setFieldSrcSysNm(updatedRequestBody);
          updatedRequestBody = setFieldItemVas(updatedRequestBody);
          updatedRequestBody = setFieldAsiaSize(updatedRequestBody);
          updatedRequestBody = setFilterAsiaSize(updatedRequestBody);
          const requestWithoutFilters = { ...updatedRequestBody, offset: '0' };
          delete requestWithoutFilters.filtersInResponse;
          dispatch(toggleSpinner(true));
          if (serviceAPI('POSearch').mock) {
            dispatch(toggleSpinner(false));
            dispatch(actions.updateSearchRequestBody(updatedRequestBody));
            dispatch(actions.PoSearchResults(newContractPOSearchDataMock));
          } else {
            request({
              api: 'POSearch',
              method: 'post',
              data: requestWithoutFilters,
              cancellable: true
            }, dispatch, getState).then((response: Object) => {
              updatedRequestBody = removeFieldAsiaSize(updatedRequestBody);
              dispatch(actions.updatePoSearchId());
              dispatch(toggleSpinner(false));
              dispatch(actions.updateOffsetInRequest('0'));
              dispatch(actions.resetSearchPanelId());
              dispatch(actions.updateSearchRequestBody(updatedRequestBody));
              const appliedPoCustomisedSearchField = updatedRequestBody.search
                .map((item) => (item.fieldName))
                .filter((field) => (field !== poSearchFieldMapping.PO_LINE_ITEM_STATUS));
              dispatch(actions.updatePoSearchFields(poSearchCriteriaFields
                .map((field) => field.key)
                .filter((field) => appliedPoCustomisedSearchField.includes(field))));
              dispatch(actions.PoSearchResults(response));
              if (!preserveSearch) {
                dispatch(actions.resetSearchId());
              }
              if (callback) {
                callback(response);
              }
            }).catch((error: Object) => {
              dispatch(actions.updatePoSearchId());
              // dispatch(actions.PoSearchFailure(error));
              dispatch(toggleSpinner(false));
              if (callback) {
                callback(null, error);
              }
            });
          }
        }
      }
    );
  }
  function getUserDetailsFromAdApi() {
    return createAsyncThunk(
      `${name}/getUserDetailsFromAdApi`,
      async (props, { getState, dispatch }) => {
        const { emailId, callback } = props;
        dispatch(toggleSpinner(true));
        request({
          api: 'ADUSERDETAILSSECURITYAPI',
          method: 'get',
          routeParams: { emailId }
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function fetchSavedSearchResults() {
    return createAsyncThunk(
      `${name}/fetchSavedSearchResults`,
      async ({
        savedSearchData, callback
      }, { getState, dispatch }) => {
        const state = getState();
        let rowsPerPageOption;
        let filterOptions = [];
        if (state.searchpaneldata) {
          rowsPerPageOption = state.searchpaneldata?.rowPerPageOption;
          filterOptions = state.searchpaneldata?.selectedFilterOptions;
        }

        const filtersInResponse = savedSearchData.searchCriteria
          && savedSearchData.searchCriteria.filtersInResponse
          ? savedSearchData.searchCriteria.filtersInResponse
          : getFiltersInResponseRequestBody(initialPOSearchFiltersState);

        let requestBody = { ...savedSearchData.searchCriteria, filtersInResponse, offset: '0' };

        if (requestBody.search
          && !requestBody.search
            .find((criteria) => criteria.fieldName === poSearchFieldMapping.DELETION_INDICATOR)) {
          requestBody.search.push({
            fieldName: poSearchFieldMapping.DELETION_INDICATOR,
            function: 'NOT IS_DEFINED'
          });
        }
        requestBody = setValidFields(poSearchFieldProperties, requestBody, dataTableHeaderInactv);
        requestBody = setFieldSrcSysNm(requestBody);
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        const filteredFieldsData = [...requestBody.fields].filter(
          (item) => !item.toLowerCase().includes('ratetypename')
        );
        requestBody = { ...requestBody, fields: filteredFieldsData };

        dispatch(actions.updateSearchRequestBody(requestBody));
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        request({
          api: 'POSearch',
          method: 'post',
          data: { savedSearchID: savedSearchData.id, ...requestBody },
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(actions.updatePoSearchId());
          requestBody = removeFieldAsiaSize(requestBody);
          dispatch(actions.updateSearchRequestBody(requestBody));
          const appliedSavedSearchField = requestBody.search
            .map((item) => (item.fieldName))
            .filter((field) => (field !== poSearchFieldMapping.PO_LINE_ITEM_STATUS));
          dispatch(actions.updatePoSearchFields(poSearchCriteriaFields
            .map((field) => field.key)
            .filter((field) => appliedSavedSearchField.includes(field))));
          dispatch(actions.persistSearchPanelData(
            mapRequestToInputFields([...savedSearchData.searchCriteria.search])
          ));
          dispatch(actions.PoSearchResults(response));
          dispatch(actions.resetSearchId());
          dispatch(actions.updatePOSearchRowsPerPage(rowsPerPageOption));
          dispatch(actions.updatePOSearchPage(0));
          dispatch(actions.updateSavedSearchNameAndId({
            savedSearchName: savedSearchData.name,
            savedSearchId: savedSearchData.id
          }));
          dispatch(actions.resetFiltersToInitialState());
          const filterValuesFromResponse = getFilterValuesFromResponse(response.data.filters || []);
          dispatch(actions.updateFilterDropdownValues(filterValuesFromResponse));
          if (filterOptions.length > 0) {
            dispatch(actions.bulkUpdateSelectedFilters(filterOptions));
          }
          const { searchCriteria } = savedSearchData;
          if (searchCriteria && searchCriteria.filter && searchCriteria.filter.length > 0) {
            const selectedFilters = {};
            searchCriteria.filter.forEach((filter) => {
              const availableFilters = filterValuesFromResponse[filter.fieldName];
              selectedFilters[filter.fieldName] = filter.fieldValue
                .map((filterValue) => availableFilters
                  .find((availableFilter) => availableFilter.includes(filterValue)))
                .filter((filterValue) => filterValue);
            });
            dispatch(actions.bulkUpdateSelectedFilters({ selectedFilters }));
          }
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          dispatch(actions.updatePoSearchId());
          // dispatch(actions.PoSearchFailure(error));
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  function requestExportFilePath() {
    return createAsyncThunk(
      `${name}/requestExportFilePath`,
      async ({
        ids, fileFormat, callback, type
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        const searchRequestBody = { ...(state.PoSearch.body || {}) };
        const searchRequestFields = [...(searchRequestBody.fields || [])];
        const removeAdditionalFields = [
          ...dataTableCustomView.filter((column) => column.additionalFields)
            .map((headRow) => headRow.additionalFields[0]),
          ...dataTableHeader.filter((column) => column.additionalFields)
            .map((headRow) => headRow.additionalFields[0]),
          ...dataTableHeaderInactv.filter((column) => column.primary)
            .map((headRow) => headRow.primary)
        ];
        const filedsIncludingDuplicate = searchRequestFields.filter(
          (item) => !removeAdditionalFields.includes(item)
        );
        const duplicateKeysAre = filedsIncludingDuplicate.filter(
          (keys, index) => filedsIncludingDuplicate.indexOf(keys) !== index
        );
        const fieldToExcludes = [
          ...appConfig.excludedPOFieldsInExportedFile,
          ...dataTableHeader.filter((column) => column.includeInRequest)
            .map((headRow) => headRow.primary),
          ...dataTableCustomView.filter((column) => column.includeInRequest)
            .map((headRow) => headRow.primary)
        ];
        let distinctAppConfigKeys = [];
        if (duplicateKeysAre.length === 0) {
          distinctAppConfigKeys = [...fieldToExcludes];
        } else {
          distinctAppConfigKeys = fieldToExcludes.filter((duplicated) => (
            !duplicateKeysAre.includes(duplicated)
          ));
        }
        let requestBody = {
          search: [...(searchRequestBody.search || [])],
          fields: filedsIncludingDuplicate.filter((field: string) => (
            !distinctAppConfigKeys.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          fileFormat
        };
        if (ids.length > 0) {
          requestBody.search.push({
            fieldName: 'sizes.sizeId',
            operator: type === 'includes' ? '=' : '!=',
            fieldValue: ids
          });
        }
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        request({
          api: 'POExport',
          method: 'post',
          data: requestBody
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  return {
    fetchUnfilteredPOResults: fetchUnfilteredPOResults(),
    applyFilters: applyFilters(),
    requestForNewFilters: requestForNewFilters(),
    // poSearchResultNextAppend: poSearchResultNextAppend(),

    PoSearchDataNextSet: PoSearchDataNextSet(),
    PoSearchDataPrevSet: PoSearchDataPrevSet(),
    PoSearchDataOffset: PoSearchDataOffset(),
    fetchPoSearchResultsNextSet: fetchPoSearchResultsNextSet(),
    fetchPoSearchResultsPrevSet: fetchPoSearchResultsPrevSet(),
    fetchPOSearchResultsOffset: fetchPOSearchResultsOffset(),
    fetchPOSearchResults: fetchPOSearchResults(),
    callPOSearchAPI: callPOSearchAPI(),
    fetchPOCustomizedSearchResults: fetchPOCustomizedSearchResults(),
    getUserDetailsFromAdApi: getUserDetailsFromAdApi(),
    fetchSavedSearchResults: fetchSavedSearchResults(),
    requestExportFilePath: requestExportFilePath()
  };
}

export const extraActions = createExtraActions();

export const poSearchResultActions = { ...actions, ...extraActions };
export const poSearchResultReducer = slice.reducer;
