import { pricingNonProd } from '../../helpers/envServer';
import poSearchFieldMapping from '../fieldMappings/poSearchFieldMapping';

const lblPurchaseOrderNumber = 'Purchase Order Number';
const lblSizeDescription = 'Size Description';
const lblProductCode = 'Product Code';
const destinationCountryValue = 'Destination Country';
const lblPOLineItemNumber = 'PO Line Item Number';
const lblScheduleLineItemNumber = 'Schedule Line Item Number';
const lblTradingCoPoNumber = 'Trading Co Purchase Order Number';
const lblPMODecCode = 'PMO/DEC Code';
const lblModeOfTransportation = 'Mode of Transportation';
const lblCurrentEvent = 'Current Event';
const lblInitGACReasonCode = 'Initial GAC Reason Code';
const lblNextEvent = 'Next Event';
const lblPurchaseGroup = 'Purchase Group';
const lblPurchaseOrg = 'Purchase Org';
const lblMeasureUnit = 'Unit of Measure';
const lblProductName = 'Product Name';
const lblAddressOverrideIndicator = 'Address Override Indicator';

export function renderRateUnitValue(row) {
  if ((row[this.additionalFields[0]] || '').toLowerCase() === 'percent') {
    return `${row[this.primary] || ''} %`;
  }
  return `${row[this.primary] || ''} ${row[this.secondary] || ''}`.trim() || undefined;
}
const poSearchFields = {
  [poSearchFieldMapping.PURCHASE_ORDER_NUMBER]: {
    primary: poSearchFieldMapping.PURCHASE_ORDER_NUMBER,
    secondary: '',
    type: 'numeric',
    link: false,
    default: true,
    label: lblPurchaseOrderNumber,
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    includeInRequest: true
  },
  [poSearchFieldMapping.PO_LINE_ITEM_NUMBER]: {
    primary: poSearchFieldMapping.PO_LINE_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    default: true,
    label: lblPOLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    includeInRequest: true
  },
  [poSearchFieldMapping.SCHEDULE_LINE_ITEM_NUMBER]: {
    primary: poSearchFieldMapping.SCHEDULE_LINE_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: lblScheduleLineItemNumber,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: true
  },
  [poSearchFieldMapping.SIZE_DESC]: {
    primary: poSearchFieldMapping.SIZE_DESC,
    secondary: '',
    type: 'string',
    link: false,
    default: true,
    label: lblSizeDescription,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.TR_CO_PURCHASE_ORDER_NUMBER]: {
    primary: poSearchFieldMapping.TR_CO_PURCHASE_ORDER_NUMBER,
    secondary: '',
    type: 'numeric',
    link: false,
    label: lblTradingCoPoNumber,
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    default: true
  },
  [poSearchFieldMapping.VENDOR_CODE]: {
    primary: poSearchFieldMapping.VENDOR_CODE,
    secondary: poSearchFieldMapping.VENDOR_NAME,
    type: 'string',
    link: false,
    label: 'Vendor Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true,
    includeInRequest: true
  },
  [poSearchFieldMapping.PMO_DEC_CODE]: {
    primary: poSearchFieldMapping.PMO_DEC_CODE,
    secondary: poSearchFieldMapping.PMO_DEC_CODE_DESC,
    type: 'string',
    link: false,
    default: true,
    label: lblPMODecCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: {
    primary: poSearchFieldMapping.PURCHASING_DOCUMENT_TYPE_CODE,
    secondary: poSearchFieldMapping.PURCHASING_DOCUMENT_TYPE_DESC,
    type: 'string',
    link: false,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.DOCUMENT_DATE]: {
    primary: poSearchFieldMapping.DOCUMENT_DATE,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    default: true,
    label: 'Document Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  [poSearchFieldMapping.CHANGE_DATE]: {
    primary: poSearchFieldMapping.CHANGE_DATE,
    secondary: '',
    type: 'date',
    link: false,
    default: true,
    label: 'Change Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.PLANNING_PRIORITY_NUMBER_EDP_CODE]: {
    primary: poSearchFieldMapping.PLANNING_PRIORITY_NUMBER_EDP_CODE,
    secondary: poSearchFieldMapping.PLANNING_PRIORITY_NUMBER_EDP_DESC,
    type: 'string',
    link: false,
    label: 'Planning Priority Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.MRGAC]: {
    primary: poSearchFieldMapping.MRGAC,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    default: true,
    label: 'MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  [poSearchFieldMapping.OGAC]: {
    primary: poSearchFieldMapping.OGAC,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    default: true,
    label: 'OGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  [poSearchFieldMapping.GAC]: {
    primary: poSearchFieldMapping.GAC,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    default: true,
    label: 'GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false
  },
  [poSearchFieldMapping.GAC_REASON_CODE]: {
    primary: poSearchFieldMapping.GAC_REASON_CODE,
    secondary: poSearchFieldMapping.GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.GAC_USECASE_CODE]: {
    primary: poSearchFieldMapping.GAC_USECASE_CODE,
    secondary: poSearchFieldMapping.GAC_USECASE_CODE_DESC,
    type: 'string',
    link: false,
    label: 'GRC Usecase Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.PRODUCT_CODE]: {
    primary: poSearchFieldMapping.PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    default: true,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    includeInRequest: true
  },
  [poSearchFieldMapping.SIZE_QUANTITY]: {
    primary: poSearchFieldMapping.SIZE_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    default: true,
    label: 'Size Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.MODE_OF_TRANSPORTATION]: {
    primary: poSearchFieldMapping.MODE_OF_TRANSPORTATION,
    secondary: poSearchFieldMapping.MODE_OF_TRANSPORTATION_MODE,
    type: 'string',
    link: false,
    label: lblModeOfTransportation,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.PLANT_CODE]: {
    primary: poSearchFieldMapping.PLANT_CODE,
    secondary: poSearchFieldMapping.PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.PURCHASE_ORDER_FOB_SIZE_AMOUNT]: {
    primary: poSearchFieldMapping.PURCHASE_ORDER_FOB_SIZE_AMOUNT,
    secondary: poSearchFieldMapping.PURCHASE_ORDER_FOB_SIZE_CURR_CURR,
    type: 'string',
    link: false,
    label: 'Gross Price/FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.PURCHASE_ORDER_FOB_SIZE_CURR_CODE]
      : [],
    renderValue: renderRateUnitValue,
    default: true
  },
  [poSearchFieldMapping.SHIPPING_TYPE]: {
    primary: poSearchFieldMapping.SHIPPING_TYPE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Shipping Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  [poSearchFieldMapping.INVENTORY_SEGMENT_CODE]: {
    primary: poSearchFieldMapping.INVENTORY_SEGMENT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Inventory Segment Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TR_CO_FOB]: {
    primary: poSearchFieldMapping.TR_CO_FOB,
    secondary: poSearchFieldMapping.TR_CO_FOB_CURRENCY,
    type: 'string',
    link: false,
    label: 'Trading Co Gross Price/FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.TR_CO_FOB_RATE]
      : [],
    renderValue: renderRateUnitValue,
    default: false
  },
  [poSearchFieldMapping.FX_ADJ_REQUIRED_INDICATOR]: {
    primary: poSearchFieldMapping.FX_ADJ_REQUIRED_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'FX Adjustment Required Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    includeInRequest: true,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TRCO_RELEVANT_INDICATOR]: {
    primary: poSearchFieldMapping.TRCO_RELEVANT_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Trd Co rel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    includeInRequest: true,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FX_ADJ_DATE]: {
    primary: poSearchFieldMapping.FX_ADJ_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'FX Adjustment Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FOREIGN_EXCHANGE]: {
    primary: poSearchFieldMapping.FOREIGN_EXCHANGE,
    secondary: poSearchFieldMapping.FOREIGN_EXCHANGE_CURRENCY,
    type: 'string',
    link: false,
    label: 'Foreign Exchange',
    wrap: true,
    includeInRequest: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.FOREIGN_EXCHANGE_RATE]
      : [],
    renderValue: renderRateUnitValue
  },
  [poSearchFieldMapping.NET_INCL_DISCOUNTS_AMOUNT]: {
    primary: poSearchFieldMapping.NET_INCL_DISCOUNTS_AMOUNT,
    secondary: poSearchFieldMapping.NET_INCL_DISCOUNTS_AMOUNT_CURRENCY,
    type: 'string',
    link: false,
    label: 'Net including discounts',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.NET_INCL_DISCOUNTS_AMOUNT_RATE]
      : [],
    renderValue: renderRateUnitValue,
    default: false
  },
  [poSearchFieldMapping.TR_CO_INCL_DISCOUNTS]: {
    primary: poSearchFieldMapping.TR_CO_INCL_DISCOUNTS,
    secondary: poSearchFieldMapping.TR_CO_INCL_DISCOUNTS_CURRENCY,
    type: 'string',
    link: false,
    label: 'Trading Co Net including discounts',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.TR_CO_INCL_DISCOUNTS_RATE]
      : [],
    renderValue: renderRateUnitValue,
    default: false
  },
  [poSearchFieldMapping.NET_INCL_SURCH_AMOUNT]: {
    primary: poSearchFieldMapping.NET_INCL_SURCH_AMOUNT,
    secondary: poSearchFieldMapping.NET_INCL_SURCH_AMOUNT_CURRENCY,
    type: 'string',
    link: false,
    label: 'Net including surcharges',
    wrap: true,
    numeric: 'false',
    default: false,
    disablePadding: false,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.NET_INCL_SURCH_AMOUNT_RATE]
      : [],
    renderValue: renderRateUnitValue
  },
  [poSearchFieldMapping.SIZE_SORT_SEQUENCE_NUMBER]: {
    primary: poSearchFieldMapping.SIZE_SORT_SEQUENCE_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Sort Sequence Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.UPC]: {
    primary: poSearchFieldMapping.UPC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'UPC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCT_FOB_AMOUNT]: {
    primary: poSearchFieldMapping.PRODUCT_FOB_AMOUNT,
    secondary: poSearchFieldMapping.PRODUCT_FOB_AMOUNT_CURRENCY,
    type: 'string',
    link: false,
    label: 'Product FOB',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FOB_MISMATCH_INDICATOR]: {
    primary: poSearchFieldMapping.FOB_MISMATCH_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'FOB Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.SIZE_MISMATCH_INDICATOR]: {
    primary: poSearchFieldMapping.SIZE_MISMATCH_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.CREATED_BY]: {
    primary: poSearchFieldMapping.CREATED_BY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Created By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CREATE_DATE]: {
    primary: poSearchFieldMapping.CREATE_DATE,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    label: 'Create Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.INTERNATIONAL_COMMERCIAL_TERMS1]: {
    primary: poSearchFieldMapping.INTERNATIONAL_COMMERCIAL_TERMS1,
    secondary: poSearchFieldMapping.INTERNATIONAL_COMMERCIAL_TERMS1_DESC,
    type: 'string',
    link: false,
    label: 'In Co Terms',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MCO]: {
    primary: poSearchFieldMapping.MCO,
    secondary: poSearchFieldMapping.MCO_DESC,
    type: 'string',
    link: false,
    label: 'MCO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.DESTINATION_COUNTRY_CODE]: {
    primary: poSearchFieldMapping.DESTINATION_COUNTRY_CODE,
    secondary: poSearchFieldMapping.DESTINATION_COUNTRY_NAME,
    type: 'string',
    link: false,
    label: destinationCountryValue,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.COMPANY_CODE]: {
    primary: poSearchFieldMapping.COMPANY_CODE,
    secondary: poSearchFieldMapping.COMPANY_DESC,
    type: 'string',
    link: false,
    label: 'Company Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    includeInRequest: true,
    default: false
  },
  [poSearchFieldMapping.TR_CO_CODE]: {
    primary: poSearchFieldMapping.TR_CO_CODE,
    secondary: poSearchFieldMapping.TR_CO_DESC,
    type: 'string',
    link: false,
    label: 'Trading Company Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    includeInRequest: true,
    default: false
  },
  [poSearchFieldMapping.PURCHASE_GROUP_CODE]: {
    primary: poSearchFieldMapping.PURCHASE_GROUP_CODE,
    secondary: poSearchFieldMapping.PURCHASE_GROUP_NAME,
    type: 'numeric',
    link: false,
    label: lblPurchaseGroup,
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PURCHASE_ORG_CODE]: {
    primary: poSearchFieldMapping.PURCHASE_ORG_CODE,
    secondary: poSearchFieldMapping.PURCHASE_ORG_NAME,
    type: 'numeric',
    link: false,
    label: lblPurchaseOrg,
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TTMI_CODE]: {
    primary: poSearchFieldMapping.TTMI_CODE,
    secondary: poSearchFieldMapping.TTMI_CODE_DESC,
    type: 'string',
    link: false,
    label: 'TTMI',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PO_LINE_ITEM_CANCEL_DATE]: {
    primary: poSearchFieldMapping.PO_LINE_ITEM_CANCEL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Item Cancel Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.STATISTICAL_DELIVERY_DATE]: {
    primary: poSearchFieldMapping.STATISTICAL_DELIVERY_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Statistical Delivery Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DIRECT_SHIP_SALES_ORDER_NUMBER]: {
    primary: poSearchFieldMapping.DIRECT_SHIP_SALES_ORDER_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Direct Ship Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DIRECT_SHIP_SALES_ORDER_ITEM]: {
    primary: poSearchFieldMapping.DIRECT_SHIP_SALES_ORDER_ITEM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Direct Ship Sales Order Item',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DIVISION]: {
    primary: poSearchFieldMapping.DIVISION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Division',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PO_LINE_ITEM_STATUS]: {
    primary: poSearchFieldMapping.PO_LINE_ITEM_STATUS,
    secondary: '',
    type: 'string',
    link: false,
    label: 'DPOM Line Item Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false,
    includeInRequest: true
  },
  [poSearchFieldMapping.TOTAL_ITEM_QUANTITY]: {
    primary: poSearchFieldMapping.TOTAL_ITEM_QUANTITY,
    secondary: '',
    type: 'numeric',
    link: false,
    label: 'Total Item Quantity',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.UNIT_OF_MEASURE]: {
    primary: poSearchFieldMapping.UNIT_OF_MEASURE,
    secondary: '',
    type: 'string',
    link: false,
    label: lblMeasureUnit,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.ORDER_REASON_CODE]: {
    primary: poSearchFieldMapping.ORDER_REASON_CODE,
    secondary: poSearchFieldMapping.ORDER_REASON_DESCRIPTION,
    type: 'string',
    link: false,
    label: 'Reason for Ordering',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.SEASON]: {
    primary: poSearchFieldMapping.SEASON,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Season',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.YEAR]: {
    primary: poSearchFieldMapping.YEAR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Season Year',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TR_CO_PLANT_CODE]: {
    primary: poSearchFieldMapping.TR_CO_PLANT_CODE,
    secondary: poSearchFieldMapping.TR_CO_PLANT_NAME,
    type: 'string',
    link: false,
    label: 'Trading Company Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CURRENT_EVENT]: {
    primary: poSearchFieldMapping.CURRENT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblCurrentEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CURRENT_EVENT_DATE]: {
    primary: poSearchFieldMapping.CURRENT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Current Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.NEXT_EVENT]: {
    primary: poSearchFieldMapping.NEXT_EVENT,
    secondary: '',
    type: 'string',
    link: false,
    label: lblNextEvent,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.NEXT_EVENT_DATE]: {
    primary: poSearchFieldMapping.NEXT_EVENT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Next Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ADDRESS_OVERRIDE_INDICATOR]: {
    primary: poSearchFieldMapping.ADDRESS_OVERRIDE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: lblAddressOverrideIndicator,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.OVER_DUE_INDICATOR]: {
    primary: poSearchFieldMapping.OVER_DUE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Over Due Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ISSUE_PO_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.ISSUE_PO_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Issuance Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ISSUE_PO_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.ISSUE_PO_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Issuance Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ACCEPT_PO_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.ACCEPT_PO_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Acceptance Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PO_ACCEPTANCE_DATE]: {
    primary: poSearchFieldMapping.PO_ACCEPTANCE_DATE,
    secondary: '',
    type: 'date',
    requestDataType: 'dynamicDate',
    link: false,
    label: 'Acceptance Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MATERIAL_ORDER_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.MATERIAL_ORDER_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Order Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MATERIAL_ORDER_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.MATERIAL_ORDER_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Order Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MATERIAL_TRIM_RECEIPT_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.MATERIAL_TRIM_RECEIPT_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Trim Receipt Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MATERIAL_TRIM_RECEIPT_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.MATERIAL_TRIM_RECEIPT_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Material Trim Receipt Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.QRS_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.QRS_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'QRS Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.QRS_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.QRS_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'QRS Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCTION_START_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.PRODUCTION_START_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production Start Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCTION_START_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.PRODUCTION_START_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production Start Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCTION_END_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.PRODUCTION_END_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production End Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCTION_END_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.PRODUCTION_END_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Production End Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.EX_FACTORY_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.EX_FACTORY_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Ex Factory Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.EX_FACTORY_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.EX_FACTORY_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Ex Factory Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FACTORY_DELIVERY_EXPECTED_DATE]: {
    primary: poSearchFieldMapping.FACTORY_DELIVERY_EXPECTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Factory Delivery Expected Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FACTORY_DELIVERY_ACTUAL_DATE]: {
    primary: poSearchFieldMapping.FACTORY_DELIVERY_ACTUAL_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Factory Delivery Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.REJECTION_CODE]: {
    primary: poSearchFieldMapping.REJECTION_CODE,
    secondary: poSearchFieldMapping.REJECTION_CODE_DESC,
    type: 'string',
    link: false,
    label: 'DPOM Rejection Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCT_NAME]: {
    primary: poSearchFieldMapping.PRODUCT_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: lblProductName,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.ADVANCED_SHIPMENT_NOTICE_ASN_DATE]: {
    primary: poSearchFieldMapping.ADVANCED_SHIPMENT_NOTICE_ASN_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'ASN Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ASN_QUANTITY]: {
    primary: poSearchFieldMapping.ASN_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'ASN Quantity',
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FACTORY_PROOF_OF_DELIVERY_DATE]: {
    primary: poSearchFieldMapping.FACTORY_PROOF_OF_DELIVERY_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Proof of Delivery Date',
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ORIGIN_RECEIPT_DATE]: {
    primary: poSearchFieldMapping.ORIGIN_RECEIPT_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Origin Receipt Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ORIGIN_RECEIPT_QUANTITY]: {
    primary: poSearchFieldMapping.ORIGIN_RECEIPT_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Origin Receipt Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.EX_FACTORY_QUANTITY]: {
    primary: poSearchFieldMapping.EX_FACTORY_QUANTITY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'EX Factory Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.INITIAL_GAC]: {
    primary: poSearchFieldMapping.INITIAL_GAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Initial GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.INITIAL_GAC_REASON_CODE]: {
    primary: poSearchFieldMapping.INITIAL_GAC_REASON_CODE,
    secondary: poSearchFieldMapping.INITIAL_GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: lblInitGACReasonCode,
    wrap: true,
    numeric: false,
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PREVIOUS_GAC]: {
    primary: poSearchFieldMapping.PREVIOUS_GAC,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Previous GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PREVIOUS_GAC_REASON_CODE]: {
    primary: poSearchFieldMapping.PREVIOUS_GAC_REASON_CODE,
    secondary: poSearchFieldMapping.PREVIOUS_GAC_REASON_DESC,
    type: 'string',
    link: false,
    label: 'Previous GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DELIVERY_COMPLETE_INDICATOR]: {
    primary: poSearchFieldMapping.DELIVERY_COMPLETE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Delivery Complete Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.LAUNCH_INDICATOR]: {
    primary: poSearchFieldMapping.LAUNCH_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Launch Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.LAUNCH_DATE]: {
    primary: poSearchFieldMapping.LAUNCH_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Launch Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CATEGORY_CODE]: {
    primary: poSearchFieldMapping.CATEGORY_CODE,
    secondary: poSearchFieldMapping.CATEGORY_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SUB_CATEGORY_CODE]: {
    primary: poSearchFieldMapping.SUB_CATEGORY_CODE,
    secondary: poSearchFieldMapping.SUB_CATEGORY_DESC,
    type: 'string',
    link: false,
    label: 'Sub Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.BLANK_COLOR]: {
    primary: poSearchFieldMapping.BLANK_COLOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Color',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.BLANK_PRODUCT_CODE]: {
    primary: poSearchFieldMapping.BLANK_PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Product Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.BLANK_STYLE_DISP_NUMBER]: {
    primary: poSearchFieldMapping.BLANK_STYLE_DISP_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Blank Style',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.STYLE_NUMBER]: {
    primary: poSearchFieldMapping.STYLE_NUMBER,
    secondary: '',
    type: 'number',
    link: false,
    label: 'Style Number',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.COLOR_DESC]: {
    primary: poSearchFieldMapping.COLOR_DESC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Color Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SIZE_DIMENSION]: {
    primary: poSearchFieldMapping.SIZE_DIMENSION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size Dimension',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.GENDER_AGE_CODE]: {
    primary: poSearchFieldMapping.GENDER_AGE_CODE,
    secondary: poSearchFieldMapping.GENDER_AGE__DESC,
    type: 'string',
    link: false,
    label: 'Gender Age',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.GLOBAL_CATEGORY_CORE_FOCUS_CODE]: {
    primary: poSearchFieldMapping.GLOBAL_CATEGORY_CORE_FOCUS_CODE,
    secondary: poSearchFieldMapping.GLOBAL_CATEGORY_CORE_FOCUS_DESC,
    type: 'string',
    link: false,
    label: 'Global Category Core Focus',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.GLOBAL_CATEGORY_SUMMARY_CODE]: {
    primary: poSearchFieldMapping.GLOBAL_CATEGORY_SUMMARY_CODE,
    secondary: poSearchFieldMapping.GLOBAL_CATEGORY_SUMMARY_DESC,
    type: 'string',
    link: false,
    label: 'Global Category Summary',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MARKETING_ID]: {
    primary: poSearchFieldMapping.MARKETING_ID,
    secondary: poSearchFieldMapping.MARKETING_DESC,
    type: 'string',
    link: false,
    label: 'Marketing Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PRODUCT_REFILL_CLASS_CODE]: {
    primary: poSearchFieldMapping.PRODUCT_REFILL_CLASS_CODE,
    secondary: poSearchFieldMapping.PRODUCT_REFILL_CLASS_DESC,
    type: 'string',
    link: false,
    label: 'Product Refill Class',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SILHOUETTE_CODE]: {
    primary: poSearchFieldMapping.SILHOUETTE_CODE,
    secondary: poSearchFieldMapping.SILHOUETTE_DESC,
    type: 'string',
    link: false,
    label: 'Silhouette',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SILO_CODE]: {
    primary: poSearchFieldMapping.SILO_CODE,
    secondary: poSearchFieldMapping.SILO_DESC,
    type: 'number',
    link: false,
    label: 'Silo',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.SPORT_ACTIVITY_CODE]: {
    primary: poSearchFieldMapping.SPORT_ACTIVITY_CODE,
    secondary: poSearchFieldMapping.SPORT_ACTIVITY_CODE_DESC,
    type: 'string',
    link: false,
    label: 'Sport Activity Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TEAM_ID]: {
    primary: poSearchFieldMapping.TEAM_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Team ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TEAM_NAME]: {
    primary: poSearchFieldMapping.TEAM_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Team Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.TEAM_SILHOUETTE_ID]: {
    primary: poSearchFieldMapping.TEAM_SILHOUETTE_ID,
    secondary: poSearchFieldMapping.TEAM_SILHOUETTE_DESC,
    type: false,
    label: 'Team Silhouette ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.LEAGUE_ID_CODE]: {
    primary: poSearchFieldMapping.LEAGUE_ID_CODE,
    secondary: poSearchFieldMapping.LEAGUE_ID_DESC,
    type: 'string',
    link: false,
    label: 'League ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ATHLETE_NAME]: {
    primary: poSearchFieldMapping.ATHLETE_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Athlete Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ATHLETE_ID]: {
    primary: poSearchFieldMapping.ATHLETE_ID,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Athlete ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.MIDSOLE_CODE]: {
    primary: poSearchFieldMapping.MIDSOLE_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Midsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.OUTSOLE_CODE]: {
    primary: poSearchFieldMapping.OUTSOLE_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Outsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.PROMO_ONLY_INDICATOR]: {
    primary: poSearchFieldMapping.PROMO_ONLY_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Promo Only Indicator ',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SALES_ORDER_NUMBER]: {
    primary: poSearchFieldMapping.SALES_ORDER_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SALES_ORDER_ITEM_NUMBER]: {
    primary: poSearchFieldMapping.SALES_ORDER_ITEM_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Sales Order Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CUSTOMER_PO]: {
    primary: poSearchFieldMapping.CUSTOMER_PO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Customer PO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.CUSTOMER_REQUESTED_DATE]: {
    primary: poSearchFieldMapping.CUSTOMER_REQUESTED_DATE,
    secondary: '',
    type: 'date',
    link: false,
    label: 'Customer Requested Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SHIP_TO_CUSTOMER_NUMBER]: {
    primary: poSearchFieldMapping.SHIP_TO_CUSTOMER_NUMBER,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Ship To Customer Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.SHIP_TO_CUSTOMER_NAME]: {
    primary: poSearchFieldMapping.SHIP_TO_CUSTOMER_NAME,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Ship To Customer Name',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DISTRIBUTION_CHANNEL_CODE]: {
    primary: poSearchFieldMapping.DISTRIBUTION_CHANNEL_CODE,
    secondary: poSearchFieldMapping.DISTRIBUTION_CHANNEL_DESC,
    type: 'string',
    link: false,
    label: 'Distribution Channel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.SALES_ORGANIZATION_CODE]: {
    primary: poSearchFieldMapping.SALES_ORGANIZATION_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Sales Organization Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_CLASS_CODE]: {
    primary: poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_CLASS_CODE,
    secondary: poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_CLASS_DESC,
    type: 'string',
    link: false,
    label: 'Global Planning Product Classification',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_GROUP]: {
    primary: poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_GROUP,
    secondary: poSearchFieldMapping.GLOBAL_PLANNING_PRODUCT_DESC,
    type: 'string',
    link: false,
    label: 'Global Planning Product Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.ITEM_TEXT]: {
    primary: poSearchFieldMapping.ITEM_TEXT,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Item Text',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.VAS_ITEM]: {
    primary: poSearchFieldMapping.VAS_ITEM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Item VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.VAS_SIZE]: {
    primary: poSearchFieldMapping.VAS_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Size VAS',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.FSP]: {
    primary: poSearchFieldMapping.FSP,
    secondary: '',
    type: 'string',
    link: false,
    label: 'FSP',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  },
  [poSearchFieldMapping.GEOGRAPHY_CODE]: {
    primary: poSearchFieldMapping.GEOGRAPHY_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Geo Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    default: false
  }
};
// To avoid the number lines go beyond 1000.
export default pricingNonProd ? {
  ...poSearchFields,
  // [poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE]: {
  //   primary: poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE,
  //   secondary: poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE_CURRENCY,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Foreign Exchange',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   additionalFields: [poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE_RATE],
  //   renderValue: renderRateUnitValue,
  //   default: false
  // },
  // [poSearchFieldMapping.TR_CO_INCL_SURC]: {
  //   primary: poSearchFieldMapping.TR_CO_INCL_SURC,
  //   secondary: poSearchFieldMapping.TR_CO_INCL_SURC_CURRENCY,
  //   type: 'string',
  //   link: false,
  //   label: 'Trading Co Net including surcharges',
  //   wrap: true,
  //   numeric: 'false',
  //   disablePadding: false,
  //   group: true,
  //   additionalFields: [poSearchFieldMapping.TR_CO_INCL_SURC_RATE],
  //   renderValue: renderRateUnitValue,
  //   default: false
  // },
  // [poSearchFieldMapping.PRODUCT_NAME]: {
  //   primary: poSearchFieldMapping.PRODUCT_NAME,
  //   secondary: '', type: 'string',
  //   link: false, label: lblProductName, wrap: true, numeric: 'false',
  //   disablePadding: false, group: true, default: false
  // },
  [poSearchFieldMapping.DIVERT_FLAG]: {
    primary: poSearchFieldMapping.DIVERT_FLAG,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Convert/Divert Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DIVERTED_FROM]: {
    primary: poSearchFieldMapping.DIVERTED_FROM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Created From',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.DIVERTED_TO]: {
    primary: poSearchFieldMapping.DIVERTED_TO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Moved To',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.POSEARCH_ASIAN_CC]: {
    primary: poSearchFieldMapping.POSEARCH_ASIAN_CC,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Asian C&C Label',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false,
    groupedColumn: true
  },
  [poSearchFieldMapping.POSEARCH_KOREA_CHEST_SIZE]: {
    primary: poSearchFieldMapping.POSEARCH_KOREA_CHEST_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Korea Size',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [poSearchFieldMapping.POSEARCH_CHINA_TOP_SIZE]: {
    primary: poSearchFieldMapping.POSEARCH_CHINA_TOP_SIZE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'China Size',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  }
} : {
  ...poSearchFields,
  [poSearchFieldMapping.PRODUCT_CODE]: {
    primary: poSearchFieldMapping.PRODUCT_CODE,
    secondary: '',
    type: 'string',
    link: false,
    default: true,
    label: lblProductCode,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true
  },
  [poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE]: {
    primary: poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE,
    secondary: poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE_CURRENCY,
    type: 'string',
    link: false,
    label: 'Trading Co Foreign Exchange',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    additionalFields: !pricingNonProd
      ? [poSearchFieldMapping.TR_CO_FOREIGN_EXCHANGE_RATE]
      : [],
    renderValue: renderRateUnitValue,
    default: false
  },
  [poSearchFieldMapping.TR_CO_INCL_SURC]: {
    primary: poSearchFieldMapping.TR_CO_INCL_SURC,
    secondary: poSearchFieldMapping.TR_CO_INCL_SURC_CURRENCY,
    type: 'string',
    link: false,
    label: 'Trading Co Net including surcharges',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    additionalFields: [poSearchFieldMapping.TR_CO_INCL_SURC_RATE],
    renderValue: renderRateUnitValue,
    default: false
  }
  // [poSearchFieldMapping.PRODUCT_NAME]: {
  //   primary: poSearchFieldMapping.PRODUCT_NAME,
  //   secondary: '', type: 'string',
  //   link: false, label: lblProductName, wrap: true, numeric: 'false',
  //   disablePadding: false, group: true, default: false
  // },
};
