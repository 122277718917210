export default {
  AIR_FREIGHT_PO_NUMBER: 'poHeader.poNumber',
  AIR_FREIGHT_ITEM_NUMBER: 'poLine.itemNumber',
  AIR_FREIGHT_OGAC: 'poLine.originalGoodsAtConsolidatorDate',
  AIR_FREIGHT_CURRENT_GAC_DATE: 'poLine.goodsAtConsolidatorDate',
  AIR_FREIGHT_QUANTITY: 'quantity',
  AIR_FREIGHT_NAF: 'dpomAirFreight.naf',
  AIR_FREIGHT_FAF: 'dpomAirFreight.faf',
  AIR_FREIGHT_GAC_CHANGE_TIMESTAMP: 'gacChangeTimeStamp',
  AIR_FREIGHT_DELAY: 'delay',
  AIR_FREIGHT_GRC: 'grc',
  AIR_FREIGHT_LIABILITY: 'liability',
  AIR_FREIGHT_FACTORY: 'factory',
  AIR_FREIGHT_TOTAL_DELAY: 'totalDelay',
  AIR_FREIGHT_AF: 'af',
  REPORT_ID: 'id',
  AIR_FREIGHT_TR_CO_PONUMBER: 'poHeader.trcoPoNumber',
  AIR_FREIGHT_VENDOR: 'poHeader.vendorCode',
  AIR_FREIGHT_VENDOR_NAME: 'vendor.vendorName',
  AIR_FREIGHT_DIVISION_CODE: 'poLine.divisionCode',
  AIR_FREIGHT_PRODUCT_CODE: 'poLine.productCode',
  AIR_FREIGHT_PRODUCT_NAME: 'poLine.productName',
  AIR_FREIGHT_ITEM_QTY: 'poLine.itemQuantity',
  AIR_FREIGHT_GAC_REASON_CODE: 'poLine.goodsAtConsolidatorReasonCode',
  AIR_FREIGHT_GAC_REASON_CODE_DESC: 'poLine.goodsAtConsolidatorReasonDescription',
  AIR_FREIGHT_GAC_USECASE_CODE: 'poLine.grcUseCaseCode',
  AIR_FREIGHT_GAC_USECASE_CODE_DESC: 'poLine.grcUseCaseDescription',
  AIR_FREIGHT_SEASON_CODE: 'poLine.seasonCode',
  AIR_FREIGHT_SEASON_YEAR: 'poLine.seasonYear',
  AIR_FREIGHT_LAUNCH: 'product.launchCode',
  AIR_FREIGHT_MIDSOLE_CODE: 'product.midsoleCode',
  AIR_FREIGHT_OUTSOLE_CODE: 'product.outsoleCode',
  AIR_FREIGHT_PROMO_ONLY_INDICATOR: 'product.promotionalIndicator',
  AIR_FREIGHT_ORDER_REASON_CODE: 'poLine.reasonForOrderingCode',
  AIR_FREIGHT_ORDER_REASON_DESCRIPTION: 'poLine.reasonforOrderingDescription',
  AIR_FREIGHT_STYLE_NUMBER: 'product.styleNumber',
  AIR_FREIGHT_CURRENT_EVENT: 'manufacturing.currentEvent',
  AIR_FREIGHT_NEXT_EVENT: 'manufacturing.nextEvent',
  AIR_FREIGHT_CUSTOMER_PO: 'salesOrder.customerPo',
  AIR_FREIGHT_DOC_TYPE_CODE: 'poHeader.poDocTypeCode',
  AIR_FREIGHT_DOC_TYPE_CODE_DESC: 'poHeader.poDocTypeDescription',
  AIR_FREIGHT_PURCHASE_ORG_CODE: 'poHeader.purchaseOrgCode',
  AIR_FREIGHT_PURCHASE_ORG_NAME: 'poHeader.purchaseOrgName',
  AIR_FREIGHT_PURCHASE_GROUP_CODE: 'poHeader.purchaseGroupCode',
  AIR_FREIGHT_PURCHASE_GROUP_NAME: 'poHeader.purchaseGroupName',
  AIR_FREIGHT_COLOR_DESC: 'product.colorDescription',
  AIR_FREIGHT_CREATED_DATE: 'poHeader.poCreatedOn',
  AIR_FREIGHT_REQUEST_SUBMISSION_DATE: 'poHeader.poRequestSumbission',
  AIR_FREIGHT_DOC_DATE: 'poHeader.documentDate',
  AIR_FREIGHT_CHANGED_DATE: 'poLine.changedOnDate',
  AIR_FREIGHT_GAC: 'poLine.goodsAtConsolidatorDate',
  AIR_FREIGHT_GEOGRAPHY_CODE: 'poLine.geographyCode',
  AIR_FREIGHT_LAUNCH_DATE: 'product.launchDate',
  AIR_FREIGHT_CURRENT_EVENT_DATE: 'manufacturing.currentEventDate',
  AIR_FREIGHT_NEXT_EVENT_DATE: 'manufacturing.nextEventDate',
  AIR_FREIGHT_ACCEPT_PO_ACTUAL_DATE: 'manufacturing.acceptPOActualDate',
  AIR_FREIGHT_FACTORY_DELIVERY_ACTUAL_DATE: 'manufacturing.factoryDeliveryActualDate',
  AIR_FREIGHT_EX_FACTORY_ACTUAL_DATE: 'manufacturing.exFactoryActualDate',
  AIR_FREIGHT_MRGAC: 'planning.mrgacDate',
  AIR_FREIGHT_PLANT_CODE: 'poLine.plantCode',
  AIR_FREIGHT_PLANT_NAME: 'poLine.plantName',
  AIR_FREIGHT_FOB_MISMATCH_INDICATOR: 'product.fobMismatchIndicator',
  AIR_FREIGHT_SIZE_MISMATCH_ID: 'product.sizeMismatchIndicator',
  AIR_FREIGHT_CREATED_BY: 'poHeader.createdBy',
  AIR_FREIGHT_TITLE_TRANSFER_MODEL_CODE: 'poHeader.titleTransferModelCode',
  AIR_FREIGHT_TITLE_TRANSFER_MODEL_DESC: 'poHeader.titleTransferModelDescription',
  AIR_FREIGHT_PO_COMPANY_CODE: 'poHeader.poCompanyCode',
  AIR_FREIGHT_PO_COMPANY_DESC: 'poHeader.poCompanyCodeDescription',
  AIR_FREIGHT_TR_CO_COMPANY_CODE: 'poHeader.trcoCompanyCode',
  AIR_FREIGHT_TR_CO_COMPANY_DESC: 'poHeader.trcoCompanyCodeDescription',
  AIR_FREIGHT_ITEM_STATUS: 'poLine.dpomItemStatus',
  AIR_FREIGHT_UNIT_OF_MEASURE_CODE: 'poLine.unitOfMeasureCode',
  AIR_FREIGHT_DESTINATION_COUNTRY_CODE: 'poLine.destinationCountryCode',
  AIR_FREIGHT_DESTINATION_COUNTRY_NAME: 'poLine.destinationCountryName',
  AIR_FREIGHT_TR_CO_PLANT_CODE: 'poLine.trcoPlantCode',
  AIR_FREIGHT_TR_CO_PLANT_NAME: 'poLine.trcoPlantName',
  AIR_FREIGHT_SHIPPING_TYPE: 'poLine.shippingType',
  AIR_FREIGHT_DIRECT_SALES_ORDER_NUMBER: 'poLine.directshipSalesOrderNumber',
  AIR_FREIGHT_DIRECT_SALES_ORDER_ITEM_NUMBER: 'poLine.directShipSalesOrderItemNumber',
  AIR_FREIGHT_CANCEL_DATE: 'poLine.cancelDate',
  AIR_FREIGHT_ITEM_TEXT_DETAIL: 'poLine.itemTextDetail',
  AIR_FREIGHT_CATEGORY_CODE: 'product.categoryCode',
  AIR_FREIGHT_CATEGORY_DESC: 'product.categoryDescription',
  AIR_FREIGHT_SUB_CATEGORY_CODE: 'product.subCategoryCode',
  AIR_FREIGHT_SUB_CATEGORY_DESC: 'product.subCategoryDescription',
  AIR_FREIGHT_GLOBAL_CATEGORY_FOCUS_CODE: 'product.globalCategoryCoreFocusCode',
  AIR_FREIGHT_GLOBAL_CATEGORY_FOCUS_DESC: 'product.globalCategoryCoreFocusDescription',
  AIR_FREIGHT_GLOABL_CATEGORY_SUMMARY_CODE: 'product.globalCategorySummaryCode',
  AIR_FREIGHT_GLOABL_CATEGORY_SUMMARY_DESC: 'product.globalCategorySummaryDescription',
  AIR_FREIGHT_GENDER_AGE_CODE: 'product.genderAgeCode',
  AIR_FREIGHT_GENDER_AGE_DESC: 'product.genderAgeDescription',
  AIR_FREIGHT_MARKETING_ID: 'product.primaryMarketingTypeIdentifier',
  AIR_FREIGHT_MARKETING_DESC: 'product.primaryMarketingTypeDescription',
  AIR_FREIGHT_SILHOUTTE_TYPE: 'product.silhouetteTypeCode',
  AIR_FREIGHT_SILHOUTTE_TYPE_DESC: 'product.silhouetteTypeDescription',
  AIR_FREIGHT_DIMENSION_CODE: 'product.dimensionCode',
  AIR_FREIGHT_PRODUCT_REFILL_CLASS_CODE: 'product.productRefillClassCode',
  AIR_FREIGHT_PRODUCT_REFILL_CLASS_DESC: 'product.productRefillClassDescription',
  AIR_FREIGHT_LEAGUE_ID_DESC: 'product.leagueIdDescription',
  AIR_FREIGHT_PO_REJECTION_CODE: 'manufacturing.poRejectionCode',
  AIR_FREIGHT_PO_REJECTION_CODE_DESC: 'manufacturing.poRejectionCodeDescription',
  AIR_FREIGHT_OVERDUE_INDICATOR: 'manufacturing.overdueIndicator',
  AIR_FREIGHT_CUSTOMER_SHIP_TO: 'poLine.customerShipToNumber',
  AIR_FREIGHT_CUSTOMER_SHIP_TO_NAME: 'poLine.customerShipToName',
  AIR_FREIGHT_GLOBAL_PRODUCT_GROUP: 'planning.globalPlanningProductGroupCode',
  AIR_FREIGHT_GLOBAL_PRODUCT_GROUP_DESC: 'planning.globalPlanningProductGroupDescription',
  AIR_FREIGHT_GLOBAL_PRODUCT_CLASS: 'planning.globalPlanningProductClassificationCode',
  AIR_FREIGHT_GLOBAL_PRODUCT_CLASS_DESC: 'planning.globalPlanningProductClassificationDescription',
  AIR_FREIGHT_PLANNING_PRIORITY_NUMBER: 'planning.planningPriorityCode',
  AIR_FREIGHT_PLANNING_PRIORITY_NUMBER_DESC: 'planning.planningPriorityDescription',
  AIR_FREIGHT_GAC_VS_MRGAC: 'planning.gacVsMrgac',
  AIR_FREIGHT_OGAC_VS_MRGAC: 'planning.ogacVsMrgac',
  AIR_FREIGHT_MRGAC_CAPACITY_CONSUMPTION_WEEK: 'planning.mrgacCapacityConsumptionWeek',
  AIR_FREIGHT_PMO_DEC_CODE: 'vendor.productionNikeLiaisonOffice',
  AIR_FREIGHT_PMO_DEC_CODE_DESC: 'vendor.productionNikeLiaisonOfficeName',
  AIR_FREIGHT_FX_ADJ_REQUIRED_INDICATOR: 'poHeader.fxAdjustmentRequiredIndicator',
  AIR_FREIGHT_CUSTOMER_REQ_DATE: 'salesOrder.customerRequestedDate',
  AIR_FREIGHT_VAS_ITEM: 'poLine.itemVasDetail',
  AIR_FREIGHT_R3_DOC_TYPE: 'poHeader.r3DocType',
  AIR_FREIGHT_MFG_ORIGIN_COUNTRY_CODE: 'poHeader.manufacturingCountryOfOrigin',
  AIR_FREIGHT_MFG_ORIGIN_COUNTRY_NAME: 'poHeader.manufacturingCountryOfOriginName',
  AIR_FREIGHT_TRANSPORT_MODE: 'poLine.transportationModeCode',
  AIR_FREIGHT_TRANSPORT_MODE_DESC: 'poLine.transportationMode',
  AIR_FREIGHT_DELIVERY_COMPLETE_INDICATOR: 'poLine.deliveryCompleteIndicator',
  AIR_FREIGHT_CUST_ISO_COUNTRY_CODE: 'poLine.customerIsoCountryCode',
  AIR_FREIGHT_SALES_ORDER_NUMBER: 'poLine.salesOrderNumber',
  AIR_FREIGHT_SALES_ORDER_ITEM_NUMBER: 'poLine.salesOrderItemNumber',
  AIR_FREIGHT_CUSTOMER_COUNTRY_NAME: 'poLine.customerCountryName',
  AIR_FREIGHT_INITIAL_GAC_DATE: 'poLine.initialGoodsAtConsolidatorDate',
  AIR_FREIGHT_REQUEST_STATUS: 'dpomAirFreight.status',
  AIR_FREIGHT_LAST_UPDATED_TS: 'dpomAirFreight.lastUpdatedTimestamp',
  AIR_FREIGHT_OVERRIDE_PONUMBER: 'poNumber',
  AIR_FREIGHT_OVERRIDE_POITEMNUMBER: 'itemNumber',
  AIR_FREIGHT_OVERRIDE_REQUESTOR: 'requestor',
  AIR_FREIGHT_OVERRIDE_CURRENT_NAF: 'currentNAF%',
  AIR_FREIGHT_OVERRIDE_CURRENT_FAF: 'currentFAF%',
  AIR_FREIGHT_OVERRIDE_PROPOSED_NAF: 'proposedNAF%',
  AIR_FREIGHT_OVERRIDE_PROPOSED_FAF: 'proposedFAF%',
  AIR_FREIGHT_OVERRIDE_COMMENTS: 'requestorComments',
  AIR_FREIGHT_OVERRIDE_STATUS: 'overrideStatus',
  AIR_FREIGHT_OVERRIDE_ATTACHMENTS_UPLOAD: 'attachmentsUpload',
  AIR_FREIGHT_OVERRIDE_ATTACHMENTS: 'attachments',
  AIR_FREIGHT_OVERRIDE_ATTACHMENTS_COUNT: 'attachmentsCount',
  AIR_FREIGHT_OVERRIDE_ELIGIBLE: 'dpomAirFreight.isLocked',
  AIR_FREIGHT_ADJUSTMENT_PONUMBER: 'poNumber',
  AIR_FREIGHT_ADJUSTMENT_POITEMNUMBER: 'itemNumber',
  AIR_FREIGHT_ADJUSTMENT_GEO_CODE: 'geoCode',
  AIR_FREIGHT_ADJUSTMENT_REQUESTOR_EMAIL: 'requestedByEmailID',
  AIR_FREIGHT_ADJUSTMENT_PMO: 'productionNikeLiaisonOffice',
  AIR_FREIGHT_ADJUSTMENT_REQUEST_DATE: 'createdDate'
};
