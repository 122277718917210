import lineSummaryReportFieldMapping from '../fieldMappings/lineSummaryReportFieldMapping';

const lblAddressOverrideIndicator = 'Address Override Indicator';

export function ppmRenderRateUnitValue(row) {
  if ((row[this.additionalFields[0]] || '').toLowerCase() === 'percent') {
    return `${row[this.primary] || ''} %`;
  }
  return `${row[this.primary] || ''} ${row[this.secondary] || ''}`.trim() || undefined;
}
// To avoid the number lines go beyond 1000.
export default {
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PONUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PONUMBER,
    label: 'Purchase Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER,
    label: 'Trading Co Purchase Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_LINE_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_LINE_NUMBER,
    label: 'PO Line Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_VENDOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_VENDOR,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_VENDOR_NAME,
    label: 'Vendor',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIVISION_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIVISION_CODE,
    label: 'Division',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_NAME,
    label: 'Product',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true,
    includeInRequest: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_QTY]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_QTY,
    label: 'Total Item Quantity',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_REASON_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_REASON_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_REASON_CODE_DESC,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GRC_USECASE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GRC_USECASE_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GRC_USECASE_CODE_DESC,
    label: 'GRC Usecase Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_CODE,
    label: 'Season',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_YEAR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SEASON_YEAR,
    label: 'Season Year',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_LAUNCH]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_LAUNCH,
    label: 'Launch Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_MIDSOLE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_MIDSOLE_CODE,
    label: 'Midsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_OUTSOLE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_OUTSOLE_CODE,
    label: 'Outsole Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PROMO_ONLY_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PROMO_ONLY_INDICATOR,
    label: 'Promo Only Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_ORDER_REASON_DESCRIPTION,
    label: 'Reason for Ordering',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_STYLE_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_STYLE_NUMBER,
    label: 'Style Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CURRENT_EVENT]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CURRENT_EVENT,
    label: 'Current Event',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_NEXT_EVENT]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_NEXT_EVENT,
    label: 'Next Event',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_PO]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_PO,
    label: 'Customer PO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE_DESC,
    label: 'Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_ORG_NAME,
    label: 'Purchase Org',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_NAME,
    label: 'Purchase Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_COLOR_DESC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_COLOR_DESC,
    label: 'Color Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_DATE,
    label: 'Create Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DOC_DATE,
    label: 'Document Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CHANGED_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CHANGED_DATE,
    label: 'Change Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_OGAC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_OGAC,
    label: 'OGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GAC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GAC,
    label: 'GAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_LAUNCH_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_LAUNCH_DATE,
    label: 'Launch Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CURRENT_EVENT_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CURRENT_EVENT_DATE,
    label: 'Current Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_NEXT_EVENT_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_NEXT_EVENT_DATE,
    label: 'Next Event Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE,
    label: 'Acceptance Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE,
    label: 'Factory Delivery Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE,
    label: 'Ex Factory Actual Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_MRGAC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_MRGAC,
    label: 'MRGAC',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    requestDataType: 'dynamicDate',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_FX_ADJ_REQUIRED_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_FX_ADJ_REQUIRED_INDICATOR,
    label: 'FX Adjustment Required Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PLANT_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PLANT_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PLANT_NAME,
    label: 'Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_FOB_MISMATCH_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_FOB_MISMATCH_INDICATOR,
    label: 'Fob Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SIZE_MISMATCH_ID]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SIZE_MISMATCH_ID,
    label: 'Size Mismatch Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_REQ_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_REQ_DATE,
    label: 'Customer Requested Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_VAS_ITEM]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_VAS_ITEM,
    label: 'Item Vas',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_R3_DOC_TYPE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_R3_DOC_TYPE,
    label: 'Historical Doc Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_BY]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_BY,
    label: 'Created By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TITLE_TRANSFER_MODEL_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TITLE_TRANSFER_MODEL_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_TITLE_TRANSFER_MODEL_DESC,
    label: 'TTMI',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_MFG_ORIGIN_COUNTRY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_MFG_ORIGIN_COUNTRY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_MFG_ORIGIN_COUNTRY_NAME,
    label: 'MCO',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PO_COMPANY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PO_COMPANY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PO_COMPANY_DESC,
    label: 'Company',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_COMPANY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_COMPANY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_COMPANY_DESC,
    label: 'Company Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_STATUS]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_STATUS,
    label: 'DPOM Line Item Status',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_UNIT_OF_MEASURE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_UNIT_OF_MEASURE_CODE,
    label: 'UoM',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DESTINATION_COUNTRY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DESTINATION_COUNTRY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_DESTINATION_COUNTRY_NAME,
    label: 'Destination Country',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_PLANT_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_PLANT_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_TR_CO_PLANT_NAME,
    label: 'Trading Company Plant',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TRANSPORT_MODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TRANSPORT_MODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_TRANSPORT_MODE_DESC,
    label: 'Mode of Transportation',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SHIPPING_TYPE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SHIPPING_TYPE,
    label: 'Shipping Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_NUMBER,
    label: 'Direct Ship Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_ITEM_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIRECT_SALES_ORDER_ITEM_NUMBER,
    label: 'Direct Ship Sales Order Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DELIVERY_COMPLETE_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DELIVERY_COMPLETE_INDICATOR,
    label: 'Delivery Complete Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CANCEL_DATE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CANCEL_DATE,
    label: 'Cancel Date',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'date',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CUST_ISO_COUNTRY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUST_ISO_COUNTRY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_COUNTRY_NAME,
    label: 'Customer Country',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SALES_ORDER_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SALES_ORDER_NUMBER,
    label: 'Sales Order Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SALES_ORDER_ITEM_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SALES_ORDER_ITEM_NUMBER,
    label: 'Sales Order Item Number',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_TEXT_DETAIL]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_TEXT_DETAIL,
    label: 'Item Text',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CATEGORY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CATEGORY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_CATEGORY_DESC,
    label: 'Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_SUB_CATEGORY_DESC,
    label: 'Sub Category',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_CATEGORY_FOCUS_DESC,
    label: 'Global Category Core Focus',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOABL_CATEGORY_SUMMARY_DESC,
    label: 'Global Category Summary',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GENDER_AGE_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GENDER_AGE_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GENDER_AGE_DESC,
    label: 'Gender Age',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_MARKETING_ID]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_MARKETING_ID,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_MARKETING_DESC,
    label: 'Marketing Type',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_SILHOUTTE_TYPE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_SILHOUTTE_TYPE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_SILHOUTTE_TYPE_DESC,
    label: 'Silhouette',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIMENSION_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIMENSION_CODE,
    label: 'Dimension Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_REFILL_CLASS_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_REFILL_CLASS_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PRODUCT_REFILL_CLASS_DESC,
    label: 'Product Refill Class',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_LEAGUE_ID_DESC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_LEAGUE_ID_DESC,
    label: 'League ID',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PO_REJECTION_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PO_REJECTION_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PO_REJECTION_CODE_DESC,
    label: 'DPOM Rejection Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_OVERDUE_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_OVERDUE_INDICATOR,
    label: 'Overdue Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_SHIP_TO]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_SHIP_TO,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_SHIP_TO_NAME,
    label: 'Ship To Customer',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: true
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_GROUP]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_GROUP,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_GROUP_DESC,
    label: 'Global Planning Product Group',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_CLASS]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_CLASS,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_GLOBAL_PRODUCT_CLASS_DESC,
    label: 'Global Planning Product Classification',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PLANNING_PRIORITY_NUMBER]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PLANNING_PRIORITY_NUMBER,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PLANNING_PRIORITY_NUMBER_DESC,
    label: 'Planning Priority',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_VS_MRGAC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_VS_MRGAC,
    label: 'Gac Vs Mrgac',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_OGAC_VS_MRGAC]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_OGAC_VS_MRGAC,
    label: 'Ogac Vs Mrgac',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_MRGAC_CAPACITY_CONSUMPTION_WEEK]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_MRGAC_CAPACITY_CONSUMPTION_WEEK,
    label: 'MRGAC Capacity Consumption Week',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_PMO_DEC_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_PMO_DEC_CODE,
    secondary: lineSummaryReportFieldMapping.LINE_SUMMARY_PMO_DEC_CODE_DESC,
    label: 'PMO/DEC code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    type: 'string',
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Geo Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_TRCO_RELEVANT_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_TRCO_RELEVANT_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Trd Co rel',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERT_FLAG]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERT_FLAG,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Convert/Divert Indicator',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERTED_FROM]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERTED_FROM,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Created From',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERTED_TO]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_DIVERTED_TO,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Moved To',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  },
  [lineSummaryReportFieldMapping.LINE_SUMMARY_ADDRESS_OVERRIDE_INDICATOR]: {
    primary: lineSummaryReportFieldMapping.LINE_SUMMARY_ADDRESS_OVERRIDE_INDICATOR,
    secondary: '',
    type: 'string',
    link: false,
    label: lblAddressOverrideIndicator,
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: false
  }
};
