/**
 File contains static string error, warnings or info messages.
 Files can be imported directly in messages.js wherever required.
 * @flow
 * @author {Cognizant Technology Solution}
 */

export function getDynamicText(key: string, ...values: Array<string | number>) {
  if (!this[key]) {
    return '';
  }
  let dynamicText = this[key];
  values.forEach((value) => {
    const valueInString = (value !== null && typeof value !== 'undefined')
      ? value.toString()
      : '';
    dynamicText = dynamicText.replace('{{value}}', valueInString);
  });
  return dynamicText;
}

const messageText = {
  gacInAppMaxSelectionErr: `Your Selection resulted in GAC request count 
  to exceed 10 thousand rows. Please reduce your selection before proceeding.`,
  fileDataRowsLimit: 'Your upload has more than 7 thousand rows of data. Please reduce the data and try again.',
  defaultTimeoutErrorMessage: 'Request Timed Out! Please refresh the page or contact administrator if the issue persists.',
  gacUpdateError: 'GAC Update Error',
  overWriteDesc: 'This Filename already exist for this thread. Do you want to replace the existing file.',
  overWriteTitle: 'Overwrite Warning',
  Selected: 'selected',
  All: 'all',
  error_txt: 'ERROR',
  error_reason: 'Something went wrong.',
  downloadAll: 'Download All',
  moreOption: 'More Options',
  Custom_View_Info: 'You can drag and drop any column name to change its position in the table header.',
  Size_Details: 'Size Details',
  Vas_Text: 'VAS Text',
  Item_Text: 'Item Text',
  newSearch: 'New Search',
  resetSearch: 'Reset Search',
  resetFilters: 'Reset Filters',
  saveTxt: 'Save',
  Close: 'CLOSE',
  pdfLabel: '.PDF',
  xmlLabel: '.XML',
  clearSearch: 'Clear Search',
  DataReceived: 'Data Received',
  overDueGac: 'Overdue GAC Updates',
  saveNewSearch: 'Save As New Search',
  fxErr: 'FX already applied',
  saveSearchLabel: 'Save Search',
  orderBookmarksCancel: 'You have unsaved changes in the page which will be discarded on close.',
  enterName: 'Please enter a name',
  convoErrorMsg: 'Please enter the Search criteria',
  DataRcvdMsg: ' Your DPOC validations results will be available on the dashboard.',
  pendingApprovDownload: 'Download Pending Approvals',
  downloadSire: 'Download SIRE Delivery Instructions',
  selectFormat: 'Please select the format you would like to export',
  FX_Info: 'By clicking Okay, the validated PO line item(s) will be FX adjusted. This action cannot be undone.',
  FX_Warning: 'The {{value}} PO line item(s) below did not pass validation.',
  FX_Success: 'We\'ve received {{value1}} {{lines}}, {{value2}} {{are}} validated.',
  FXQueuedIn: 'FX Adjustment will be applied and reflect on the PO in some time.',
  FXSelectionError: 'Please manually select the records to request FX adjustment.',
  FXErrorTitle: 'FX request not processed',
  GacSuccess: 'Requests are Submitted',
  GacSuccessMsg: 'Watch your email and dashboard notifications for confirmations on next steps.',
  GacErrorMsg: 'Need proposed GAC date and reason code to be entered.',
  GacLimitDialogTitle: 'Do you want to continue?',
  GacLimitDescription: 'You have selected a large amount of POs, which may result in sub optimal performance.',
  SelectAConversation: 'Select a conversation card to view',
  StartAConversation: 'Search a conversation to view',
  ConversationStatusClose: 'Closing this thread will prevent further messages from being posted and addition of attachments. This thread can be reopened at a later time.',
  deleteDenied: 'You can only delete attachments that were uploaded by you.',
  ConversationStatusOpen: 'Opening this thread will allow messages to be posted and allow addition of attachments.',
  ConversationClosedMsg: 'Closed Conversation Thread',
  ConversationReopenMsg: 'Re-Open Conversation Thread',
  ConversationthreadTitle: 'Conversation Threads',
  ConversationFailerMsg: 'The server encountered an internal error. Please contact your administrator.',
  ConversationFetchResult: 'Fetching results. Please wait ...',
  saveSpinnerTxt: 'Saving is in progress. Please wait ...',
  uploadResult: 'Uploading your file. Please wait ...',
  fetchingProfDetails: 'Fetching your profile information. Please wait ...',
  savingDetail: 'Saving your profile information. Please wait ...',
  emailNotification: 'Email Notifications for Conversation Threads and Shared Bookmarks',
  popupNotification: 'Select to see pop up in Operational Metrics',
  addMoreCriteria: 'You need to add one more search criteria.',
  customDynamicErrorText: 'Error: Invalid Date Range. Please ensure start date is before end date',
  minCreateDateErrorText: 'Create Date should be greater than 14 feb 2023',
  selectedMinCreateDateErrorText: 'Select date greater than 14 Feb 2023',
  ConversationCollab: 'COLLAB #',
  ConversationCollabSmall: 'Collab #',
  ConversationTopics: 'Conversation Topics',
  ConversationTopic: 'Conversation Topic',
  ConversationLastUpdate: 'Last Updated',
  sireProf: 'SIRE Delivery Profile',
  reportHeader: 'Reporting',
  ppmHeader: 'Production Progress & Metrics',
  ppmReport: 'PPM',
  collabReport: 'COLLAB HISTORY',
  poChangeReport: 'PO CHANGE',
  lisrReport: 'Line Summary',
  convoThreadReport: 'CONVERSATION THREAD',
  afReport: 'AIR FREIGHT',
  gacHistoryReport: 'GAC HISTORY',
  lineSummaryHeader: 'Line Item Summary Report',
  ConversationLastUpdates: 'LAST UPDATED',
  ConversationThreadStatus: 'Thread Status',
  ConversationPoRejects: 'PO Reject',
  ConversationPOSmall: 'po reject',
  ConversationVlaidFileSize: 'Please select a file less than 20MB and retry',
  selectPoNum: 'Please select at least one PO Number',
  ConversationValidFileFormat: 'Selected file format is not valid',
  bookMarksLimit: 'Only 25 bookmarks are allowed. Please delete older bookmark to add new one.',
  ConversationPoSearchCaps: 'POSEARCH',
  poNumbr: 'PO Number',
  gacErrHeader: 'PO-Line Item-Size Description',
  lineNumbr: 'Line Item Number',
  enterValidEmailAddress: 'Please Enter a Valid Email Address',
  enterOneField: 'Please enter at least 1 field',
  CloseThread: 'CLOSE THREAD',
  ReopenThread: 'RE-OPEN THREAD',
  vendorCodes: 'VENDOR CODES',
  VendorCodeSmall: 'Vendor Codes',
  Cancel: 'CANCEL',
  Proceed: 'PROCEED',
  Open: 'OPEN',
  OpenSmall: 'Open',
  CloseSmall: 'Close',
  Status: 'Status',
  statusLabel: 'Status:',
  savedTitie: 'Saved',
  Dismiss: 'DISMISS',
  notAuthorized: 'Not Authorized',
  Refresh: 'REFRESH',
  appErrorTitle: 'Internal Server Error',
  pendingReq: 'Pending Requests',
  pendingApprov: 'Pending Approvals',
  sapGac: 'SAP GAC',
  gacLabel: 'GAC',
  updatesFail: 'Update Failures',
  pendingLabel: 'Pending',
  sysApproved: 'System Approved',
  pmoApproved: 'PMO Approved',
  pmoReject: 'PMO Rejected',
  sapSuccess: 'SAP Success',
  sapFail: 'SAP Failed',
  appErrorDescription: `The server encountered an internal error.
  Please contact your administrator.`,
  searchReport: 'Search to view a report',
  underContructionTitle: 'Under Construction',
  underContructionDescription: `This page is currently under construction.
  Please be patient as we build out the application.`,
  FileUploadFormatError: 'The File Uploaded is not in the correct format. A .csv or .xls format with 20 MB size is required.',
  FileUploadStandardError: 'The Uploaded file Content is not as per the Standard. Kindly review it.',
  FileUploadSuccess: 'Your DPOM validation results will also be available on the dashboard.',
  FileUploadError: 'Error Found',
  PORejectionUploadSuccess: 'Your request status will be available on the next screen.',
  FileUploadReceived: 'Received',
  AcceptUploadSuccessTitle: 'Data Received',
  AccepPoUploadSuccess: 'Your DPOM validation results will also be available on the dashboard.',
  gacSelectionErrorTitle: 'REQUEST NOT PROCESSED',
  gacSelectionError: 'Kindly review your selection. GAC Update cannot be requested for Closed or Cancelled POs.',
  favouriteSearchLimitMessage: 'Saved Search Favourite Exceeds Limit',
  searchBookmarkLimitMessage: 'Maximum limit is 25',
  shareSearchSuccess: 'Shared Successfully',
  shareSearchFailure: 'Sharing failed. Please check the email ID and try again. You can only share a saved search with an existing DPOM user.',
  'styleNumber.error': 'Invalid Style Number',
  'seasonNumber.error': 'Invalid Season Number',
  'poNumber.error': 'Invalid PO Number',
  'tradingPoNumber.error': 'Invalid Trading PO Number',
  'productCode.error': 'Invalid Product Code',
  'searchPanelLabel.Search': 'PO Search',
  'searchPanelLabel.SearchButton': 'Search',
  fxNotReqErr: 'FX not required',
  gacRangeErr: 'GAC out of range',
  errorReason: 'errorReason',
  errorReasonLabel: 'Error Reasons',
  poPdfDesc: `This may take some time.
  Results will be on the status page.`,
  poPdf: 'Requests are submitted',
  error: 'Error!',
  sessionTimeout: 'Session Timeout',
  sessionTimeoutDesc: 'Your Session has timed out. Please refresh to login again.',
  poPdfErrorDesc: 'PO PDF download is only for Accepted or Closed purchase orders.',
  gacDownloadErrMsg: 'Did you download all required data yet?',
  exportAllMsg: 'Would you like to export all data? This will retrieve information from all the pages of the Search Results.',
  dashboardErr: 'Something went wrong. Please try your request again.',
  approve: 'Approved',
  reject: 'Rejected',
  requestRejected: 'Your selected POs are rejected. Initiator has been notified via Dashboard.',
  requestApproved: 'Your selected POs are approved. Update sent to SAP.',
  legalPoPdf: 'PO PDF Request Received',
  poPdfReq: 'PO PDF request',
  legalPoPdfDesc: `This may take some time.
  Please check download page for download link.`,
  success: 'Success',
  duplicate: 'Duplicate Request',
  limitReached: 'Limit Reached',
  successSireProfile: `This may take sometime.
  Please check download page for download link.`,
  sireLimit: `You've reached the maximum limit of 500
   selections. Please update your selection`,
  poNumber: 'PURCHASE ORDER NUMBER',
  poNumSmall: 'Purchase Order Number',
  enterTitle: 'Please enter a title for your search',
  poReportExpAll: 'Would you like to export all data? This will retrieve information from all the pages of the PO Collaboration History Report Search Results.',
  poChangeExport: 'Would you like to export all data? This will retrieve information from all the pages of the PO Change Report Search Results.',
  conversationThreadReportExport: 'Would you like to export all data? This will retrieve information from all the pages of the Conversation Thread Report Search Results.',
  ppmReportExpAll: 'Would you like to export all data? This will retrieve information from all the pages of the Production Progress & Metrics Search Results.',
  ppmReportExpAllSuccess: 'Download request has been submitted successfully. Please check download page for download link.',
  lineSummaryReportExpAll: 'Would you like to export all data? This will retrieve information from all the pages of the Line Item Search Results.',
  airFreightReportExpAll: 'Would you like to export all data? This will retrieve information from all the pages of Air Freight Search Results.',
  gacChangeHistoryReportExpAll: 'Would you like to export all data? This will retrieve information from all the pages of GAC Change History Search Results.',
  selectOneField: 'Please select atleast 1 field',
  stakeHolders: 'StakeHolders',
  stakeHolderLarge: 'STAKEHOLDERS',
  poNum: 'PO#',
  category: 'CATEGORY',
  selectCategory: 'Please select a category for the thread',
  collabReportHeader: 'Collaboration History Report',
  poChangeReportHeader: 'PO Change Report',
  conversationThreadReportHeader: 'Conversation Thread Report',
  selectCategoryTxt: 'Please select a category',
  enterMsg: 'Please enter a message to begin the conversation',
  enterStakeholder: 'Please enter at least one stakeholder email address',
  enterMsgTxt: 'Please enter a message',
  attachments: 'Attachments',
  status: 'Status',
  fxValidation: 'FX Adjustment Validation',
  proposedGac: 'Proposed GAC',
  gacDate: 'Proposed GAC Date',
  gacReason: 'Proposed GAC Reason Code',
  gacUsecase: 'Proposed GAC Use Case Code',
  grcUsecaseDescription: 'Proposed Use Case Code Description',
  columnLabel: 'Columns',
  setColumn: 'Set Column Order',
  tableHeader: 'Search Results',
  searchCriteria: 'Search Criteria',
  sireTTtmiOptionNoTTMI: 'No TTMI',
  sireTTtmiOptionB: 'Customs released',
  sireTTtmiOptionC: 'DC',
  sireTTtmiOptionD: 'Flash title',
  sireTTtmiOptionE: 'Territorial waters',
  sireBuyGrpOptionA: '01 – First Quality',
  sireBuyGrpOptionB: '02 – Overrun',
  sireBuyGrpOptionC: '03 – B Grade',
  sireBuyGrpOptionD: '04 – Promotional',
  sireBuyGrpOptionE: '05 – Salesman Sample',
  sireBuyGrpOptionF: '06 – Closeouts',
  sireDivOptionA: '10 – Apparel',
  sireDivOptionB: '20 – Footwear',
  sireDivOptionC: '30 – Equipment',
  sireModeOptionA: 'Airfreight',
  sireModeOptionB: 'AirSea',
  sireModeOptionC: 'Barge',
  sireModeOptionD: 'Hand carry',
  sireModeOptionE: 'Internal movement',
  sireModeOptionF: 'Rail',
  sireModeOptionG: 'SeaAir',
  sireModeOptionH: 'Small package',
  sireModeOptionI: 'Truck',
  sireModeOptionJ: 'Vessel',
  noMatch: 'No Matches Found',
  noRecord: 'No Record Found',
  tryOthrSearch: 'Please try another search',
  hideFilter: 'Hide/Unhide Filters',
  noProfile: 'Profile Not Found',
  maxLengthCrossed: 'You have reached the maximum limit of 500 selections. Please update your selection',
  exportDesc: 'Would you like to export all data? This will retrieve information from all the pages of the Search Results',
  noSireProfile: 'No SIRE profile has been provided for the selected Purchase Order Item. Please update your selection.',
  filterTxt: 'Filters',
  columnHeader: ' Data Table Columns',
  noBookmark: 'No bookmarks found',
  savedSearchHeader: 'Saved Searches',
  viewSearches: 'View Saved Searches',
  viewReportSearches: 'View Saved Reports',
  shareSearch: 'Share Search',
  poSearch: 'PO Searches',
  poReport: 'PO Reports',
  successTxt: 'Success!',
  userDetail: 'User Details',
  nameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  dpomRole: 'DPOM Role',
  factoryAccs: 'Factory Access',
  preferenceLabel: 'Preferences',
  enterEmail: 'Please enter recipients email addresses',
  updatedSearch: 'Your search has been updated successfully',
  executeImmediately: 'Your search has been saved successfully. Please click proceed to check download page for download link.',
  dpomResultTile: 'DPOM Validation Results',
  profileApplied: 'Profile successfully applied!',
  savedSearchTxt: 'Your search has been saved successfully',
  authFailed: 'Authorization Failed',
  emailIdLabel: 'Email Address',
  nikeId: 'Nike ID',
  convoThread: 'Conversation Thread',
  buttonText: {
    btnShare: 'SHARE',
    btnCancel: 'CANCEL',
    btnSave: 'SAVE',
    btnOkay: 'OKAY',
    btnClose: 'CLOSE',
    btnDownload: 'DOWNLOAD',
    downloadBtn: 'Downloads',
    downloadSmall: 'Download',
    btnReject: 'Reject',
    btnExport: 'EXPORT',
    btnProceed: 'PROCEED',
    btnApply: 'Apply',
    btnSearch: 'SEARCH',
    btnApplyCaps: 'APPLY',
    btnSubmit: 'SUBMIT',
    btnDismiss: 'DISMISS',
    btnDelete: 'DELETE',
    gacReq: 'GAC Update Request',
    accptPo: 'Accept PO',
    legalPoBtn: 'Legal PO PDF',
    issuePoBtn: 'Issue PO',
    fxAdjstBtn: 'FX Adjustment',
    sireBtn: 'SIRE Delivery Instructions',
    convoBtn: 'Conversations',
    expBtn: 'Export',
    viewBtn: 'View',
    btnTemp: 'Upload Template',
    btnDownTemp: 'Download Template',
    btnApprove: 'Approve',
    btnStart: 'START',
    btnScheduler: 'SCHEDULE JOB',
    btnExecuteImmediately: 'DOWNLOAD IMMEDIATELY',
    override: 'Override',
    btnReturnHome: 'Return to Dashboard'
  },
  placeholderText: {
    chatSearchBox: 'Search here',
    addEmail: 'Add Email Address'
  },
  screenClose: 'Close Screen?',
  viewAll: 'View All',
  deleteSearch: 'Delete Search',
  deleteAttachment: 'Delete Attachment',
  deleteStakeHolder: 'Delete StakeHolder',
  addCriteria: '+  Add Search Criteria',
  gacUpdate: 'GAC Updates',
  wipdUpdate: 'WIP Updates',
  poAccept: 'PO Acceptance',
  gacUpload: 'GAC Uploads',
  poRejection: 'PO Rejection',
  wipUpload: 'WIP Uploads',
  lineStatus: 'DPOM Line Item Status',
  exportAll: 'Export All',
  exportSelected: 'Export Selected Records',
  uploadStatus: 'Status Screen',
  clearFilter: 'Clear Filters',
  customFIlter: 'Customize Filters',
  collabId: 'Conversation ID # ',
  customize: 'Customize',
  setFilter: 'Set Filters',
  csvLabel: '.CSV',
  xlsxLable: '.XLSX',
  failedPo: 'Failed PO Items',
  failedPODownloads: 'Failed Download Request(s)',
  clearFields: 'Clear All Fields',
  dashBoardLabel: 'Dashboard',
  clearAll: 'Clear All',
  selectAll: 'Select All',
  resetAll: 'Reset All',
  resetFields: 'Reset All Fields',
  enterNewDateText: 'Enter New Date and Code to be applied:',
  dashboardVisibile: 'Only 10 favorite Searches are visible on the Dashboard',
  dashboardSaved: 'Your list of favorite Saved Searches have been added to the Dashboard',
  savedSearchRemove: 'Clicking on delete will remove this Saved Search from the list permanently.',
  attachmentRemove: 'Clicking on \'DELETE\' will remove this attachment permanently. You cannot undo this action.',
  stakeHolderRemove: 'Clicking on \'DELETE\' will remove this stakeholder permanently. You cannot undo this action.',
  stackHolderAdd: 'Please add any additional Stakeholders needed',
  pdfAlert: 'Please click submit to raise PDF request',
  pdfSelectLess: 'Please select 500 or less POs to request PDF',
  gacInAppErr: 'Please provide required information for following POs.',
  selectPo: 'Please select at least one PO Item',
  maxLimit: 'Maximum limit Reached',
  authMsg: 'You don\'t have permission to view this application. Please contact the DPOM system admininistrator.',
  permissionMsg: 'You don\'t have permission to view this page. Please contact the DPOM system admininistrator.',
  waitTextSpinner: 'Please wait',
  fetchResult: 'Fetching your results...',
  dashBoardSpinnerTxt: 'Fetching your dashboard information.',
  authReq: 'Please wait while we authenticate your request.',
  dpomExtIssueNikeUser: 'DPOM Production Issue - Nike user',
  dpomExtIssueFactoryUser: 'DPOM Production Issue - Factory user',
  externalPswrd: 'External Partner Password Reset',
  externalTrainDoc: 'DPOM Training Documents',
  externalCalendar: 'Nike Buy Calendar',
  externalFX: 'FX Calculation Report',
  subTxtNike: 'Tip - Enter "DPOM" as the service and choose the most relevant service area',
  subTxtFactory: 'Tip - Enter "DPOM" in the application/service search box',
  linkLabel: 'Useful Links',
  linkTool: 'Links to Other Tools',
  supportMaterial: 'DPOM Support Materials',
  uploadedFile: 'The file {{value}} has been uploaded successfully.',
  noConvoThread: 'No Conversation threads found',
  saved_Search: '{{value}} Saved Searches',
  no_saved_search: 'No Saved Searches',
  saved_report: '{{value}} Saved Reports',
  no_saved_report: 'No Saved Reports',
  savedSearch: 'Saved!',
  searchTitle: 'Please enter a title for your search',
  fileName: {
    failedGac: 'FailedGACUpdates',
    failedWip: 'FailedWIPUpdates',
    failedPo: 'FailedAcceptanceUpdates',
    failedPoRejection: 'FailedPoRejectionUpdates',
    failedPoPdf: 'FailedPoPdfUpdates',
    failedSire: 'FailedSireInstructionUpdates'
  },
  plantShipText: 'Please provide only Ship To or Plant, not both.',
  multipleShare: 'Bookmark could not be shared with the below emails. Please review the error(s) and try again.',
  timeoutErrorMessageType1: '{{value}} failed to load. Please open a NikeNow ticket for support.',
  timeoutErrorMessageType2: '{{value}} failed. Please open a NikeNow ticket for support.',
  dynamicText: getDynamicText,
  deleteRowTemplate1: 'Are you sure you want to update the selected record?',
  updateRowMsg: 'Are you sure you want to update the selected record?',
  deleteRowTemplate2: 'Are you sure you want to remove {{value}}-{{value}}',
  Confirm: 'CONFIRM',
  confirm: 'Confirm',
  unsavedData: 'Unsaved Data',
  geoEmailGroupMsg: 'Update Group Maintenance',
  unsavedAddMessageDesc: 'You have unsaved data in add section. If you leave, your changes will be lost. Do you want to leave?',
  unsavedResultMessageDesc: 'You have unsaved data in result section. If you leave, your changes will be lost. Do you want to leave?',
  goBack: 'Go Back',
  leaveWithoutSaving: 'Leave Without Saving',
  discardChange: 'Please confirm that you would like to discard the changes.',
  discardChangeTitle: 'Discard Changes?',
  conversationCodes: 'Conversation Codes',
  gacReasonCode: 'GAC Reason Codes',
  gacReasonUsecases: 'GAC Reason Use Cases',
  pmoDecFactoryContact: 'PMO/DEC Factory Contact',
  featureFlag: 'Feature Flag',
  updateFeatureFlag: 'Update Feature Flag',
  featureName: 'Feature Name',
  geoGlobalFactoryContact: 'Stakeholder Config',
  personalLabel: 'Personal',
  groupLabel: 'Group',
  conversationCategoryCodes: 'Conversation Category Codes',
  geoEmailGroup: 'Group Maintenance',
  addConversationCategoryCode: 'Conversation Category Code added successfully',
  updateConversationCode: 'Conversation Category Code updated successfully',
  addGeoStakeholderEmailMsg: 'Stakeholder Config added successfully',
  updateGeoStakeholderEmailMsg: 'Stakeholder Config updated successfully',
  addConversationCode: 'Add Conversation Code',
  addGacReasonCode: 'Add GAC Reason Code',
  addGacReasonUsecase: 'Add GAC Reason Usecases',
  addFactoryCode: 'Add Factory Code',
  addFeatureFlag: 'Add Feature Flag',
  addContactDetails: 'PMO/DEC Factory Contact added successfully',
  updateContactDetails: 'PMO/DEC Factory Contact updated successfully',
  addGeoEmailGroupDetails: 'Group Maintenance added successfully',
  updateGeoEmailGroupDetails: 'Group Maintenance updated successfully',
  addFeatureFlagDetails: 'Feature Flag added successfully',
  updateFeatureFlagDetails: 'Feature Flag updated successfully',
  partialUpdateDetails: `PMO/DEC Factory contact
  partially updated. Below are the failures.`,
  airfreightHistorySubtableTitle: 'Air Freight Approval History',
  gacHistorySubtableTitle: 'GAC History',
  create: 'Created',
  updated: 'Updated',
  partiallyUpdated: 'Partially Updated',
  creationFailed: 'Adding Failed',
  poPdfTab: 'PO PDF',
  sireTab: 'Sire Delivery Instruction',
  poSearchTab: 'PO Search',
  reportsTab: 'Reports',
  OK: 'OK',
  LISROperationExportText: 'Would you like to export the result data ? This will provide you graph summary table and retrieve information from all the pages of the result set.',
  airFreightReportHeader: 'Air Freight Report',
  setAsDefault: 'Set As Default',
  selectRowPerPage: 'Select default Rows per page for PO Search, Reports and Sire Delivery :',
  cronTitle: 'Schedule Report Download Availability',
  cronStartDate: 'Start Date',
  cronStartTime: 'Start Time',
  cronEndDate: 'End Date',
  cronEndTime: 'End Time',
  cronInterval: 'Interval',
  addGacReasonCodeDesc: 'GAC Reason Code added successfully.',
  updateGACReasonCode: 'GAC Reason Code updated successfully.',
  updateGacUseCaseCode: 'GAC Use Case Description updated successfully.',
  addGacUseCaseCode: 'GAC Use Case Code added successfully.',
  unsubmitted: 'Unsubmitted',
  submitted: 'Submitted',
  partialSubmittedDetails: 'Partially Submitted the Airfreight override update request details',
  submittedDetails: 'Record Creation Successful',
  unsubmittedDetails: 'Record Creation Failed',
  updationfailed: 'Approval failed.',
  failed: 'Failed',
  updateSuccessMsg: 'Updated successfully.',
  partiallyUpdatedMsg: 'Partially Updated.',
  uploaded: 'Uploaded',
  uploadedDetails: 'Attachment uploaded successfully',
  deleted: 'Deleted',
  deletedDetails: 'Attachment deleted successfully',
  validFileSize: 'Incorrect File Size',
  validFileFormat: 'Incorrect File Format',
  gacChangeHistoryReportHeader: 'GAC Change History Report',
  gacToolbarTitleInfo: 'Results available for PO created after 14 Feb 2023',
  geoEmailReActivate400Msg: 'Record failed to create as the de-activated record already exists',
  geoEmailReActivatePopUpMsg1: 'Do you want to re-activate the Geo Email?',
  geoEmailReActivatePopUpMsg: 'The de-activated record already exists. Proceed to re-activate',
  geoEmailReactivateMsg: 'Reactivate Group Maintenance'
};

export default messageText;
